/* unplugin-vue-components disabled */const { computed, getCurrentInstance, ref, inject, onMounted } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const model_1 = require("@/model");
const work_1 = require("@/store/modules/work");
const stage_1 = require("@/components/MainScene/render/stage");
const core_1 = require("@babylonjs/core");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AutoPathPanel',
    emits: ['onSuccess', 'onError'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const store = (0, work_1.useWork)();
        const curNode = computed(() => store.curNode);
        const { proxy } = getCurrentInstance();
        const checkPathName = (rule, value, callback) => {
            if (value === '' || value == null) {
                callback(new Error('请输入路径名称'));
            }
            else if (curNode.value.rootMachine.paths.find(e => e.name == value)) {
                callback(new Error('该路径名称已存在'));
            }
            callback();
        };
        const checkUcs = (rule, value, callback) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (value == null) {
                callback(new Error('请选择坐标系'));
            }
            let startUcs = yield toRobotUcsById(pathForm.value.startUcsId);
            let endUcs = yield toRobotUcsById(pathForm.value.endUcsId);
            if (startUcs &&
                endUcs &&
                startUcs.x == endUcs.x &&
                startUcs.y == endUcs.y &&
                startUcs.z == endUcs.z &&
                startUcs.rx == endUcs.rx &&
                startUcs.ry == endUcs.ry &&
                startUcs.rz == endUcs.rz) {
                callback(new Error('起点与终点不能相同'));
            }
            callback();
        });
        const rules = ref({
            pathName: [{ required: true, message: '请输入路径名称' },
                {
                    validator: checkPathName, trigger: 'change'
                }],
            startUcsId: [{ required: true, message: '请选择开始坐标系' }, { validator: checkUcs, trigger: 'change' }],
            endUcsId: [{ required: true, message: '请选择结束坐标系' }, { validator: checkUcs, trigger: 'change' }]
        });
        const cascaderProps = {
            checkStrictly: true
        };
        const emit = __emit;
        const pathForm = ref({
            pathName: '',
            startUcsId: '',
            endUcsId: ''
        });
        const coordOptions = computed(() => {
            var _a, _b;
            let options = [];
            if (curNode.value && curNode.value.rootMachine && ((_a = curNode.value.rootMachine) === null || _a === void 0 ? void 0 : _a.type) === model_1.ENTITY_TYPE.ROBOT) {
                curNode.value.rootMachine.tcps.forEach((tcp, index) => {
                    if (index === 0) {
                        options.push({
                            label: `工具坐标系原点`,
                            value: curNode.value.rootMachine.tcp0.id
                        });
                    }
                    else {
                        options.push({
                            label: `${tcp.name}`,
                            value: tcp.id
                        });
                    }
                });
            }
            (_b = model_1.Project.current()) === null || _b === void 0 ? void 0 : _b.machines.forEach(e => {
                if (e.type == model_1.ENTITY_TYPE.UCS) {
                    options.push({
                        label: e.name,
                        value: e.id
                    });
                }
            });
            return options;
        });
        function toRobotUcsById(id) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let project = model_1.Project.current();
                for (let i = 0; i < project.machines.length; i++) {
                    let item = project.machines[i];
                    if (item.id == id && item.type === model_1.ENTITY_TYPE.UCS)
                        return yield getUcsPoint(item.id, curNode.value.rootMachine.id);
                    if (item.type == model_1.ENTITY_TYPE.ROBOT) {
                        let tcpIndex = item.tcps.findIndex(tcp => tcp.id == id);
                        if (tcpIndex == 0)
                            return yield getTcpPoint(curNode.value.rootMachine.id);
                        if (item) {
                            let tool = item.tcps.find(tcp => tcp.id == id);
                            if (tool)
                                return transformPoseToSameUcs(yield getTcpPoint(curNode.value.rootMachine.id), tool);
                        }
                    }
                }
            });
        }
        const $api = inject("$api");
        const pathFromRef = ref();
        function transformPoseToUcs(ucs, pr) {
            let res = new model_1.PositionRotation();
            res.x = pr.x - ucs.x;
            res.y = pr.y - ucs.y;
            res.z = pr.z - ucs.z;
            res.rx = pr.rx - ucs.rx;
            res.ry = pr.ry - ucs.ry;
            res.rz = pr.rz - ucs.rz;
            let matrix = new core_1.Matrix();
            return res;
        }
        function transformPoseToSameUcs(rootPr, pr) {
            let res = new model_1.PositionRotation();
            res.x = pr.x + rootPr.x;
            res.y = pr.y + rootPr.y;
            res.z = pr.z + rootPr.z;
            res.rx = pr.rx + rootPr.rx;
            res.ry = pr.ry + rootPr.ry;
            res.rz = pr.rz + rootPr.rz;
            return res;
        }
        function getTcpPoint(id) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let res = yield stage_1.Stage.call('getTcpPoint', id);
                return res;
            });
        }
        function getUcsPoint(id, robotId) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let res = yield stage_1.Stage.call('getUcsPoint', id, robotId);
                return res;
            });
        }
        function handleAutoPath() {
            throw new Error('功能未实现');
        }
        function refreshPathFromName() {
            var _a, _b;
            if (!((_a = curNode.value) === null || _a === void 0 ? void 0 : _a.rootMachine))
                return;
            let index = 0;
            do {
                index++;
                pathForm.value.pathName = `路径${index}`;
            } while ((_b = curNode.value.rootMachine.paths) === null || _b === void 0 ? void 0 : _b.find(e => e.name == pathForm.value.pathName));
        }
        onMounted(() => {
            refreshPathFromName();
        });
        __expose({
            refreshPathFromName
        });
        return (_ctx, _cache) => {
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            const _component_el_form_item = (0, vue_2.resolveComponent)("el-form-item");
            const _component_el_option = (0, vue_2.resolveComponent)("el-option");
            const _component_el_select = (0, vue_2.resolveComponent)("el-select");
            const _component_el_form = (0, vue_2.resolveComponent)("el-form");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)(_component_el_form, {
                    model: (0, vue_2.unref)(pathForm),
                    rules: (0, vue_2.unref)(rules),
                    class: "auto-form",
                    "label-width": "90px",
                    ref_key: "pathFromRef",
                    ref: pathFromRef
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.createVNode)(_component_el_form_item, {
                            prop: "pathName",
                            label: "轨迹命名"
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_input, {
                                    placeholder: "填写名称",
                                    modelValue: (0, vue_2.unref)(pathForm).pathName,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (((0, vue_2.unref)(pathForm).pathName) = $event)),
                                    maxlength: 15
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_form_item, {
                            prop: "startUcsId",
                            label: "起始位姿"
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_select, {
                                    modelValue: (0, vue_2.unref)(pathForm).startUcsId,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (((0, vue_2.unref)(pathForm).startUcsId) = $event)),
                                    placeholder: "选择起始位姿",
                                    props: cascaderProps
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(coordOptions), (item) => {
                                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                                key: item.id,
                                                label: item.label,
                                                value: item.value
                                            }, null, 8, ["label", "value"]));
                                        }), 128))
                                    ]),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_form_item, {
                            prop: "endUcsId",
                            label: "结束位姿"
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_select, {
                                    modelValue: (0, vue_2.unref)(pathForm).endUcsId,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (((0, vue_2.unref)(pathForm).endUcsId) = $event)),
                                    placeholder: "选择结束位姿",
                                    props: cascaderProps
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(coordOptions), (item) => {
                                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                                key: item.id,
                                                label: item.label,
                                                value: item.value
                                            }, null, 8, ["label", "value"]));
                                        }), 128))
                                    ]),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["model", "rules"]),
                (0, vue_2.createVNode)(_component_el_button, {
                    onClick: handleAutoPath,
                    type: "primary",
                    style: { "display": "block", "margin-left": "auto", "margin-top": "5px" }
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.createTextVNode)("自动生成 ")
                    ]),
                    _: 1
                })
            ], 64));
        };
    }
});
