/* unplugin-vue-components disabled */const { ref } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        data: {
            type: (Array),
            required: true
        },
        hideOnClick: {
            type: Boolean,
            defalut: false
        },
        unShowMenuIcon: {
            type: Boolean,
            default: false
        }
    },
    emits: ['clickIcon', 'showChange'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const menu = ref();
        const emit = __emit;
        function handleCommand(command) {
            if (!command)
                return;
            if (command.clickClose && menu.value) {
                menu.value.handleClose();
                setTimeout(() => command.onClick(command), 200);
            }
            else {
                command.onClick(command);
            }
        }
        function onShowChange(val) {
            setTimeout(() => emit('showChange', val), 200);
        }
        const openMenu = () => {
            menu.value.handleOpen();
        };
        const closeMenu = () => {
            menu.value.handleClose();
        };
        __expose({
            openMenu,
            closeMenu
        });
        return (_ctx, _cache) => {
            const _component_More = (0, vue_2.resolveComponent)("More");
            const _component_el_icon = (0, vue_2.resolveComponent)("el-icon");
            const _component_svg_icon = (0, vue_2.resolveComponent)("svg-icon");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_el_popconfirm = (0, vue_2.resolveComponent)("el-popconfirm");
            const _component_el_dropdown_item = (0, vue_2.resolveComponent)("el-dropdown-item");
            const _component_el_dropdown_menu = (0, vue_2.resolveComponent)("el-dropdown-menu");
            const _component_el_dropdown = (0, vue_2.resolveComponent)("el-dropdown");
            return (__props.data && __props.data.length > 0)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dropdown, {
                    key: 0,
                    placement: "bottom",
                    trigger: "contextmenu",
                    width: 50,
                    "hide-on-click": __props.hideOnClick,
                    onVisibleChange: onShowChange,
                    ref_key: "menu",
                    ref: menu
                }, {
                    dropdown: (0, vue_2.withCtx)(() => [
                        (0, vue_2.createVNode)(_component_el_dropdown_menu, {
                            onMouseleave: _cache[1] || (_cache[1] = ($event) => (emit('mouseleave')))
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.data, (item, index) => {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dropdown_item, {
                                        key: index,
                                        command: item
                                    }, {
                                        default: (0, vue_2.withCtx)(() => [
                                            (item.confirmMsg && item.confirmMsg != '')
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_popconfirm, {
                                                    key: 0,
                                                    title: item.confirmMsg,
                                                    onConfirm: ($event) => (handleCommand(item))
                                                }, {
                                                    reference: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                            link: "",
                                                            plain: true
                                                        }, {
                                                            default: (0, vue_2.withCtx)(() => [
                                                                (item.icon && item.icon != '')
                                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_svg_icon, {
                                                                        key: 0,
                                                                        "icon-class": item.icon
                                                                    }, null, 8, ["icon-class"]))
                                                                    : (0, vue_2.createCommentVNode)("", true),
                                                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(item.label), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["title", "onConfirm"]))
                                                : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                                    key: 1,
                                                    link: "",
                                                    plain: true,
                                                    onClick: ($event) => (handleCommand(item))
                                                }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (item.icon && item.icon != '')
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_svg_icon, {
                                                                key: 0,
                                                                "icon-class": item.icon
                                                            }, null, 8, ["icon-class"]))
                                                            : (0, vue_2.createCommentVNode)("", true),
                                                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(item.label), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["onClick"]))
                                        ]),
                                        _: 2
                                    }, 1032, ["command"]));
                                }), 128))
                            ]),
                            _: 1
                        })
                    ]),
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.createElementVNode)("div", {
                            onClick: _cache[0] || (_cache[0] = (0, vue_2.withModifiers)(($event) => (emit('clickIcon')), ["stop"])),
                            style: { "width": "100%" }
                        }, [
                            (!__props.unShowMenuIcon)
                                ? (0, vue_2.renderSlot)(_ctx.$slots, "default", { key: 0 }, () => [
                                    (0, vue_2.createVNode)(_component_el_icon, { class: "icon" }, {
                                        default: (0, vue_2.withCtx)(() => [
                                            (0, vue_2.createVNode)(_component_More)
                                        ]),
                                        _: 1
                                    })
                                ])
                                : (0, vue_2.createCommentVNode)("", true)
                        ])
                    ]),
                    _: 3
                }, 8, ["hide-on-click"]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
