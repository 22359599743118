"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.net = void 0;
const tslib_1 = require("tslib");
const protocol_1 = require("@/rpc/lib/protocol");
const response_processor_1 = require("@/rpc/response_processor");
const http_1 = require("@/api/http");
var net;
(function (net) {
    class FlutterEvent {
        constructor() {
            this.requests = {};
        }
        exec(reqId, event, messages = []) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                return new Promise((resolve, reject) => {
                    let t = setTimeout(() => {
                        delete this.requests[reqId];
                        reject("消息发送超时！");
                    }, 10000);
                    this.requests[reqId] = (resp) => {
                        clearTimeout(t);
                        delete this.requests[reqId];
                        resolve(resp);
                    };
                    console.log('requests', JSON.stringify(Object.keys(this.requests)));
                    _callHandler(event, messages);
                });
            });
        }
        onEvent(event, brand, data) {
            let dataList = [data];
            let protocolStr = event.split('.')[0];
            if (response_processor_1.processor.ResponseProcessor[`${protocolStr}.${brand}`]) {
                try {
                    dataList = response_processor_1.processor.ResponseProcessor[`${protocolStr}.${brand}`].reduce(data);
                }
                catch (e) {
                    console.log(e);
                    throw new Error('数据处理异常，请重试！');
                }
            }
            var resp;
            dataList.forEach((data) => {
                switch (event) {
                    case "http.data":
                        resp = protocol_1.protocol.Encoding[`http.${brand}`].decode(data);
                        this.emit(resp.id, resp);
                        break;
                    case "socket.data":
                        resp = protocol_1.protocol.Encoding[`socket.${brand}`].decode(data);
                        this.emit(resp.id, resp);
                        break;
                    case "sftp.write_file":
                    case "socket.connect":
                    case "sftp.connect":
                        resp = protocol_1.protocol.Encoding[`sftp`].decode(data);
                        this.emit(resp.id, new protocol_1.protocol.DefaultResponse(event, true));
                        break;
                }
            });
        }
        emit(reqId, data) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.requests[reqId]) {
                    yield this.requests[reqId](data);
                }
            });
        }
    }
    class Socket extends FlutterEvent {
        constructor(brand, ip, port) {
            super();
            this.brand = brand;
            this.ip = ip;
            this.port = port;
        }
        static current() {
            if (Socket.instance)
                return Socket.instance;
            throw new Error("Socket 未实例化");
        }
        static connect(brand, ip, port) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                Socket.instance = new Socket(brand, ip, port);
                yield _callHandler("socket.connect", [ip, port, brand]);
            });
        }
        write(request) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let message;
                try {
                    let encoding = protocol_1.protocol.Encoding[`socket.${this.brand}`];
                    message = encoding.encode(request);
                }
                catch (e) {
                    console.error(e);
                    throw new Error("发送失败，请重试！");
                }
                let reqId = request.id;
                try {
                    return yield this.exec(reqId, "socket.write", message);
                }
                catch (e) {
                    console.error(e);
                    throw e;
                }
            });
        }
    }
    net.Socket = Socket;
    class SFtp extends FlutterEvent {
        constructor(ip, port, username, password) {
            super();
            this.ip = ip;
            this.port = port;
            this.username = username;
            this.password = password;
        }
        static current() {
            if (SFtp.instance)
                return SFtp.instance;
            throw new Error("SSHFtp 未实例化");
        }
        static connect(ip, port, username, password) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                SFtp.instance = new SFtp(ip, port, username, password);
                yield _callHandler("sftp.connect", [
                    ip,
                    port,
                    username,
                    password,
                ]);
            });
        }
        readFile(path) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield this.exec("sftp.data", "sftp.read_file", path);
            });
        }
        writeFile(path, content) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let res = yield this.exec(path, "sftp.write_file", [
                    path,
                    content,
                ]);
                return res;
            });
        }
        rmFile(path) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield this.exec("sftp.data", "sftp.rm_file", path);
            });
        }
    }
    net.SFtp = SFtp;
    class Http extends FlutterEvent {
        constructor(ip, port, proxy) {
            super();
            this.ip = ip;
            this.port = port;
            this.proxy = proxy || { ip: this.ip, port: this.port };
        }
        static current() {
            if (Http.instance)
                return Http.instance;
            throw new Error("Http 未实例化");
        }
        static connect(brand, ip, port, proxy) {
            Http.instance = new Http(ip, port, proxy);
            return Http.instance;
        }
        request(brand, req) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let encoding = protocol_1.protocol.Encoding[`http.${brand}`];
                let { url, method, data } = encoding.encode(req);
                let res = yield http_1.http.request({
                    url: `${this.proxy.ip}:${this.proxy.port}${url}`,
                    headers: {
                        "aim-ip": this.ip,
                        "aim-port": this.port,
                    },
                    method: method,
                    params: method.toLowerCase() === "get" ? data : undefined,
                    data: method.toLowerCase() === "get" ? undefined : data,
                    useLoading: false,
                });
                return encoding.decode(JSON.stringify(res));
            });
        }
    }
    net.Http = Http;
    const _callHandler = (handlerName, messages = []) => tslib_1.__awaiter(this, void 0, void 0, function* () {
        if (!(messages instanceof Array))
            messages = [messages];
        else
            messages = messages.map((message) => message.toString());
        let inappwebview = window.flutter_inappwebview;
        let result;
        if (inappwebview === null || inappwebview === void 0 ? void 0 : inappwebview.callHandler) {
            console.log("call1");
            result = inappwebview.callHandler(handlerName, ...messages);
        }
        else if (inappwebview === null || inappwebview === void 0 ? void 0 : inappwebview._callHandler) {
            console.log("call2");
            result = inappwebview._callHandler(handlerName, setTimeout(function () { }), ...messages);
        }
        else {
            throw new Error("非Flutter环境");
        }
        return result;
    });
    const uuid = () => {
        return Date.now().toString() + Math.round(Math.random() * 1000);
    };
})(net || (exports.net = net = {}));
