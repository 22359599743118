/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-2d52fa0a"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "device-type" };
const _hoisted_2 = { class: "device-content" };
const _hoisted_3 = ["onClick", "data-type_id"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
    key: 1,
    class: "device-content"
};
const _hoisted_6 = { style: { "padding": "14px" } };
const _hoisted_7 = { class: "dialog-footer" };
const _hoisted_8 = ["isUseCurDevice"];
const _hoisted_9 = { class: "dialog-footer" };
const vue_3 = require("vue");
const work_1 = require("@/store/modules/work");
const model_1 = require("@/model");
const one_message_1 = require("@/utils/one_message");
const project_1 = require("@/service/project");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        showTypes: {
            default: [],
            type: (Array)
        },
        isUseCurDevice: {
            type: Boolean,
            default: false
        },
        isImportScene: {
            type: Boolean,
            default: false
        }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const isInstallToolStep = (0, vue_3.ref)(false);
        const workStore = (0, work_1.useWork)();
        const curNode = (0, vue_3.computed)(() => workStore.curNode);
        const device_id = (0, vue_3.ref)(0);
        const visible = (0, vue_3.ref)(false);
        const activeName = (0, vue_3.ref)('All');
        const allLabels = (0, vue_3.ref)([
            {
                name: '全部',
                value: 'All',
                subType: { 'scene': '全部', 'load': '全部', 'arm_span': '全部', 'axles_count': '全部' },
                subTypes: ['scene', 'load', 'arm_span', 'axles_count']
            }, {
                name: '机器人',
                value: 'robot',
                subType: { 'scene': '全部', 'load': '全部', 'arm_span': '全部', 'axles_count': '全部' },
                subTypes: ['scene', 'load', 'arm_span', 'axles_count']
            }, {
                name: '工具',
                value: 'tool',
                subType: { 'scene': '全部', 'mounting_way': '全部' },
                subTypes: ['scene', 'mounting_way']
            }, {
                name: '夹具',
                value: 'fixture',
                subType: { 'scene': '全部', 'mounting_way': '全部' },
                subTypes: ['scene', 'mounting_way']
            }, {
                name: '设备',
                value: 'device',
                subType: { 'technology': '全部' },
                subTypes: ['technology']
            }
        ]);
        const robotEntites = (0, vue_3.computed)(() => {
            var _a;
            if (!props.isUseCurDevice) {
                return (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.findMachineByType(model_1.ENTITY_TYPE.ROBOT);
            }
            else {
                return [curNode.value.rootMachine];
            }
        });
        const labels = (0, vue_3.computed)(() => {
            if (props.showTypes && props.showTypes.length > 0) {
                activeName.value = props.showTypes[0];
                return allLabels.value.filter((label) => props.showTypes.includes(label.value));
            }
            else {
                activeName.value = 'All';
                return allLabels;
            }
        });
        const labelNames = (0, vue_3.ref)({
            scene: '应用',
            load: '负载',
            arm_span: '臂展',
            axles_count: '轴数',
            mounting_way: '安装方式',
            technology: '工艺'
        });
        const options = (0, vue_3.ref)({
            scene: new Set(['全部']),
            load: new Set(['全部']),
            arm_span: new Set(['全部']),
            axles_count: new Set(['全部']),
            mounting_way: new Set(['全部']),
            technology: new Set(['全部']),
        });
        const devices = (0, vue_3.ref)([]);
        const filterDevices = (0, vue_3.computed)(() => (label) => {
            let deviceType = label.value;
            let showDevices = deviceType == 'All' ? devices.value.filter((device) => device.type != model_1.MODEL_TYPE.FIXTURE) : devices.value.filter((device) => device.type == deviceType);
            showDevices = showDevices.filter((device) => {
                let flag = true;
                Object.keys(label.subType).forEach(key => {
                    if (label.subType[key] != '全部' && !showDevices.find(e => e[key] == label.subType[key])) {
                        flag = false;
                    }
                });
                return flag;
            });
            return showDevices;
        });
        let importSceneId;
        function onImportScene(e) {
            if (importSceneId)
                clearTimeout(importSceneId);
            importSceneId = setTimeout(() => {
                var _a, _b, _c;
                let robots = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.findMachineByType(model_1.ENTITY_TYPE.ROBOT);
                if (robots && robots.length >= 1) {
                    (0, one_message_1.oneMessage)({ message: '只能添加一个机器人', type: 'warning' });
                    return;
                }
                let type_id = ((_c = (_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.dataset) === null || _c === void 0 ? void 0 : _c.type_id) || device_id.value;
                let model = devices.value.find((model) => model.type_id == type_id);
                if (!model) {
                    return;
                }
                let child = model_1.Project.current().createMachine(model);
                workStore.selectNode(child);
                visible.value = false;
                importSceneId = null;
            }, 500);
        }
        const assembleData1 = (0, vue_3.ref)();
        const assembleData2 = (0, vue_3.ref)();
        function changeSelectAssemble1(val) {
            assembleData1.value = val;
        }
        function changeSelectAssemble2(val) {
            assembleData2.value = val;
        }
        function onAddToolClick() {
            if (props.isUseCurDevice) {
                assembleData1.value = {
                    entityId: curNode.value.rootMachine.id,
                    coordID: curNode.value.rootMachine.id
                };
                handleInstallTool();
            }
            else {
                isInstallToolStep.value = true;
            }
        }
        function handleInstallTool() {
            var _a;
            if (!model_1.Project.current())
                return;
            let model = devices.value.find((tmpl) => tmpl.type_id == device_id.value);
            let project = model_1.Project.current();
            let child = project.createMachine(model, false);
            child.data = [assembleData1.value, assembleData2.value];
            let parentDevice = project.findMachineById(assembleData1.value.entityId);
            if (!parentDevice) {
                throw new Error(`can't fine parent Robot`);
            }
            if (((_a = parentDevice.tool) === null || _a === void 0 ? void 0 : _a.model.name) === child.model.name) {
                (0, one_message_1.oneMessage)({ message: '所选夹具类型与当前安装的夹具类型一致，操作取消' });
                return;
            }
            parentDevice.setTool(child);
            workStore.selectNode(child);
            isInstallToolStep.value = false;
            visible.value = false;
            assembleData1.value = {};
            assembleData2.value = {};
        }
        function cleanup() {
            device_id.value = 0;
        }
        function show() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                visible.value = true;
                if (!devices.value.length) {
                    let results = yield (0, project_1.getDevicesInfos)();
                    let realData = results.filter(e => e.cover != null);
                    devices.value = realData.map(result => model_1.MachineModel.formJson(result));
                    realData.forEach(e => {
                        if (e.scene != null && e.scene.trim() != "")
                            options.value.scene.add(e.scene);
                        if (e.load != null && e.load.trim() != "")
                            options.value.load.add(e.load);
                        if (e.arm_span != null && e.arm_span.trim() != "")
                            options.value.arm_span.add(e.arm_span);
                        if (e.mounting_way != null && e.mounting_way.trim() != "")
                            options.value.mounting_way.add(e.mounting_way);
                        if (e.technology != null && e.technology.trim() != "")
                            options.value.technology.add(e.technology);
                    });
                }
            });
        }
        __expose({
            show,
            devices
        });
        return (_ctx, _cache) => {
            const _component_el_text = (0, vue_2.resolveComponent)("el-text");
            const _component_el_option = (0, vue_2.resolveComponent)("el-option");
            const _component_el_select = (0, vue_2.resolveComponent)("el-select");
            const _component_el_tag = (0, vue_2.resolveComponent)("el-tag");
            const _component_el_skeleton_item = (0, vue_2.resolveComponent)("el-skeleton-item");
            const _component_el_skeleton = (0, vue_2.resolveComponent)("el-skeleton");
            const _component_el_tab_pane = (0, vue_2.resolveComponent)("el-tab-pane");
            const _component_el_tabs = (0, vue_2.resolveComponent)("el-tabs");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_assemble_item = (0, vue_2.resolveComponent)("assemble-item");
            const _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                modelValue: visible.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((visible).value = $event)),
                width: "1024px",
                class: "device-dialog",
                "align-center": "",
                onClosed: cleanup
            }, {
                footer: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createElementVNode)("span", _hoisted_7, [
                        (0, vue_2.createVNode)(_component_el_button, {
                            onClick: _cache[2] || (_cache[2] = ($event) => (visible.value = false))
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createTextVNode)("关闭")
                            ]),
                            _: 1
                        }),
                        (__props.isImportScene)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                key: 0,
                                type: "primary",
                                onClick: onImportScene
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createTextVNode)(" 导入场景中 ")
                                ]),
                                _: 1
                            }))
                            : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                key: 1,
                                type: "primary",
                                onClick: onAddToolClick,
                                disabled: device_id.value === 0
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createTextVNode)(" 安装工具 ")
                                ]),
                                _: 1
                            }, 8, ["disabled"]))
                    ])
                ]),
                default: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createVNode)(_component_el_tabs, {
                        modelValue: activeName.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((activeName).value = $event)),
                        "tab-position": "left",
                        class: "demo-tabs"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(labels.value, (label) => {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_tab_pane, {
                                    label: label.name,
                                    name: label.value,
                                    key: 'label-' + label.name
                                }, {
                                    default: (0, vue_2.withCtx)(() => {
                                        var _a;
                                        return [
                                            ((_a = devices.value) === null || _a === void 0 ? void 0 : _a.length)
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                    key: 'title-' + _ctx.type
                                                }, [
                                                    (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(label.subTypes, (type, index) => {
                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, {
                                                                key: `type${index}`
                                                            }, [
                                                                (0, vue_2.createVNode)(_component_el_text, null, {
                                                                    default: (0, vue_2.withCtx)(() => [
                                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(labelNames.value[type]) + "：", 1)
                                                                    ]),
                                                                    _: 2
                                                                }, 1024),
                                                                (0, vue_2.createVNode)(_component_el_select, {
                                                                    modelValue: label.subType[type],
                                                                    "onUpdate:modelValue": ($event) => ((label.subType[type]) = $event),
                                                                    placeholder: labelNames.value[type]
                                                                }, {
                                                                    default: (0, vue_2.withCtx)(() => [
                                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(options.value[type], (value) => {
                                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                                                                key: value,
                                                                                label: value,
                                                                                value: value
                                                                            }, null, 8, ["label", "value"]));
                                                                        }), 128))
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                                            ], 64));
                                                        }), 128))
                                                    ]),
                                                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(filterDevices.value(label), (device) => {
                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                                class: (0, vue_2.normalizeClass)(['device-item', { 'selected': device_id.value == device.type_id }]),
                                                                key: 'item-' + device.type_id,
                                                                onClick: ($event) => (device_id.value = device.type_id),
                                                                onDblclick: _cache[0] || (_cache[0] = ($event) => (__props.isImportScene ? onImportScene($event) : onAddToolClick())),
                                                                "data-type_id": device.type_id
                                                            }, [
                                                                (0, vue_2.createElementVNode)("img", {
                                                                    src: device.cover
                                                                }, null, 8, _hoisted_4),
                                                                (0, vue_2.createVNode)(_component_el_tag, null, {
                                                                    default: (0, vue_2.withCtx)(() => [
                                                                        (0, vue_2.createVNode)(_component_el_text, { truncated: true }, {
                                                                            default: (0, vue_2.withCtx)(() => [
                                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(device.name), 1)
                                                                            ]),
                                                                            _: 2
                                                                        }, 1024)
                                                                    ]),
                                                                    _: 2
                                                                }, 1024)
                                                            ], 42, _hoisted_3));
                                                        }), 128))
                                                    ])
                                                ]))
                                                : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, [
                                                    ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(24, (index) => {
                                                        return (0, vue_2.createVNode)(_component_el_skeleton, {
                                                            style: { "width": "100px" },
                                                            animated: "",
                                                            key: 'skeleton-' + index
                                                        }, {
                                                            template: (0, vue_2.withCtx)(() => [
                                                                (0, vue_2.createVNode)(_component_el_skeleton_item, {
                                                                    variant: "image",
                                                                    style: { "width": "100px", "height": "100px" }
                                                                }),
                                                                (0, vue_2.createElementVNode)("div", _hoisted_6, [
                                                                    (0, vue_2.createVNode)(_component_el_skeleton_item, {
                                                                        variant: "h3",
                                                                        style: { "width": "100%" }
                                                                    })
                                                                ])
                                                            ]),
                                                            _: 2
                                                        }, 1024);
                                                    }), 64))
                                                ]))
                                        ];
                                    }),
                                    _: 2
                                }, 1032, ["label", "name"]));
                            }), 128))
                        ]),
                        _: 1
                    }, 8, ["modelValue"]),
                    (0, vue_2.createVNode)(_component_el_dialog, {
                        width: "400px",
                        "align-center": "",
                        ref: "installTool",
                        modelValue: isInstallToolStep.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((isInstallToolStep).value = $event))
                    }, {
                        footer: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createElementVNode)("span", _hoisted_9, [
                                (0, vue_2.createVNode)(_component_el_button, {
                                    onClick: _cache[3] || (_cache[3] = ($event) => (isInstallToolStep.value = false))
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("取消")
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_button, {
                                    type: "primary",
                                    onClick: handleInstallTool
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("确定")
                                    ]),
                                    _: 1
                                })
                            ])
                        ]),
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createVNode)(_component_assemble_item, {
                                title: "机器人",
                                onChangeSelectEntity: changeSelectAssemble1,
                                entities: robotEntites.value
                            }, null, 8, ["entities"]),
                            (0, vue_2.createElementVNode)("div", {
                                style: { "padding-top": "10px" },
                                isUseCurDevice: __props.isUseCurDevice
                            }, null, 8, _hoisted_8),
                            (0, vue_2.createVNode)(_component_assemble_item, {
                                title: "设备",
                                onChangeSelectEntity: changeSelectAssemble2,
                                isDisableReverse: true,
                                entities: [_ctx.curSelectDeviceEntities]
                            }, null, 8, ["entities"])
                        ]),
                        _: 1
                    }, 8, ["modelValue"])
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
