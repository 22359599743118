"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRobotService = void 0;
const tslib_1 = require("tslib");
const protocol_1 = require("@/rpc/lib/protocol");
require("@/rpc/codec");
const base_1 = require("@/rpc/lib/base");
const net_1 = require("@/rpc/lib/net");
const angle_1 = require("@/utils/angle");
var Socket = net_1.net.Socket;
var SFtp = net_1.net.SFtp;
var SetServoRequest = protocol_1.protocol.SetServoRequest;
var SetUserFrameRequest = protocol_1.protocol.SetUserFrameRequest;
var MoveByJogRequest = protocol_1.protocol.MoveByJogRequest;
var SetUserNumRequest = protocol_1.protocol.SetUserNumRequest;
var StopRobotRequest = protocol_1.protocol.StopRobotRequest;
var GetTcpPoseRequest = protocol_1.protocol.GetTcpPoseRequest;
var InverseKinematicRequest = protocol_1.protocol.InverseKinematicRequest;
var MoveByLineRequest = protocol_1.protocol.MoveByLineRequest;
var SetCoordModeRequest = protocol_1.protocol.SetCoordModeRequest;
var GetIOInputStateRequest = protocol_1.protocol.GetIOInputStateRequest;
var GetIOOutputStateRequest = protocol_1.protocol.GetIOOutputStateRequest;
var SetIOStateRequest = protocol_1.protocol.SetIOStateRequest;
var GetRobotStateRequest = protocol_1.protocol.GetRobotStateRequest;
var RunFileRequest = protocol_1.protocol.RunFileRequest;
var GetFileRunningStateRequest = protocol_1.protocol.GetFileRunningStateRequest;
var GetRobotSpeedRequest = protocol_1.protocol.GetRobotSpeedRequest;
var SetRobotSpeedRequest = protocol_1.protocol.SetRobotSpeedRequest;
var GetJointsRequest = protocol_1.protocol.GetJointsRequest;
var MoveByJointRequest = protocol_1.protocol.MoveByJointRequest;
var GetMotorStatusRequest = protocol_1.protocol.GetMotorStatusRequest;
class EliteService extends base_1.RobotService {
    init(cfg) {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.init.call(this, cfg);
            yield Socket.connect("elite", cfg.host, cfg.socketPort);
            yield SFtp.connect(cfg.host, cfg.sFtpPort, cfg.username, cfg.password);
            return yield Socket.current().write(new GetRobotStateRequest());
        });
    }
    moveToJoint(joints, speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let robotSpeed = (yield this.getRobotSpeed()).speed;
                return Socket.current().write(new MoveByJointRequest(joints, speed * 0.2));
            }
            catch (e) {
                console.error(e);
                throw e;
            }
        });
    }
    moveUp(speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.moveByJog(5, speed);
        });
    }
    moveDown(speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.moveByJog(4, speed);
        });
    }
    moveToPoint(pose, speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let point = pose.concat();
            point[point.length - 1] = (0, angle_1.degToRad)(point[point.length - 1]);
            point[point.length - 2] = (0, angle_1.degToRad)(point[point.length - 2]);
            point[point.length - 3] = (0, angle_1.degToRad)(point[point.length - 3]);
            let ikRes = (yield Socket.current().write(new InverseKinematicRequest(point)));
            if (!ikRes.success) {
                throw new Error(ikRes.msg);
            }
            ikRes.pos = ikRes.pos.map((item) => {
                if (item < -180) {
                    item += 360;
                }
                else if (item > 180) {
                    item -= 360;
                }
                return item;
            });
            return Socket.current().write(new MoveByLineRequest(Math.max(speed, 8), ikRes.pos, 2));
        });
    }
    moveByJog(index, speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield Socket.current().write(new MoveByJogRequest(speed * 0.666, index));
        });
    }
    runFile(filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return Socket.current().write(new RunFileRequest(filename));
        });
    }
    uploadFile(path, content) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            path = `/rbctrl/${path}`;
            let res = yield SFtp.current().writeFile(path, content);
            return res;
        });
    }
    upload(fileDataList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const preFilename = "youtooPreUpload.jbi";
            let res = yield this.uploadFile(preFilename, "NOP\nEND");
            let errMsg = '上传文件失败,请检查机器人状态后重试！';
            if (!res.success) {
                throw new Error(errMsg);
            }
            console.log('上传成功');
            res = yield this.setRobotPrepareMove([0, 0, 0, 0, 0, 0]);
            if (!res.success)
                throw new Error(errMsg);
            res = yield this.runFile(preFilename);
            if (!res.success)
                throw new Error(errMsg);
            console.log('运行空文件成功');
            console.log(JSON.stringify(fileDataList));
            try {
                for (let i = 0; i < fileDataList.length; i++) {
                    const item = fileDataList[i];
                    console.log("uploadFile pre send");
                    res = yield this.uploadFile(item.filename, item.content);
                    console.log("uploadFile" + JSON.stringify(res));
                    if (!res.success) {
                        throw new Error(`上传文件失败${item.filename}`);
                    }
                }
                return res;
            }
            catch (e) {
                console.error(JSON.stringify(e));
                throw e;
            }
        });
    }
    stopRobot() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield Socket.current().write(new StopRobotRequest());
        });
    }
    getMotorStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let motorStatus = (yield Socket.current().write(new GetMotorStatusRequest()));
            return motorStatus.state === "synchronous";
        });
    }
    getCurPoint() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let motorStatus = yield this.getMotorStatus();
            if (!motorStatus) {
                throw new Error("伺服电机未同步，请同步后重试！");
            }
            let res = (yield Socket.current().write(new GetTcpPoseRequest()));
            res.pose[res.pose.length - 1] = (0, angle_1.radToDeg)(res.pose[res.pose.length - 1]);
            res.pose[res.pose.length - 2] = (0, angle_1.radToDeg)(res.pose[res.pose.length - 2]);
            res.pose[res.pose.length - 3] = (0, angle_1.radToDeg)(res.pose[res.pose.length - 3]);
            return res;
        });
    }
    getCurJoints() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let motorStatus = yield this.getMotorStatus();
            if (!motorStatus) {
                throw new Error("伺服电机未同步，请同步后重试！");
            }
            return yield Socket.current().write(new GetJointsRequest());
        });
    }
    levelling() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.setRobotPrepareMove([0, 0, 0, 0, 0, 0]);
            let res = (yield this.getCurPoint());
            if (!res.success) {
                throw new Error(res.msg);
            }
            let pose = res.pose;
            pose[pose.length - 3] = 180;
            pose[pose.length - 2] = 0;
            return this.moveToPoint(pose, 5);
        });
    }
    moveRotateByAngle(angle, speed) {
        return this.moveByJog(9, speed);
    }
    moveLineByAngle(angle, speed) {
        return this.moveByJog(0, speed);
    }
    rotateLeft(speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.moveByJog(11, speed);
        });
    }
    rotateRight(speed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.moveByJog(10, speed);
        });
    }
    getInput(addr) {
        return Socket.current().write(new GetIOInputStateRequest(addr));
    }
    getOutput(addr) {
        return Socket.current().write(new GetIOOutputStateRequest(addr));
    }
    setOutput(addr, value) {
        return Socket.current().write(new SetIOStateRequest(addr, value));
    }
    getRobotState() {
        return Socket.current().write(new GetRobotStateRequest());
    }
    setRobotPrepareMove(frame) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const socket = Socket.current();
                let servoRes = yield socket.write(new SetServoRequest(1));
                if (!servoRes.success) {
                    throw new Error(servoRes.msg);
                }
                let setUserFrameRes = yield socket.write(new SetUserFrameRequest(frame));
                if (!setUserFrameRes.success) {
                    throw new Error(setUserFrameRes.msg);
                }
                let setCurrentCoordRes = yield socket.write(new SetCoordModeRequest(3));
                if (!setCurrentCoordRes.success) {
                    throw new Error(setCurrentCoordRes.msg);
                }
                let setUserNumRes = yield socket.write(new SetUserNumRequest(0));
                if (!setUserNumRes.success) {
                    throw new Error(setUserNumRes.msg);
                }
                return setUserNumRes;
            }
            catch (e) {
                throw new Error(`${JSON.stringify(e.message || e)}`);
            }
        });
    }
    getFileRunningState() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return Socket.current().write(new GetFileRunningStateRequest());
        });
    }
    getRobotSpeed() {
        return Socket.current().write(new GetRobotSpeedRequest());
    }
    setRobotSpeed(speed) {
        return Socket.current().write(new SetRobotSpeedRequest(speed));
    }
}
base_1.RobotService.register("elite", new EliteService());
function useRobotService(name) {
    return base_1.RobotService[name];
}
exports.useRobotService = useRobotService;
