"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const common_1 = require("./common");
Blockly.Blocks['pose_set_axis'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('设置', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(this.pointOptions), 'POSE')
            .appendField(new Blockly.FieldDropdown(this.axisOptions), 'AXIS')
            .appendField(new Blockly.FieldLabel('='), 'LABEL_NAME');
        this.appendValueInput('VALUE')
            .setCheck('Number');
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("position_blocks");
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyChangeRoute', that.id, ({ id, name, type, remark }, update) => {
            let field = that.getField('POSE');
            if (!field)
                return;
            update(field, id, `${model_1.PathTypeName[type]}:${name}`);
        });
        common_1.DataListener.instance.onAction('notifyDeleteRoute', that.id, ({ id, name, type, remark }, update) => {
            let field = that.getField('POSE');
            if (field && field.getValue() === id) {
                field.setValue('NONE');
            }
        });
    },
    pointOptions() {
        let rpList = model_1.Project.current().curProgram.robot.paths.filter(e => e.type === 'point').map(e => [`[${model_1.PathTypeName[e.type]}] ${e.name}`, e.id]);
        return [['请选择', 'NONE'], ...rpList];
    },
    axisOptions() {
        return [["X轴", 'X'], ["Y轴", 'Y'], ["Z轴", 'Z'], ["RX轴", 'Rx'], ["RY轴", 'Ry'], ["RZ轴", 'Rz']];
    }
};
Blockly.Blocks['pose_offset_axis'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('设置', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].pointOptions), 'POSE')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].axisOptions), 'AXIS')
            .appendField(new Blockly.FieldLabel('偏移'), 'LABEL_NAME');
        this.appendValueInput('VALUE')
            .setCheck('Number');
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("position_blocks");
        this.optionChange();
    },
    optionChange: Blockly.Blocks['pose_set_axis'].optionChange
};
Blockly.Blocks['pose_get'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('获取', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].pointOptions), 'POSE');
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("position_blocks");
        this.optionChange();
    },
    optionChange: Blockly.Blocks['pose_set_axis'].optionChange
};
Blockly.Blocks["pose_get_axis"] = {
    init: function () {
        const that = this;
        const varField = new Blockly.FieldDropdown(this.varOptions);
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].pointOptions), 'POSE')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].axisOptions), 'AXIS');
        this.setPreviousStatement(false);
        this.setNextStatement(false);
        this.setStyle("math_blocks");
        this.setOutput(true, model_1.VAL_TYPE_CHECK['double']);
        this.optionChange();
    },
    optionChange: Blockly.Blocks['pose_set_axis'].optionChange,
    varOptions: Blockly.Blocks["vars_set"]["varOptions"],
};
Blockly.Blocks["pose_coordinate_convert"] = {
    init: function () {
        const that = this;
        const varField = new Blockly.FieldDropdown(this.varOptions);
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('位姿转换 ', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].pointOptions), 'FROM')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['set_coordinate'].options), 'BASE')
            .appendField(new Blockly.FieldLabel('转换为 ', 'blocklyText'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(Blockly.Blocks['pose_set_axis'].pointOptions), 'TO');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("position_blocks");
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyChangeRoute', that.id, ({ id, name, type, remark }, update) => {
            let fromField = that.getField('FROM');
            let toField = that.getField('TO');
            if (!toField || !fromField)
                return;
            update(fromField, id, `${model_1.PathTypeName[type]}:${name}`);
            update(toField, id, `${model_1.PathTypeName[type]}:${name}`);
        });
        common_1.DataListener.instance.onAction('notifyDeleteRoute', that.id, ({ id, name }, update) => {
            let fromField = that.getField('FROM');
            let toField = that.getField('TO');
            if (!toField || !fromField)
                return;
            let fromFieldVal = that.getFieldValue('FROM');
            let toFieldVal = that.getFieldValue('TO');
            if (id === fromFieldVal)
                fromField.setValue('NONE');
            if (id === toFieldVal)
                toFieldVal.setValue('NONE');
        });
        common_1.DataListener.instance.onAction('notifyChangeUcs', that.id, ({ id, name }, update) => {
            let field = that.getField('BASE');
            if (!field)
                return;
            update(field, id, name);
        });
        common_1.DataListener.instance.onAction('notifyDeleteUcs', that.id, ({ id, name }, update) => {
            let field = that.getField('BASE');
            if (!field)
                return;
            let fieldVal = that.getFieldValue('BASE');
            if (fieldVal === id)
                field.setValue('NONE');
        });
    },
    varOptions: Blockly.Blocks["vars_set"]["varOptions"],
};
