/* unplugin-vue-components disabled */const { ref, computed } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-cfa8ae0e"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "brand-wrap" };
const _hoisted_2 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "型号", -1));
const _hoisted_3 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "dashed" }, null, -1));
const _hoisted_4 = { class: "ip-port-wrap" };
const _hoisted_5 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "ip地址", -1));
const _hoisted_6 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, ".", -1));
const _hoisted_7 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, ".", -1));
const _hoisted_8 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, ".", -1));
const _hoisted_9 = _withScopeId(() => (0, vue_2.createElementVNode)("span", { style: { "width": "50px" } }, "端口", -1));
const _hoisted_10 = { class: "confirm-btn" };
const _hoisted_11 = { class: "robot-connecting-wrap" };
const _hoisted_12 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "机器人正在连接", -1));
const _hoisted_13 = { class: "robot-connecting-wrap" };
const _hoisted_14 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "机器人正在重新连接", -1));
const model_1 = require("@/model");
const service_1 = require("@/rpc/service");
const one_message_1 = require("@/utils/one_message");
const laborer_1 = require("@/store/modules/laborer");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        canClose: {
            type: Boolean,
            default: false
        },
        onConnected: {
            type: Function,
            default: () => {
            }
        }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const isConnectFaild = ref(false);
        const laborerStore = (0, laborer_1.useLaborer)();
        const robot = computed(() => { var _a; return (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.robot; });
        const robotDialog = ref(false);
        const ipArr = ref([0, 0, 0, 0]);
        const port = ref(0);
        const config = computed(() => { var _a; return (_a = robot.value) === null || _a === void 0 ? void 0 : _a.customConfig; });
        const curConnectState = computed({
            get: () => laborerStore.robotConnectState,
            set: (data) => laborerStore.robotConnectState = data
        });
        let reconnectCount = 0;
        function handleRobotChange() {
            var _a, _b;
            if (!robot.value) {
                return;
            }
            ipArr.value = (((_a = config.value) === null || _a === void 0 ? void 0 : _a.host) || "").split('.').map(item => Number(item));
            port.value = ((_b = config.value) === null || _b === void 0 ? void 0 : _b.port) || 0;
        }
        function handleSure() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!robot.value) {
                    return;
                }
                if (!port.value) {
                    one_message_1.oneMessage.error("端口号不能为空!");
                    return;
                }
                if (port.value === 123456) {
                    laborerStore.mode = 'dev';
                    curConnectState.value = 'connected';
                    closeRobotConfig();
                    return;
                }
                laborerStore.mode = 'prod';
                config.value.port = port.value;
                config.value.host = ipArr.value.join('.');
                curConnectState.value = 'connecting';
                yield connectRobot();
            });
        }
        let curConnectId = 0;
        function connectRobot() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let innerId = ++curConnectId;
                try {
                    let res = yield handleConnect();
                    if (curConnectId == innerId) {
                        if (res === null || res === void 0 ? void 0 : res.success) {
                            if (props.onConnected)
                                yield props.onConnected();
                            closeRobotConfig();
                            curConnectState.value = 'connected';
                            isConnectFaild.value = false;
                        }
                        else {
                            curConnectState.value = 'disReconnected';
                            isConnectFaild.value = true;
                        }
                    }
                }
                catch (e) {
                    if (curConnectId == innerId) {
                        curConnectState.value = 'disReconnected';
                        isConnectFaild.value = true;
                    }
                }
            });
        }
        function handleConnect() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                let innerId = curConnectId;
                try {
                    let robotService = (0, service_1.useRobotService)('elite');
                    let res = yield robotService.init({
                        host: config.value.host,
                        socketPort: config.value.port,
                        sFtpPort: 22,
                        username: config.value.username,
                        password: config.value.password
                    });
                    if (res.success || innerId !== curConnectId) {
                        return res;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (innerId !== curConnectId) {
                        return;
                    }
                    throw e;
                }
            });
        }
        function onConnectCancel() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                curConnectId++;
                curConnectState.value = 'disconnect';
            });
        }
        function openRobotConfig() {
            handleRobotChange();
            robotDialog.value = true;
        }
        function closeRobotConfig() {
            robotDialog.value = false;
        }
        function selectAllNum(evt) {
            evt.currentTarget.select();
        }
        __expose({
            openRobotConfig,
            closeRobotConfig
        });
        return (_ctx, _cache) => {
            const _component_el_alert = (0, vue_2.resolveComponent)("el-alert");
            const _component_el_tag = (0, vue_2.resolveComponent)("el-tag");
            const _component_el_input_number = (0, vue_2.resolveComponent)("el-input-number");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_Loading = (0, vue_2.resolveComponent)("Loading");
            const _component_el_icon = (0, vue_2.resolveComponent)("el-icon");
            const _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(_component_el_dialog, {
                    modelValue: (0, vue_2.unref)(robotDialog),
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((0, vue_2.isRef)(robotDialog) ? (robotDialog).value = $event : null)),
                    title: ['disconnect', 'disReconnected'].includes((0, vue_2.unref)(curConnectState)) ? '连接机器人' : '',
                    width: "500px",
                    "show-close": __props.canClose,
                    "close-on-click-modal": __props.canClose,
                    "close-on-press-escape": __props.canClose,
                    center: true
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", null, [
                            (0, vue_2.withDirectives)((0, vue_2.createVNode)(_component_el_alert, {
                                title: "连接机器人失败",
                                type: "error",
                                size: "small",
                                center: "",
                                "show-icon": "",
                                closable: false
                            }, null, 512), [
                                [vue_2.vShow, (0, vue_2.unref)(isConnectFaild)]
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                _hoisted_2,
                                (0, vue_2.createVNode)(_component_el_tag, { type: "info" }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("ELITE艾利特EC66")
                                    ]),
                                    _: 1
                                })
                            ]),
                            _hoisted_3,
                            (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                _hoisted_5,
                                (0, vue_2.createVNode)(_component_el_input_number, {
                                    modelValue: (0, vue_2.unref)(ipArr)[0],
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (((0, vue_2.unref)(ipArr)[0]) = $event)),
                                    class: "ip-input",
                                    max: 255,
                                    min: 0,
                                    controls: false,
                                    onFocus: _cache[1] || (_cache[1] = ($event) => (selectAllNum($event)))
                                }, null, 8, ["modelValue"]),
                                _hoisted_6,
                                (0, vue_2.createVNode)(_component_el_input_number, {
                                    modelValue: (0, vue_2.unref)(ipArr)[1],
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (((0, vue_2.unref)(ipArr)[1]) = $event)),
                                    class: "ip-input",
                                    max: 255,
                                    min: 0,
                                    controls: false,
                                    onFocus: _cache[3] || (_cache[3] = ($event) => (selectAllNum($event)))
                                }, null, 8, ["modelValue"]),
                                _hoisted_7,
                                (0, vue_2.createVNode)(_component_el_input_number, {
                                    modelValue: (0, vue_2.unref)(ipArr)[2],
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (((0, vue_2.unref)(ipArr)[2]) = $event)),
                                    class: "ip-input",
                                    max: 255,
                                    min: 0,
                                    controls: false,
                                    onFocus: _cache[5] || (_cache[5] = ($event) => (selectAllNum($event)))
                                }, null, 8, ["modelValue"]),
                                _hoisted_8,
                                (0, vue_2.createVNode)(_component_el_input_number, {
                                    modelValue: (0, vue_2.unref)(ipArr)[3],
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => (((0, vue_2.unref)(ipArr)[3]) = $event)),
                                    class: "ip-input",
                                    max: 255,
                                    min: 0,
                                    controls: false,
                                    onFocus: _cache[7] || (_cache[7] = ($event) => (selectAllNum($event)))
                                }, null, 8, ["modelValue"]),
                                _hoisted_9,
                                (0, vue_2.createVNode)(_component_el_input_number, {
                                    modelValue: (0, vue_2.unref)(port),
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((0, vue_2.isRef)(port) ? (port).value = $event : null)),
                                    class: "port-input",
                                    controls: false,
                                    min: 0,
                                    onFocus: _cache[9] || (_cache[9] = ($event) => (selectAllNum($event)))
                                }, null, 8, ["modelValue"])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                (0, vue_2.createVNode)(_component_el_button, {
                                    onClick: handleSure,
                                    type: "success",
                                    style: { "margin-right": "5px", "margin-top": "5px" },
                                    icon: "Link",
                                    size: "normal"
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("连接设备 ")
                                    ]),
                                    _: 1
                                })
                            ])
                        ], 512), [
                            [vue_2.vShow, ['disconnect', 'disReconnected'].includes((0, vue_2.unref)(curConnectState))]
                        ]),
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", null, [
                            (0, vue_2.createElementVNode)("div", _hoisted_11, [
                                _hoisted_12,
                                (0, vue_2.createVNode)(_component_el_icon, { class: "loading-wrap is-loading" }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createVNode)(_component_Loading)
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_button, {
                                    class: "cancel-btn",
                                    onClick: onConnectCancel
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("取消")
                                    ]),
                                    _: 1
                                })
                            ])
                        ], 512), [
                            [vue_2.vShow, ['connecting', 'reconnecting'].includes((0, vue_2.unref)(curConnectState))]
                        ]),
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", null, [
                            (0, vue_2.createElementVNode)("div", _hoisted_13, [
                                _hoisted_14,
                                (0, vue_2.createVNode)(_component_el_icon, { class: "loading-wrap is-loading" }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createVNode)(_component_Loading)
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_button, {
                                    class: "cancel-btn",
                                    onClick: onConnectCancel
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("取消")
                                    ]),
                                    _: 1
                                })
                            ])
                        ], 512), [
                            [vue_2.vShow, (0, vue_2.unref)(curConnectState) === 'reconnecting']
                        ])
                    ]),
                    _: 1
                }, 8, ["modelValue", "title", "show-close", "close-on-click-modal", "close-on-press-escape"])
            ]));
        };
    }
});
