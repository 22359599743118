"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataListener = exports.getVarOptions = void 0;
const tslib_1 = require("tslib");
const model_1 = require("@/model");
const utils_1 = require("@/utils");
const work_1 = require("@/store/modules/work");
function getVarOptions(filter = () => true) {
    return () => {
        var _a, _b;
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        let program = project.curProgram.program;
        console.assert(robot);
        console.assert(program);
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        let globalVars = robot.code.globalVars.filter(Var => Var.type != model_1.VAL_TYPE.LABEL).filter(filter).map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [`${isSub ? '[主程序]' : ''} ${Var.name}`, utils_1.Utils.pinyin(Var.name)];
        });
        let localVars = program.localVars.filter(Var => Var.type != model_1.VAL_TYPE.LABEL && !Var.isTemp).filter(filter).map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [`${Var.name}`, utils_1.Utils.pinyin(Var.name)];
        });
        if (!globalVars.length && !localVars.length)
            return [['选择变量', 'NONE']];
        return [['选择变量', 'NONE'], ...globalVars, ...localVars];
    };
}
exports.getVarOptions = getVarOptions;
class DataListener {
    constructor() {
        this.funcs = new Map();
        const store = (0, work_1.useWork)();
        store.$onAction(({ name, store, args, after, onError }) => {
            after((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let [model] = args;
                Object.values(this.funcs).forEach(item => item.forEach(([event, func]) => {
                    if (name === event) {
                        try {
                            func(model, (field, id, textContent) => {
                                if (field && field.getValue() === id) {
                                    field['selectedOption'] = [textContent, id];
                                    field.forceRerender();
                                }
                            });
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }
                }));
            }));
        });
    }
    static get instance() {
        if (!this._instance) {
            this._instance = new DataListener();
        }
        return this._instance;
    }
    reset() {
        this.funcs = new Map();
    }
    onAction(name, blockId, func) {
        console.log('name', name);
        let actions = this.funcs[blockId] = this.funcs[blockId] || [];
        let action = actions.find(([actionName]) => actionName === name);
        if (action)
            action[1] = func;
        else
            actions.push([name, func]);
    }
}
exports.DataListener = DataListener;
