"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
Blockly.defineBlocksWithJsonArray([
    {
        'type': 'logic_operation',
        'message0': '%1 %2 %3',
        'args0': [
            {
                'type': 'input_value',
                'name': 'A',
                "check": "Boolean",
            },
            {
                'type': 'field_dropdown',
                'name': 'OP',
                'options': [
                    ['并且', 'AND'],
                    ['或者', 'OR'],
                ],
            },
            {
                'type': 'input_value',
                'name': 'B',
                "check": "Boolean",
            },
        ],
        'inputsInline': true,
        'output': 'Boolean',
        'style': 'logic_blocks',
        'extensions': ['logic_compare', 'logic_op_tooltip'],
    },
    {
        'type': 'logic_compare',
        'message0': '%1 %2 %3',
        'args0': [
            {
                'type': 'input_value',
                'name': 'A',
                "check": ["Boolean", "String", "Number", "Type"],
            },
            {
                'type': 'field_dropdown',
                'name': 'OP',
                'options': [
                    ['等于', 'EQ'],
                    ['不等于', 'NEQ'],
                    ['小于', 'LT'],
                    ['小于等于', 'LTE'],
                    ['大于', 'GT'],
                    ['大于等于', 'GTE'],
                ],
            },
            {
                'type': 'input_value',
                'name': 'B',
                "check": ["Boolean", "String", "Number", "Type"],
            },
        ],
        'inputsInline': true,
        'output': 'Boolean',
        'style': 'logic_blocks',
        'extensions': ['logic_compare', 'logic_op_tooltip'],
    }, {
        'type': 'logic_boolean',
        'message0': '%1',
        'output': 'Boolean',
        'args0': [
            {
                'type': 'field_dropdown',
                'name': 'BOOL',
                'options': [
                    ['真', "TRUE"],
                    ['假', "FALSE"],
                ]
            }
        ],
        'style': 'logic_blocks',
        'extensions': ['parent_tooltip_when_inline'],
    }
]);
