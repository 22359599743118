"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const utils_1 = require("@/utils");
Blockly.defineBlocksWithJsonArray([
    {
        "type": "run_sync",
        "message0": "%1 %2",
        "args0": [{
                "type": "field_label", "text": "同时运行", "class": "blocklyBold"
            }, {
                "type": "field_dropdown",
                "name": "PROGRAM",
                "options": [["选择程序", 'ROOT'], ["程序1", 'PG_1'], ['程序2', 'PG_2'], ['程序3', 'PG_3']]
            },],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "extensions": []
    }, {
        "type": "controls_if",
        "message0": "如果 %1 则",
        "args0": [{
                "type": "input_value", "name": "IF0", "check": "Boolean"
            }],
        "message1": "%1",
        "args1": [{
                "type": "input_statement", "name": "DO"
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "tooltip": "%{BKY_CONTROLS_REPEAT_TOOLTIP}",
        "mutator": "controls_if_mutator",
        "extensions": ["controls_if_tooltip"]
    }, {
        "type": "controls_repeat_ext",
        "message0": "重复 %1 次",
        "args0": [{
                "type": "input_value", "name": "TIMES", "check": "Number"
            }],
        "message1": "执行 %1",
        "args1": [{
                "type": "input_statement", "name": "DO"
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "tooltip": "%{BKY_CONTROLS_WHILEUNTIL_TOOLTIP_WHILE}",
        'extensions': ['controls_whileUntil_tooltip'],
    }, {
        "type": "controls_while",
        "message0": "当 %1 则",
        "args0": [{
                "type": "input_value", "name": "IF0", "check": "Boolean"
            }],
        "message1": "循环执行 %1",
        "args1": [{
                "type": "input_statement", "name": "DO"
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "tooltip": "%{BKY_CONTROLS_WHILEUNTIL_TOOLTIP_WHILE}",
        'extensions': ['controls_whileUntil_tooltip'],
    }, {
        "type": "timer_wait",
        "message0": "%1 %2 %3",
        "args0": [{
                "type": "field_label", "text": "等待", "class": "blocklyBold"
            }, {
                "type": "field_tag", "text": "时间（毫秒）", "class": "labelBlock"
            }, {
                "type": "field_number", "name": "TIMES", "value": 100, "min": 0, "precision": 1
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "extensions": []
    }, {
        "type": "timer_def",
        "message0": "%1 %2 %3",
        "args0": [{
                "type": "field_label", "text": "秒表", "class": "blocklyBold"
            }, {
                "type": "field_label", "text": "创建"
            }, {
                "type": "field_input", "name": "VAR", "text": "秒表名称", "check": "Text"
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "loop_blocks",
        "extensions": []
    }
]);
Blockly.Blocks['tag_mark'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('书签', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldLabel('定义'), 'LABEL_DEF')
            .appendField(new Blockly.FieldTextInput('书签名称', this.varChange), 'TAG');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("loop_blocks");
    },
    varChange: Blockly.Blocks['vars_def']['varChange'],
};
Blockly.Blocks['tag_jump'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('书签', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldLabel('回到'), 'LABEL_JUMP')
            .appendField(new Blockly.FieldDropdown(this.tagOptions), 'TAG');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("loop_blocks");
    },
    tagOptions: function () {
        var _a, _b;
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        let program = project.curProgram.program;
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        let labels = (isSub ? program.localVars : robot.code.globalVars)
            .filter(Var => Var.type == model_1.VAL_TYPE.LABEL)
            .map((Var) => [Var.name, utils_1.Utils.pinyin(Var.name)]);
        if (labels.length) {
            return [...labels];
        }
        else {
            return [['选择书签', 'NONE']];
        }
    },
};
Blockly.Blocks['timer_state'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('秒表', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown([["选择", 'NONE'], ["开始", 'ClockStart'], ["停止", 'ClockStop'], ["读取", 'ClockRead'], ["重置", 'ClockReset']]), 'SIGN_TYPE')
            .appendField(new Blockly.FieldDropdown(this.tagOptions), 'VAR');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("loop_blocks");
    },
    tagOptions: function () {
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return [['选择秒表', 'NONE']];
        let blocks = ws.getBlocksByType("timer_def", true);
        let tags = blocks.map(block => {
            let tag = block.getFieldValue("VAR");
            return [tag, utils_1.Utils.pinyin(tag)];
        });
        return [['选择秒表', 'NONE'], ...tags];
    },
};
Blockly.Blocks['run_invoke'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('调用', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(this.pgOptions), 'PROGRAM');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("loop_blocks");
    },
    pgOptions: function () {
        let project = model_1.Project.current();
        let programs = project.curProgram.robot.code.programs.filter(pg => pg.name != '主程序').map(pg => [pg.name, utils_1.Utils.pinyin(pg.name)]);
        return [['选择子程序', 'NONE'], ...programs];
    },
};
Blockly.Blocks['run_program'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('调用', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(this.pgOptions), 'PROGRAM');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("loop_blocks");
    },
    pgOptions: function () {
        let project = model_1.Project.current();
        let program = project.curProgram;
        let programs = program.robot.code.programs
            .filter(pg => { var _a; return pg.name != '主程序' && pg.id !== ((_a = program.program) === null || _a === void 0 ? void 0 : _a.id); })
            .map(pg => [pg.name, pg.id]);
        return [['选择子程序', 'NONE'], ...programs];
    },
};
