/* unplugin-vue-components disabled */const { ref, watchEffect } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const vue_3 = require("vue");
const one_message_1 = require("@/utils/one_message");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        isNullable: {
            type: Boolean,
            default: false,
        },
        beforeChange: {
            type: Function,
            default: (value) => true,
        },
        maxChar: {
            type: Number,
            default: 0
        }
    },
    emits: ['update:model', 'onChange'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const attrs = (0, vue_3.useAttrs)();
        const emit = __emit;
        const myModel = ref(attrs.model);
        watchEffect(() => {
            if (attrs.model) {
                myModel.value = attrs.model;
            }
        });
        function checkWord() {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (props.beforeChange && !props.beforeChange(myModel.value)) {
                    myModel.value = attrs.model;
                    return;
                }
                if ((myModel.value == null || myModel.value == '') && !props.isNullable) {
                    (0, one_message_1.oneMessage)({ message: '输入不可为空' });
                    myModel.value = attrs.model;
                    return;
                }
                emit('update:model', myModel.value);
                emit('onChange', myModel.value);
            });
        }
        function onInput(val) {
            if (props.maxChar > 0) {
                myModel.value = val.substring(0, props.maxChar);
            }
        }
        return (_ctx, _cache) => {
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, (0, vue_2.mergeProps)(Object.assign(Object.assign({}, props), _ctx.$attrs), {
                modelValue: (0, vue_2.unref)(myModel),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((0, vue_2.isRef)(myModel) ? (myModel).value = $event : null)),
                onChange: checkWord,
                onInput: onInput
            }), (0, vue_2.createSlots)({ _: 2 }, [
                (_ctx.$slots.suffix)
                    ? {
                        name: "suffix",
                        fn: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "suffix")
                        ]),
                        key: "0"
                    }
                    : undefined,
                (_ctx.$slots.prefix)
                    ? {
                        name: "prefix",
                        fn: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "prefix")
                        ]),
                        key: "1"
                    }
                    : undefined,
                (_ctx.$slots.prepend)
                    ? {
                        name: "prepend",
                        fn: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "prepend")
                        ]),
                        key: "2"
                    }
                    : undefined,
                (_ctx.$slots.append)
                    ? {
                        name: "append",
                        fn: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "append")
                        ]),
                        key: "3"
                    }
                    : undefined
            ]), 1040, ["modelValue"]));
        };
    }
});
