"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Async = void 0;
const tslib_1 = require("tslib");
class Async {
    static waitTill(condition, timeout = 10000, period = 100) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            timeout = Math.max(0, timeout);
            let start = Date.now();
            while (!condition()) {
                if (Date.now() - start > timeout)
                    break;
                yield new Promise(timeout => {
                    setTimeout(timeout, 100);
                });
            }
        });
    }
}
exports.Async = Async;
