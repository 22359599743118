"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneMessage = void 0;
const element_plus_1 = require("element-plus");
oneMessage.error = (msg) => {
    oneMessage({ message: msg, type: 'error' });
};
oneMessage.success = (msg) => {
    oneMessage({ message: msg, type: 'success' });
};
oneMessage.warning = (msg) => {
    oneMessage({ message: msg, type: 'warning' });
};
function oneMessage(options) {
    let cfg = { message: '', grouping: true };
    if (typeof options === "string") {
        cfg.message = options;
        options = cfg;
    }
    if (options.grouping == null) {
        options.grouping = true;
    }
    (0, element_plus_1.ElMessage)(options);
}
exports.oneMessage = oneMessage;
