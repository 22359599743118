"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processor = void 0;
var processor;
(function (processor) {
    class ResponseProcessor {
        static register(name, respProcessor) {
            ResponseProcessor[name] = respProcessor;
        }
    }
    processor.ResponseProcessor = ResponseProcessor;
    class EliteSocketResponseProcessor extends ResponseProcessor {
        reduce(data) {
            let count = 0;
            let lastIndex = 0;
            let datas = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i] === "{") {
                    count++;
                }
                else if (data[i] === "}") {
                    count--;
                }
                if (count === 0) {
                    datas.push(data.substring(lastIndex, i + 1));
                    lastIndex = i + 1;
                }
            }
            return datas;
        }
    }
    processor.EliteSocketResponseProcessor = EliteSocketResponseProcessor;
    ResponseProcessor.register('socket.elite', new EliteSocketResponseProcessor());
})(processor || (exports.processor = processor = {}));
