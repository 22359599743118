"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWork = exports.Node = exports.VIR_NODE_TYPE = exports.NODE_TYPE = void 0;
const pinia_1 = require("pinia");
const model_1 = require("@/model");
var NODE_TYPE;
(function (NODE_TYPE) {
    NODE_TYPE["NONE"] = "None";
    NODE_TYPE["ROBOT"] = "Robot";
    NODE_TYPE["TOOL"] = "Tool";
    NODE_TYPE["DEVICE"] = "Device";
    NODE_TYPE["Table"] = "Table";
    NODE_TYPE["TCP"] = "Tcp";
    NODE_TYPE["UCS"] = "Ucs";
    NODE_TYPE["PATH"] = "Path";
    NODE_TYPE["MENU"] = "Menu";
})(NODE_TYPE || (exports.NODE_TYPE = NODE_TYPE = {}));
var VIR_NODE_TYPE;
(function (VIR_NODE_TYPE) {
    VIR_NODE_TYPE["N_PATH"] = "N_Path";
    VIR_NODE_TYPE["N_TAIL"] = "N_TAIL";
    VIR_NODE_TYPE["N_TCP"] = "N_TCP";
    VIR_NODE_TYPE["N_PROGRAM"] = "N_Program";
})(VIR_NODE_TYPE || (exports.VIR_NODE_TYPE = VIR_NODE_TYPE = {}));
class Node {
    constructor({ type, rootId, target, }) {
        this.type = NODE_TYPE.NONE;
        console.assert(!!rootId, "rootId cannot be empty");
        this.rootId = rootId;
        this.type = type;
        this.target = target || this;
    }
    get id() {
        return this.target.id;
    }
    get rootMachine() {
        return model_1.Project.current().findMachineById(this.rootId);
    }
}
exports.Node = Node;
class State {
    constructor() {
        this.projects = [];
        this.selectMode = "normal";
        this.curNode = null;
        this.projectSaveState = "";
    }
}
exports.useWork = (0, pinia_1.defineStore)("work", {
    state: () => new State(),
    getters: {
        curDevice() {
            var _a;
            return (_a = this.curNode) === null || _a === void 0 ? void 0 : _a.rootMachine;
        },
        curDeviceId() {
            var _a;
            return (_a = this.curNode) === null || _a === void 0 ? void 0 : _a.rootId;
        },
    },
    actions: {
        resetProject() {
            this.curNode = null;
            this.selectMode = "normal";
            this.projectSaveState = "";
            this.babylonScene = null;
            this.projects.splice(0, this.projects.length);
        },
        notifyChannel(event, params) {
            console.log("notifyEvent");
        },
        notifyChannelCallback(event, params) {
            console.log("notifyEventCallback");
        },
        notifyAddMachine(machine, parentId) {
            console.log("notifyAddMachine");
        },
        notifyRemoveMachine(id) {
            console.log("notifyRemoveMachine");
        },
        notifyHideMachine(id) {
            console.log("notifyHideMachine");
        },
        notifyShowMachine(id) {
            console.log("notifyShowMachine");
        },
        notifyRemovePath(id) {
            console.log("notifyRemovePath");
        },
        notifySelectMachine(id) {
            console.log("notifySelectMachine");
        },
        notifyChangeProgram() {
            console.log("notifyChangeProgram");
        },
        notifyChangeProject() {
            console.log("notifyChangeProject");
        },
        notifyChangeProgramName(model) {
            console.log("notifyChangeProgramName");
        },
        notifyChangeRoute(model) {
            console.log("notifyChangeRoute");
        },
        notifyDeleteRoute(model) {
            console.log("notifyDeleteRoute");
        },
        notifyChangeIO(model) {
            console.log("notifyChangeIO");
        },
        notifyDeleteIO(model) {
            console.log("notifyDeleteIO");
        },
        notifyChangeUcs(model) {
            console.log("notifyChangeUcs");
        },
        notifyDeleteUcs(model) {
            console.log("notifyDeleteUcs");
        },
        selectNode(target) {
            if (target == null) {
                this.curNode = null;
                this.notifySelectMachine("");
                return;
            }
            else if (target === null || target === void 0 ? void 0 : target.points) {
                let path = target;
                this.curNode = new Node({
                    rootId: model_1.Project.current().findMachineById(path.id).id,
                    type: NODE_TYPE.PATH,
                    target: target,
                });
            }
            else if (target.rootId) {
                let menuNode = target;
                if (Object.values(VIR_NODE_TYPE).indexOf(menuNode.type) !== -1) {
                    this.curNode = new Node({
                        rootId: menuNode.rootId,
                        type: NODE_TYPE.MENU,
                        target: target,
                    });
                }
                else {
                    let machine = model_1.Project.current().findTargetById(menuNode.id);
                    this.selectNode(machine);
                    return;
                }
            }
            else {
                let entity = target;
                let options = {
                    id: entity.id,
                    rootId: model_1.Project.current().findMachineById(target.id).id,
                    target: entity,
                    type: NODE_TYPE.NONE,
                };
                if (entity.type === model_1.ENTITY_TYPE.ROBOT) {
                    options.type = NODE_TYPE.ROBOT;
                }
                else if (entity.type === model_1.ENTITY_TYPE.DEVICE) {
                    options.type = NODE_TYPE.DEVICE;
                }
                else if (entity.type === model_1.ENTITY_TYPE.UCS) {
                    options.type = NODE_TYPE.UCS;
                }
                else if (entity.type === model_1.ENTITY_TYPE.PATH) {
                    options.type = NODE_TYPE.PATH;
                }
                else if (entity.type === model_1.ENTITY_TYPE.TCP) {
                    options.type = NODE_TYPE.TCP;
                }
                else if (entity.type === model_1.ENTITY_TYPE.TOOL) {
                    options.type = NODE_TYPE.TOOL;
                }
                else {
                    options.type = NODE_TYPE.NONE;
                }
                this.curNode = new Node(options);
            }
            this.notifySelectMachine(target.id);
        },
    },
});
