"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const common_1 = require("./common");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const utils_1 = require("@/utils");
Blockly.Blocks['move_to'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('运动', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(function () {
            return [["轨迹运动", "move_path"], ["直线运动", "move_point"], ["相对直线运动", "move_line"], ["单关节运动", "move_skeleton"], ["关节运动", "move_joints"]];
        }, this.typeChange), 'MOVE_TYPE');
        const speed = this.appendDummyInput('SPEED')
            .appendField("移动速度:")
            .appendField(new Blockly.FieldNumber(100, 1, 100, 1), "SPEED")
            .appendField("(%)");
        const pl = this.appendDummyInput('PL')
            .appendField("平滑度:")
            .appendField(new Blockly.FieldDropdown(function () { return [["精准", "0.0"], ["普通", "0.6"], ["平滑", "1.0"]]; }, this.plChange), 'PL');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("move_blocks");
        this.typeChange("all");
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyChangeRoute', that.id, ({ id, name, type, remark }, update) => {
            let field = that.getField('PATH') ||
                that.getField('AXIS') ||
                that.getField('POINT') ||
                that.getField('JOINT') ||
                that.getField('JOINTS');
            if (!field)
                return;
            update(field, id, `${model_1.PathTypeName[type]}:${name}`);
        });
        common_1.DataListener.instance.onAction('notifyDeleteRoute', that.id, ({ id, name, type, remark }, update) => {
            let field = that.getField('PATH') ||
                that.getField('AXIS') ||
                that.getField('POINT') ||
                that.getField('JOINT') ||
                that.getField('JOINTS');
            if (field && field.getValue() === id) {
                field.setValue('NONE');
            }
        });
        common_1.DataListener.instance.onAction('notifyChangeUcs', that.id, ({ id, name }, update) => {
            let field = that.getField('FRAME');
            if (!field)
                return;
            update(field, id, name);
        });
        common_1.DataListener.instance.onAction('notifyDeleteUcs', that.id, ({ id, name, type, remark }, update) => {
            let field = that.getField('FRAME');
            if (field && field.getValue() === id) {
                field.setValue('NONE');
            }
        });
    },
    plChange: function (newVal) {
    },
    typeChange: function (type, visible = true) {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        console.assert(robot);
        const blockTypes = ["move_path", "move_point", "move_line", "move_skeleton", "move_joints"];
        if (type === "all") {
            blockTypes.forEach(type => this.typeChange(type, false));
            return;
        }
        let input = that.getInput(type);
        if (visible) {
            blockTypes.forEach(t => {
                if (t !== type)
                    that.removeInput(t, true);
            });
            if (input) {
                input.visible = visible;
                return type;
            }
        }
        input = input || that.appendDummyInput(type);
        if (type == "move_path") {
            input
                .appendField("使")
                .appendField(new Blockly.FieldDropdown(function () {
                var _a;
                let tcps = (((_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.robot.tcps) || []).filter(tcp => tcp.reference.frame !== 'rp').map(tcp => [tcp.name, utils_1.Utils.pinyin(tcp.id)]);
                if (tcps.length)
                    return tcps;
                return [];
            }), "TOOL")
                .appendField("沿")
                .appendField(new Blockly.FieldDropdown(function () {
                let paths = robot.paths.filter(path => !['point', 'joints', 'jointPoint'].includes(path.type) && !path.ioId);
                let pathsOption = paths.map(path => [`${model_1.PathTypeName[path.type]}:${path.name}`, path.id]);
                return [["选择轨迹", "NONE"]].concat(pathsOption);
            }), "PATH");
        }
        else if (type == "move_point") {
            input
                .appendField(new Blockly.FieldDropdown(function () {
                return model_1.Project.current().curProgram.robot.tcps.filter(tcp => tcp.reference.frame !== 'rp').map(tcp => [tcp.name, utils_1.Utils.pinyin(tcp.id)]);
            }), "TOOL")
                .appendField("直线移动到")
                .appendField(new Blockly.FieldDropdown(function () {
                let paths = robot.paths.filter(path => path.type === 'point');
                let pathsOption = paths.map(path => [`${model_1.PathTypeName[path.type]}:${path.name}`, path.id]);
                return [["选择点", "NONE"]].concat(pathsOption);
            }, function (type) {
                if (type === "INPUT") {
                    that
                        .appendDummyInput("INPUT")
                        .appendField("目标位置点:")
                        .appendField("x")
                        .appendField(new Blockly.FieldNumber(0), "X")
                        .appendField("y")
                        .appendField(new Blockly.FieldNumber(0), "Y")
                        .appendField("z")
                        .appendField(new Blockly.FieldNumber(0), "Z")
                        .appendField("Rx")
                        .appendField(new Blockly.FieldNumber(0), "Rx")
                        .appendField("Ry")
                        .appendField(new Blockly.FieldNumber(0), "Ry")
                        .appendField("Rz")
                        .appendField(new Blockly.FieldNumber(0), "Rz");
                }
                else {
                    that.removeInput("INPUT", true);
                }
                return type;
            }), "POINT");
        }
        else if (type == "move_line") {
            let frame = new Blockly.FieldDropdown(function () {
                var _a;
                let rps = (((_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.robot.tcps) || []).filter(tcp => tcp.reference.frame === 'rp');
                let userCoord = rps.map(tcp => [tcp.name, tcp.id]);
                return [['请选择', 'NONE'], ['基坐标系', '$base'], ['工具坐标系', '$tool'], ...userCoord];
            });
            frame.setValue('$base');
            input
                .appendField("使")
                .appendField(new Blockly.FieldDropdown(function () {
                var _a;
                let tcps = (((_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.robot.tcps) || []).filter(tcp => tcp.reference.frame != 'rp').map(tcp => [tcp.name, tcp.id]);
                if (tcps.length)
                    return tcps;
                return [];
            }), "TOOL")
                .appendField("沿")
                .appendField(frame, "FRAME")
                .appendField(new Blockly.FieldDropdown(function () {
                return [["X轴", 'X'], ['Y轴', 'Y'], ['Z轴', 'Z']];
            }, function (type) {
                that.removeInput("INPUT", true);
                return type;
            }), "AXIS")
                .appendField("移动")
                .appendField(new Blockly.FieldNumber(0, null, null, 0.01), "DISTANCE")
                .appendField("(mm)");
        }
        else if (type == "move_skeleton") {
            input
                .appendField("机器人关节")
                .appendField(new Blockly.FieldDropdown(function () {
                let axes = model_1.Project.current().curProgram.robot.model.z_axes.length.toString();
                return [["轴1", '1'], ['轴2', '2'], ['轴3', '3'], ['轴4', '4'], ['轴5', '5'], ['轴6', '6'], ['轴7', '7'], ['轴8', '8']].filter(item => item[1] < axes).reverse();
            }), "JOINT")
                .appendField("转")
                .appendField(new Blockly.FieldNumber(100, -360, 360, 0.01), "ANGLE")
                .appendField("(角度)");
        }
        else if (type == "move_joints") {
            input
                .appendField("机器人关节沿")
                .appendField(new Blockly.FieldDropdown(function () {
                let paths = robot.paths.filter(path => path.type === 'joints' || path.type === 'jointPoint');
                let pathsOption = paths.map(path => [`${model_1.PathTypeName[path.type]}:${path.name}`, path.id]);
                return [["选择点", "NONE"]].concat(pathsOption);
            }), "JOINTS")
                .appendField("运动");
        }
        input.visible = visible;
        return type;
    }
};
Blockly.Blocks['move_material'] = {
    init: function () {
        this.jsonInit({
            "type": "controls_if",
            "message0": "自动取料",
            "args0": [],
            "message1": "运行速度 %1(%) 步进 %2(mm)",
            "args1": [{
                    "type": "field_number", "name": "SPEED", "value": 100, "min": 1, "max": 100, "precision": 1
                }, {
                    "type": "field_number", "name": "STEP", "value": 20, "min": 1, "max": 25, "precision": 1
                }],
            "message2": "当取料异常 执行%1",
            "args2": [{
                    "type": "input_statement", "name": "FAIL"
                }],
            "previousStatement": null,
            "nextStatement": null,
            "style": "move_blocks",
            "tooltip": "%{BKY_ROBOT_AUTO_RUN_TOOLTIP}",
        });
        const input = this.appendDummyInput()
            .appendField("使用")
            .appendField(new Blockly.FieldDropdown(function () {
            var _a;
            let tcps = (((_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.robot.tcps) || []).map(tcp => [tcp.name, tcp.id]);
            if (tcps.length)
                return tcps;
            return [];
        }), "TOOL");
        this.moveNumberedInputBefore(3, 1);
        this.appendIO("夹具信号IO", "I", "IO_IN");
        this.moveNumberedInputBefore(4, 3);
        this.appendIO("夹具控制IO", "O", "IO_OT");
        this.moveNumberedInputBefore(5, 4);
        this.appendIO("补料完成IO", "I", "IO_CT");
        this.moveNumberedInputBefore(6, 5);
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyChangeIO', that.id, ({ id, name, type, remark }, update) => {
            let text = `${name}：${remark || model_1.IO_TYPE_NAME[type]}`;
            let inField = that.getField('IO_IN');
            let otField = that.getField('IO_OT');
            let ctField = that.getField('IO_CT');
            let field;
            if ((inField === null || inField === void 0 ? void 0 : inField.selectedOption[1]) === id) {
                field = inField;
            }
            else if ((otField === null || otField === void 0 ? void 0 : otField.selectedOption[1]) === id) {
                field = otField;
            }
            else if ((ctField === null || ctField === void 0 ? void 0 : ctField.selectedOption[1]) === id) {
                field = ctField;
            }
            if (field)
                update(field, id, text);
        });
        common_1.DataListener.instance.onAction('notifyDeleteIO', that.id, ({ id, name, type, remark }, update) => {
            let text = `${name}：${remark || model_1.IO_TYPE_NAME[type]}`;
            let inField = that.getField('IO_IN');
            let otField = that.getField('IO_OT');
            let ctField = that.getField('IO_CT');
            let field;
            if ((inField === null || inField === void 0 ? void 0 : inField.selectedOption[1]) === id) {
                field = inField;
            }
            else if ((otField === null || otField === void 0 ? void 0 : otField.selectedOption[1]) === id) {
                field = otField;
            }
            else if ((ctField === null || ctField === void 0 ? void 0 : ctField.selectedOption[1]) === id) {
                field = ctField;
            }
            if (field && field.getValue() === id) {
                field.setValue("NONE");
            }
        });
    },
    appendIO: function (label, flag, id) {
        return this.appendDummyInput()
            .appendField(label)
            .appendField(new Blockly.FieldDropdown(function () {
            let project = model_1.Project.current();
            let robot = project.curProgram.robot;
            console.assert(robot);
            let ioList;
            if (robot.type === model_1.ENTITY_TYPE.ROBOT && (ioList = model_1.Robot.current.filterIO(io => io.type.indexOf(flag) >= 0 && io.usingType !== 'feedBin')).length) {
                return [...ioList.map(io => {
                        return [`${io.name}：${io.remark || model_1.IO_TYPE_NAME[io.type]}`, io.id];
                    })];
            }
            return [['选择IO', 'NONE']];
        }), id);
    }
};
