/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-41b5964f"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { id: "tips" }, [
    (0, vue_2.createElementVNode)("span", null, "项目版本冲突，请选择需要保留的版本。")
], -1));
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    setup(__props, { expose: __expose }) {
        const openDialog = (0, vue_3.ref)(false);
        const selectRadio = (0, vue_3.ref)('remote');
        const remoteDate = (0, vue_3.ref)();
        const localDate = (0, vue_3.ref)();
        let res;
        function ok() {
            openDialog.value = false;
            if (res)
                res(selectRadio.value);
            res = null;
        }
        function openProjectConflictDialog(remoteTimestamp, localTimestamp) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                const getTimeStr = (timestamp) => {
                    let dateObj = new Date(timestamp);
                    let year = dateObj.getFullYear();
                    let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                    let day = dateObj.getDate().toString().padStart(2, '0');
                    let hour = dateObj.getHours().toString().padStart(2, '0');
                    let minute = dateObj.getMinutes().toString().padStart(2, '0');
                    return `${year}-${month}-${day} ${hour}:${minute}`;
                };
                remoteDate.value = getTimeStr(remoteTimestamp);
                localDate.value = getTimeStr(localTimestamp);
                openDialog.value = true;
                return new Promise((resolve, rej) => {
                    res = resolve;
                });
            });
        }
        const onDialogClose = () => {
            remoteDate.value = null;
            localDate.value = null;
        };
        __expose({
            openProjectConflictDialog
        });
        return (_ctx, _cache) => {
            const _component_el_radio = (0, vue_2.resolveComponent)("el-radio");
            const _component_el_radio_group = (0, vue_2.resolveComponent)("el-radio-group");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                modelValue: openDialog.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((openDialog).value = $event)),
                width: "500",
                "show-close": false,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                onClose: onDialogClose
            }, {
                footer: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createVNode)(_component_el_button, {
                        type: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event) => (ok()))
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createTextVNode)("确认")
                        ]),
                        _: 1
                    })
                ]),
                default: (0, vue_2.withCtx)(() => [
                    _hoisted_1,
                    (0, vue_2.createVNode)(_component_el_radio_group, {
                        modelValue: selectRadio.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectRadio).value = $event))
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createVNode)(_component_el_radio, {
                                label: "remote",
                                size: "large"
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createTextVNode)("最近修改(" + (0, vue_2.toDisplayString)(remoteDate.value) + ")的版本", 1)
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_radio, {
                                label: "local",
                                size: "large"
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createTextVNode)("本设备缓存(" + (0, vue_2.toDisplayString)(localDate.value) + ")的版本", 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }, 8, ["modelValue"])
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
