"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64ToString = exports.stringToBase64 = void 0;
const stringToBase64 = (str) => {
    let encoder = new TextEncoder();
    let data = encoder.encode(str);
    let binaryArray = Array.from(data);
    return btoa(String.fromCharCode.apply(null, binaryArray));
};
exports.stringToBase64 = stringToBase64;
const base64ToString = (base64) => {
    const binaryString = atob(base64);
    const data = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        data[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(data);
};
exports.base64ToString = base64ToString;
