import Blockly from "blockly";
import {FieldTag} from "../fields/tag";
import {ToolboxCategory} from "./toolbox";

/**
 * @return {!Object} An object containing sizing and path information about
 *     collapsed block indicators.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makeJaggedTeeth = function() {
  var height = this.JAGGED_TEETH_HEIGHT;
  var width = this.JAGGED_TEETH_WIDTH;

  var mainPath =
    Blockly.utils.svgPaths.line(
      [
        Blockly.utils.svgPaths.point(width, height / 4),
        Blockly.utils.svgPaths.point(-width * 2, height / 2),
        Blockly.utils.svgPaths.point(width, height / 4)
      ]);
  return {
    height: height,
    width: width,
    path: mainPath
  };
};

/**
 * @return {!Object} An object containing sizing and path information about
 *     start hats.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makeStartHat = function() {
  var height = this.START_HAT_HEIGHT;
  var width = this.START_HAT_WIDTH;

  var mainPath =
    Blockly.utils.svgPaths.curve('c',
      [
        Blockly.utils.svgPaths.point(30, -height),
        Blockly.utils.svgPaths.point(70, -height),
        Blockly.utils.svgPaths.point(width, 0)
      ]);
  return {
    height: height,
    width: width,
    path: mainPath
  };
};

/**
 * @return {!Object} An object containing sizing and path information about
 *     puzzle tabs.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makePuzzleTab = function() {
  var width = this.TAB_WIDTH;
  var height = this.TAB_HEIGHT;

  function makeMainPath(up) {
    var forward = up ? -1 : 1;
    return Blockly.utils.svgPaths.line([
      Blockly.utils.svgPaths.point(0, 0),
      Blockly.utils.svgPaths.point(0, forward * height),
      Blockly.utils.svgPaths.point(0, 0),
    ])
  }

  // c 0,-10  -8,8  -8,-7.5  s 8,2.5  8,-7.5
  var pathUp = makeMainPath(true);
  // c 0,10  -8,-8  -8,7.5  s 8,-2.5  8,7.5
  var pathDown = makeMainPath(false);

  return {
    type: this.SHAPES.PUZZLE,
    width: width,
    height: height,
    pathDown: pathDown,
    pathUp: pathUp
  };
};


/**
 * @return {!Object} An object containing sizing and path information about
 *     puzzle tab highlights.
 * @package
 */
Blockly.geras.HighlightConstantProvider.prototype.makePuzzleTab = function() {
  var width = this.constantProvider.TAB_WIDTH;
  var height = this.constantProvider.TAB_HEIGHT;

  // This is how much of the vertical block edge is actually drawn by the puzzle
  // tab.
  var verticalOverlap = 2.5;

  var highlightRtlUp =
    Blockly.utils.svgPaths.moveBy(-2, -height + verticalOverlap + 0.9) +
    Blockly.utils.svgPaths.lineTo(width * -0.45, -2.1);

  var highlightRtlDown =
    Blockly.utils.svgPaths.lineOnAxis('v', verticalOverlap) +
    Blockly.utils.svgPaths.moveBy(-width * 0.97, 2.5) +
    Blockly.utils.svgPaths.curve('q',
      [
        Blockly.utils.svgPaths.point(-width * 0.05, 10),
        Blockly.utils.svgPaths.point(width * 0.3, 9.5)
      ]) +
    Blockly.utils.svgPaths.moveBy(width * 0.67, -1.9) +
    Blockly.utils.svgPaths.lineOnAxis('v', verticalOverlap);

  var highlightLtrUp =
    Blockly.utils.svgPaths.lineOnAxis('v', -1.5) +
    Blockly.utils.svgPaths.moveBy(width * -0.92, -0.5) +
    Blockly.utils.svgPaths.moveBy(width * 0.92, 1);

  var highlightLtrDown =
    Blockly.utils.svgPaths.moveBy(-5, height - 0.7) +
    Blockly.utils.svgPaths.lineTo(width * 0.46, -2.1);

  return {
    width: width,
    height: height,
    pathUp: function(rtl) {
      return rtl ? highlightRtlUp : highlightLtrUp;
    },
    pathDown: function(rtl) {
      return rtl ? highlightRtlDown : highlightLtrDown;
    }
  };
};

/**
 * @return {!Object} An object containing sizing and path information about
 *     notches.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makeNotch = function() {
  var width = this.NOTCH_WIDTH;
  var height = this.NOTCH_HEIGHT;
  var innerWidth = width;
  function makeMainPath(dir) {
    return Blockly.utils.svgPaths.line(
      [
        Blockly.utils.svgPaths.point(0, -height),
        Blockly.utils.svgPaths.point(dir * innerWidth, 0),
        Blockly.utils.svgPaths.point(0, height)
      ]);
  }
  var pathLeft = makeMainPath(1);
  var pathRight = makeMainPath(-1);

  return {
    type: this.SHAPES.NOTCH,
    width: width,
    height: height,
    pathLeft: pathLeft,
    pathRight: pathRight
  };
};

/**
 * @return {!Object} An object containing sizing and path information about
 *     inside corners.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makeInsideCorners = function() {
  var radius = this.CORNER_RADIUS/2;

  var innerTopLeftCorner = Blockly.utils.svgPaths.arc('a', '0 0,0', radius,
    Blockly.utils.svgPaths.point(-radius, radius));

  var innerBottomLeftCorner = Blockly.utils.svgPaths.arc('a', '0 0,0', radius,
    Blockly.utils.svgPaths.point(radius, radius));

  return {
    width: radius,
    height: radius,
    pathTop: innerTopLeftCorner,
    pathBottom: innerBottomLeftCorner
  };
};

/**
 * @return {!Object} An object containing sizing and path information about
 *     outside corners.
 * @package
 */
Blockly.blockRendering.ConstantProvider.prototype.makeOutsideCorners = function() {
  var radius = this.CORNER_RADIUS;
  /**
   * SVG path for drawing the rounded top-left corner.
   * @const
   */
  var topLeft =
    Blockly.utils.svgPaths.moveBy(0, radius) +
    Blockly.utils.svgPaths.arc('a', '0 0,1', radius,
      Blockly.utils.svgPaths.point(radius, -radius));

  /**
   * SVG path for drawing the rounded top-right corner.
   * @const
   */
  var topRight =
    Blockly.utils.svgPaths.arc('a', '0 0,1', radius,
      Blockly.utils.svgPaths.point(radius, radius));

  /**
   * SVG path for drawing the rounded bottom-left corner.
   * @const
   */
  var bottomLeft = Blockly.utils.svgPaths.arc('a', '0 0,1', radius,
    Blockly.utils.svgPaths.point(-radius, -radius));

  /**
   * SVG path for drawing the rounded bottom-right corner.
   * @const
   */
  var bottomRight = Blockly.utils.svgPaths.arc('a', '0 0,1', radius,
    Blockly.utils.svgPaths.point(-radius, radius));

  return {
    topLeft: topLeft,
    topRight: topRight,
    bottomRight: bottomRight,
    bottomLeft: bottomLeft,
    rightHeight: radius
  };
};

Blockly.fieldRegistry.register('field_tag', FieldTag);
// Blockly.registry.register(
//   Blockly.registry.Type.TOOLBOX_ITEM,
//   Blockly.ToolboxCategory.registrationName,
//   ToolboxCategory, true);
export { Blockly }
