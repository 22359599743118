"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnowflakeIdGenerate = void 0;
class SnowflakeIdGenerate {
    constructor(_workerId = 0, _dataCenterId = 0, _sequence = 0) {
        this.twepoch = 0;
        this.workerIdBits = 1;
        this.dataCenterIdBits = 1;
        this.maxWrokerId = -1 ^ (-1 << this.workerIdBits);
        this.maxDataCenterId = -1 ^ (-1 << this.dataCenterIdBits);
        this.sequenceBits = 10;
        this.workerIdShift = this.sequenceBits;
        this.dataCenterIdShift = this.sequenceBits + this.workerIdBits;
        this.sequenceMask = -1 ^ (-1 << this.sequenceBits);
        this.lastTimestamp = -1;
        this.workerId = 1;
        this.dataCenterId = 1;
        this.sequence = 0;
        this.timeGen = () => {
            return Date.now();
        };
        this.tilNextMillis = (lastTimestamp) => {
            let timestamp = this.timeGen();
            while (timestamp <= lastTimestamp) {
                timestamp = this.timeGen();
            }
            return timestamp;
        };
        this.nextId = () => {
            let timestamp = this.timeGen();
            if (timestamp < this.lastTimestamp) {
                throw new Error('Clock moved backwards. Refusing to generate id for ' + (this.lastTimestamp - timestamp));
            }
            if (this.lastTimestamp === timestamp) {
                this.sequence = (this.sequence + 1) & this.sequenceMask;
                if (this.sequence === 0) {
                    timestamp = this.tilNextMillis(this.lastTimestamp);
                }
            }
            else {
                this.sequence = 0;
            }
            this.lastTimestamp = timestamp;
            const timestampPos = (timestamp - this.twepoch) * 4096;
            const dataCenterPos = this.dataCenterId << this.dataCenterIdShift;
            const workerPos = this.workerId << this.workerIdShift;
            return timestampPos + dataCenterPos + workerPos + this.sequence;
        };
        this.generate = () => {
            return this.nextId();
        };
        if (this.workerId > this.maxWrokerId || this.workerId < 0) {
            throw new Error('config.worker_id must max than 0 and small than maxWrokerId-[' + this.maxWrokerId + ']');
        }
        if (this.dataCenterId > this.maxDataCenterId || this.dataCenterId < 0) {
            throw new Error('config.data_center_id must max than 0 and small than maxDataCenterId-[' + this.maxDataCenterId + ']');
        }
        this.workerId = _workerId;
        this.dataCenterId = _dataCenterId;
        this.sequence = _sequence;
    }
}
exports.SnowflakeIdGenerate = SnowflakeIdGenerate;
