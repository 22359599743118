"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLaborer = exports.PointState = void 0;
const pinia_1 = require("pinia");
class PointState {
    constructor(path, pointIndex) {
        this.path = path;
        this.pointIndex = pointIndex;
        this.editedState = null;
    }
    get curPoint() {
        return this.path.points[this.pointIndex];
    }
}
exports.PointState = PointState;
class State {
    constructor() {
        this.pointState = null;
        this.robotConnectState = "disconnect";
        this.mode = 'prod';
        this.isRobotMoving = false;
    }
}
exports.useLaborer = (0, pinia_1.defineStore)("laborer", {
    state: () => new State(),
    actions: {
        resetState() {
            this.pointState = null;
            this.robotConnectState = "disconnect";
        },
        selectPoint(path, pointIndex) {
            if (!path) {
                this.pointState = null;
            }
            if (path && !isNaN(Number(pointIndex))) {
                if (!this.pointState) {
                    this.pointState = new PointState(path, Number(pointIndex));
                }
                else {
                    this.pointState.path = path;
                    this.pointState.pointIndex = Number(pointIndex);
                }
            }
        },
        editPoint() {
            var _a;
            if (this.pointState) {
                this.pointState.editedState = {
                    path: this.pointState.path,
                    pointIndex: (_a = this.pointState) === null || _a === void 0 ? void 0 : _a.pointIndex,
                };
            }
        },
    }
});
