/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "rocker-wrap" };
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Rocker',
    props: {
        isLineMode: { type: Boolean, default: true }
    },
    emits: ['onMouseDown', 'onMouseMove', 'onMouseUp'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const rockerRef = (0, vue_3.ref)();
        let width = 0;
        let height = 0;
        let D = 260;
        let d = 160;
        let x = D / 2;
        let y = D / 2;
        let centerX = D / 2;
        let centerY = D / 2;
        let preX = 0;
        let preY = 0;
        let isControlTouch = (0, vue_3.ref)(false);
        let angle = 0;
        __expose({
            isControlTouch
        });
        (0, vue_3.onMounted)(() => {
            const ctx = rockerRef.value.getContext('2d');
            const rockerBg = new Image();
            rockerBg.src = '/assets/Rocker/rocker_bg.svg';
            rockerBg.onload = function () {
                drawOuter();
                redraw();
            };
            const rocker = new Image();
            rocker.src = '/assets/Rocker/rocker.svg';
            rocker.onload = function () {
                drawCenter();
                redraw();
            };
            let canvas = ctx.canvas;
            if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
                canvas.addEventListener("touchstart", onMouseDown);
                canvas.addEventListener("touchmove", onMouseMove);
                canvas.addEventListener("touchend", onMouseUp);
                window.addEventListener("touchend", onMouseUp);
            }
            else {
                canvas.addEventListener("mousedown", onMouseDown);
                canvas.addEventListener("mousemove", onMouseMove);
                canvas.addEventListener("mouseup", onMouseUp);
                canvas.addEventListener("mouseout", onMouseUp);
            }
            function onMouseDown(e) {
                if ((x > x - d / 2 && x < x + d / 2) && (y > y - d / 2 && y < y + d / 2)) {
                    preX = (e.offsetX || e.changedTouches[0].clientX);
                    preY = (e.offsetY || e.changedTouches[0].clientY);
                    isControlTouch.value = true;
                    emit('onMouseDown');
                }
            }
            function onMouseMove(e) {
                if (isControlTouch.value) {
                    let moveX = preX - (e.offsetX || e.changedTouches[0].clientX);
                    let moveY = preY - (e.offsetY || e.changedTouches[0].clientY);
                    x -= moveX;
                    y -= moveY;
                    limitToCircle({ x, y }, D / 2 - d / 2);
                    redraw();
                    preX = (e.offsetX || e.changedTouches[0].clientX);
                    preY = (e.offsetY || e.changedTouches[0].clientY);
                }
            }
            function onMouseUp(e) {
                if (!isControlTouch.value)
                    return;
                isControlTouch.value = false;
                x = centerX;
                y = centerY;
                redraw();
                emit('onMouseUp');
            }
            function limitToCircle(pos, limitRadius) {
                let dx = pos.x - centerX;
                let dy = pos.y - centerY;
                let _r = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
                angle = Math.atan2(dy, dx);
                let emitAngle = -Math.atan2(-dx, dy);
                emitAngle = emitAngle >= 0 ? emitAngle - Math.PI : emitAngle + Math.PI;
                emit('onMouseMove', emitAngle, Math.min(_r, limitRadius) / limitRadius);
                if (_r < limitRadius) {
                    x = pos.x;
                    y = pos.y;
                }
                else {
                    x = centerX + Math.cos(angle) * limitRadius;
                    y = centerY + Math.sin(angle) * limitRadius;
                }
            }
            const redraw = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                drawOuter();
                drawText();
                drawCenter();
            };
            function drawText() {
                ctx.save();
                ctx.font = "bold 22px arial";
                ctx.fillStyle = "#393B83";
                ctx.font = "bold 16px arial";
                ctx.fillText('前', 47 + 150 / 2, 45);
                ctx.fillText('后', 47 + 150 / 2, 260 - 35);
                ctx.fillText('左', 30, 260 / 2);
                ctx.fillText('右', 260 - 45, 260 / 2);
            }
            function drawOuter() {
                ctx.save();
                ctx.drawImage(rockerBg, 0, 0, D, D);
                ctx.restore();
            }
            function drawCenter() {
                ctx.save();
                ctx.translate(x - d / 2, y - d / 2);
                ctx.drawImage(rocker, 0, 0, d, d);
                ctx.restore();
            }
            (0, vue_3.watchEffect)(() => {
                redraw();
            });
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("canvas", {
                    width: "260",
                    height: "260",
                    ref_key: "rockerRef",
                    ref: rockerRef
                }, null, 512)
            ]));
        };
    }
});
