"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const utils_1 = require("@/utils");
Blockly.Blocks["vars_def"] = {
    init() {
        var _a, _b;
        let that = this;
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        let typeField = new Blockly.FieldDropdown([
            [
                "数字",
                model_1.VAL_TYPE.DOUBLE,
            ],
        ]);
        const numberInput = new Blockly.FieldNumber(0);
        numberInput.setMax(9999999999999998);
        numberInput.setMin(-9999999999999998);
        const input = this.appendDummyInput("VALUE")
            .appendField(new Blockly.FieldLabel("定义", "blocklyText blocklyBold"), "LABEL")
            .appendField(new Blockly.FieldTextInput("变量名", this.varChange), "VAR")
            .appendField(typeField, "TYPE")
            .appendField(new Blockly.FieldLabel("初始值:"), "LABEL_VALUE")
            .appendField(numberInput, "INIT_VALUE");
        this.setPreviousStatement(true);
        this.setNextStatement(true, "Number");
        this.setStyle("variable_blocks");
        typeField.setValidator(function (value) {
            input.removeField("LABEL_VALUE", true);
            if (value == model_1.VAL_TYPE.POSE) {
                if (!that.getField("LABEL_X")) {
                    input.removeField("INIT_VALUE", true);
                    input.appendField(new Blockly.FieldLabel("初始值:"), "LABEL_VALUE");
                    const pr = that
                        .appendDummyInput("PR_VALUE")
                        .appendField(new Blockly.FieldLabel("x:"), "LABEL_X")
                        .appendField(new Blockly.FieldNumber(0), "INIT_X")
                        .appendField(new Blockly.FieldLabel("y:"), "LABEL_Y")
                        .appendField(new Blockly.FieldNumber(0), "INIT_Y")
                        .appendField(new Blockly.FieldLabel("z:"), "LABEL_Z")
                        .appendField(new Blockly.FieldNumber(0), "INIT_Z")
                        .appendField(new Blockly.FieldLabel("rx:"), "LABEL_RX")
                        .appendField(new Blockly.FieldNumber(0), "INIT_RX")
                        .appendField(new Blockly.FieldLabel("ry:"), "LABEL_RY")
                        .appendField(new Blockly.FieldNumber(0), "INIT_RY")
                        .appendField(new Blockly.FieldLabel("rz:"), "LABEL_RZ")
                        .appendField(new Blockly.FieldNumber(0), "INIT_RZ");
                }
            }
            else if (value == model_1.VAL_TYPE.JOINT) {
                that.removeInput("PR_VALUE", true);
                input.removeField("INIT_VALUE", true);
            }
            else if (value == model_1.VAL_TYPE.DOUBLE) {
                input.insertFieldAt(3, new Blockly.FieldLabel("初始值:"), "LABEL_VALUE");
                if (!that.getField("INIT_VALUE")) {
                    that.removeInput("PR_VALUE", true);
                    input.appendField(new Blockly.FieldNumber(0), "INIT_VALUE");
                }
            }
            that.updateInitValue(value);
            that.updateVarsSet(value);
            that.updateVarsGet(value);
            return value;
        });
    },
    varChange(varValue) {
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return;
        let project = model_1.Project.current();
        let varName = varValue.trim();
        varName = varName
            .replace(/[^0-9a-zA-Z_\u4e00-\u9fa5]/g, "")
            .replace(/^\d+/, "");
        let Vars;
        let index = 0;
        let block = this.getSourceBlock();
        let type = block.getField("TAG") ? "tag" : "var";
        let oldValue = block.getFieldValue(type.toUpperCase());
        if (varName == "") {
            let name = block.getField("TAG") ? "书签" : "变量";
            let index = project.getNewId(type, "");
            varName = varValue = `${name}${index}`;
        }
        while ((Vars = project.curProgram.getVars(utils_1.Utils.pinyin(varName))) &&
            Vars.filter((Var) => Var.id !== this.getSourceBlock().id).length) {
            varName = `${varValue}_${++index}`;
        }
        if (type == "tag") {
            let tag_jumps = ws.getBlocksByType("tag_jump", true);
            tag_jumps.forEach((block) => {
                let tag = block.getFieldValue("TAG");
                if (tag == oldValue) {
                    block.setFieldValue(utils_1.Utils.pinyin(varName), "TAG");
                }
            });
        }
        return varName;
    },
    updateInitValue: function (type) {
        const initValue = this.getFieldValue("INIT_VALUE");
        if (type == model_1.VAL_TYPE.DOUBLE || type == model_1.VAL_TYPE.INT) {
            if (!/^[+-]?\d+(\.\d+)?$/.test(initValue))
                this.setFieldValue(0, "INIT_VALUE");
        }
        else if (type == model_1.VAL_TYPE.BOOLEAN) {
            if (initValue != true)
                this.setFieldValue(false, "INIT_VALUE");
        }
        else {
            if (initValue == 0)
                this.setFieldValue("", "INIT_VALUE");
        }
    },
    updateVarsSet: function (type) {
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return;
        let vars_sets = ws.getBlocksByType("vars_set", true);
        const varDef = utils_1.Utils.pinyin(this.getFieldValue("VAR"));
        vars_sets.forEach((block) => {
            const varSet = block.getFieldValue("VAR");
            if (utils_1.Utils.pinyin(varDef) == varSet) {
                const varInput = block.getInput("VAR");
                const valueinput = block.getInput("VALUE");
                console.log(`setCheck【${model_1.VAL_TYPE_CHECK[type]}】`);
                valueinput === null || valueinput === void 0 ? void 0 : valueinput.setCheck(model_1.VAL_TYPE_CHECK[type]);
                block.setFieldValue("", "VAR");
            }
        });
    },
    updateVarsGet: function (type) {
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return;
        let vars_gets = ws.getBlocksByType("vars_get", true);
        const varDef = utils_1.Utils.pinyin(this.getFieldValue("VAR"));
        vars_gets.forEach((block) => {
            const varSet = block.getFieldValue("VAR");
            if (utils_1.Utils.pinyin(varDef) == varSet) {
                console.log(`setOutput【${model_1.VAL_TYPE_CHECK[type]}】`);
                block.setOutput(true, model_1.VAL_TYPE_CHECK[type]);
            }
        });
    },
    onCheckChanged(value) {
        var _a, _b;
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        if (isSub) {
            let block = this.getSourceBlock();
            let varName = block.getFieldValue("VAR");
            let isParams = block.getFieldValue("IS_PARAM");
            let project = model_1.Project.current();
            let params = project.curProgram.program.params;
            let Var = model_1.RobotProgram.prototype.getLocalVar.call(project.curProgram.program, block.id);
            if (Var) {
                if (isParams) {
                    params.push(Var);
                }
                else {
                    params.splice(params.indexOf(Var), 1);
                }
            }
        }
    },
};
Blockly.Blocks["vars_set"] = {
    init: function () {
        const input = this.appendValueInput("VALUE")
            .setCheck(["Number", "String"])
            .appendField(new Blockly.FieldLabel("赋值", "blocklyText blocklyBold"), "LABEL")
            .appendField(new Blockly.FieldDropdown(this.varOptions, this.varChange), "VAR")
            .appendField("=", "LABEL_=");
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true, "Number");
        this.setStyle("variable_blocks");
    },
    varOptions: function () {
        var _a, _b;
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        let program = project.curProgram.program;
        console.assert(robot);
        console.assert(program);
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        let globalVars = robot.code.globalVars
            .filter((Var) => Var.type != model_1.VAL_TYPE.LABEL)
            .map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [
                `${isSub ? "[主程序]" : ""} ${Var.name}`,
                utils_1.Utils.pinyin(Var.name),
            ];
        });
        let localVars = program.localVars
            .filter((Var) => Var.type != model_1.VAL_TYPE.LABEL && !Var.isTemp)
            .map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [`${Var.name}`, utils_1.Utils.pinyin(Var.name)];
        });
        if (!globalVars.length && !localVars.length)
            return [["选择变量", "NONE"]];
        return [...globalVars, ...localVars];
    },
    varChange(varValue) {
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return [["选择变量", "NONE"]];
        const input = this.getSourceBlock().getInput("VALUE");
        let project = model_1.Project.current();
        let Var = project.curProgram.getVar(varValue);
        if (Var && input) {
            let type = Var.type.toString();
            console.log(`setCheck【${model_1.VAL_TYPE_CHECK[type]}】`);
            input.setCheck(model_1.VAL_TYPE_CHECK[type]);
        }
        return varValue;
    },
};
Blockly.Blocks["vars_get"] = {
    init: function () {
        const that = this;
        const varField = new Blockly.FieldDropdown(this.varOptions);
        const input = this.appendDummyInput()
            .appendField(varField, "VAR");
        this.setPreviousStatement(false);
        this.setNextStatement(false);
        this.setStyle("math_blocks");
        this.setOutput("Type");
        varField.setValidator(function (varValue) {
            let project = model_1.Project.current();
            if (!project)
                return varValue;
            let Var = project.curProgram.getVar(varValue);
            if (Var) {
                let type = Var.type;
                console.log(type);
                console.log(`setOutput【${model_1.VAL_TYPE_CHECK[type]}】`);
                that.setOutput(true, model_1.VAL_TYPE_CHECK[type]);
            }
            return varValue;
        });
    },
    varOptions: Blockly.Blocks["vars_set"]["varOptions"],
};
