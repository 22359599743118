/* unplugin-vue-components disabled */const { computed } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-6df44784"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { style: { "display": "flex", "align-items": "center", "gap": "3px" } };
const _hoisted_2 = { style: { "display": "flex", "width": "100%", "flex-direction": "column" } };
const _hoisted_3 = { style: { "display": "flex", "align-items": "center" } };
const _hoisted_4 = { style: { "display": "flex", "align-items": "center", "width": "34px", "flex-basis": "content" } };
const _hoisted_5 = { class: "axis-box" };
const _hoisted_6 = { style: { "display": "flex", "flex-grow": "1" } };
exports.default = (0, vue_1.defineComponent)({
    __name: 'SliderItem',
    props: {
        item: {},
        onSliderChange: {},
        disable: { type: Boolean, default: false },
        minLimit: { default: 0 },
        maxLimit: { default: 360 },
        name: {},
        isShowLocked: { type: Boolean },
        showLimit: { type: Boolean, default: true }
    },
    emits: ['update:item'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const val = computed({
            get() {
                if (typeof props.item === 'object')
                    return props.item.val;
                else
                    return props.item;
            },
            set(value) {
                var _a;
                if (value == null) {
                    if (typeof props.item === 'object') {
                        emit('update:item', ((_a = props.item) === null || _a === void 0 ? void 0 : _a.val) || 0);
                    }
                    else {
                        emit('update:item', props.item || 0);
                    }
                }
                else {
                    emit('update:item', value);
                }
            }
        });
        return (_ctx, _cache) => {
            const _component_el_checkbox = (0, vue_2.resolveComponent)("el-checkbox");
            const _component_el_slider = (0, vue_2.resolveComponent)("el-slider");
            const _component_el_input_number = (0, vue_2.resolveComponent)("el-input-number");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (_ctx.showLimit)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            key: 0,
                            class: (0, vue_2.normalizeClass)(_ctx.isShowLocked ? 'is-show-lock-limit' : 'un-show-lock-limit')
                        }, [
                            (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.minLimit) + "°", 1),
                            (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.maxLimit) + "°", 1)
                        ], 2))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("div", _hoisted_4, [
                            (_ctx.isShowLocked)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_checkbox, {
                                    key: 0,
                                    class: "lock-checkbox",
                                    modelValue: _ctx.item.isLocked,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.item.isLocked) = $event)),
                                    disabled: _ctx.disable,
                                    label: "",
                                    size: "small"
                                }, null, 8, ["modelValue", "disabled"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.name), 1)
                        ]),
                        (0, vue_2.createElementVNode)("div", _hoisted_6, [
                            (0, vue_2.createVNode)(_component_el_slider, {
                                class: "slider-wrapper",
                                modelValue: (0, vue_2.unref)(val),
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((0, vue_2.isRef)(val) ? (val).value = $event : null)),
                                min: _ctx.minLimit,
                                max: _ctx.maxLimit,
                                step: "0.0001",
                                disabled: _ctx.disable
                            }, null, 8, ["modelValue", "min", "max", "disabled"]),
                            (0, vue_2.createVNode)(_component_el_input_number, {
                                modelValue: (0, vue_2.unref)(val),
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((0, vue_2.isRef)(val) ? (val).value = $event : null)),
                                min: _ctx.minLimit,
                                max: _ctx.maxLimit,
                                step: "0.0001",
                                controls: false,
                                disabled: _ctx.disable,
                                style: { "width": "60px", "flex-shrink": "0" },
                                "value-on-clear": _ctx.item.val
                            }, null, 8, ["modelValue", "min", "max", "disabled", "value-on-clear"])
                        ])
                    ])
                ])
            ]));
        };
    }
});
