"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RobotService = void 0;
class RobotService {
    static register(name, codec) {
        RobotService[name] = codec;
    }
    init(cfg) {
        this.config = cfg;
    }
}
exports.RobotService = RobotService;
