/* unplugin-vue-components disabled */const { nextTick } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const vue_3 = require("vue");
const project_1 = require("@/service/project");
const index_1 = require("@/model/index");
const one_message_1 = require("@/utils/one_message");
const work_1 = require("@/store/modules/work");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    emits: ["openProject"],
    setup(__props, { expose: __expose, emit: __emit }) {
        const newProjectDialog = (0, vue_3.ref)(false);
        const projectForm = (0, vue_3.ref)({
            name: ''
        });
        const emit = __emit;
        const projectFormRef = (0, vue_3.ref)();
        const rules = (0, vue_3.ref)({
            name: [
                { required: true, message: '请输入项目名称', trigger: 'blur' },
                {
                    validator: (rule, value, callback) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (value.length > 30) {
                            callback(new Error('项目名称不能超过30个字符，请重新输入！'));
                            return;
                        }
                        let patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
                        if (patrn.test(value)) {
                            callback(new Error('项目名称不能含有特殊字符，请重新输入！'));
                            return;
                        }
                        if (value.indexOf(" ") !== -1) {
                            callback(new Error('项目名称不能含有空格，请重新输入！'));
                            return;
                        }
                        let projectList = (yield project_1.ProjectService.current().getAllProjectList()).project_infos;
                        if (projectList.find((project) => project.p_name === value)) {
                            callback(new Error('已存在同名项目！'));
                            return;
                        }
                        callback();
                    }), trigger: 'blur'
                }
            ]
        });
        const dialogName = (0, vue_3.ref)('');
        const workStore = (0, work_1.useWork)();
        const isAdding = (0, vue_3.ref)(false);
        let originProjectId;
        function handleNewProject(id) {
            return tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (id != null) {
                    originProjectId = id;
                    dialogName.value = workStore.curPage === 'laborer' ? '复制工位' : '复制项目';
                }
                else {
                    dialogName.value = '新建项目';
                }
                newProjectDialog.value = true;
            });
        }
        const cancel = () => {
            newProjectDialog.value = false;
            originProjectId = null;
            if (!newProjectDialog.value && !index_1.Project.current()) {
                emit("openProject");
            }
        };
        const newProject = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a;
            try {
                if (!projectFormRef.value || isAdding.value)
                    return;
                isAdding.value = true;
                if (project_1.ProjectService.current().getAutoSaveState() != project_1.AUTO_SAVE_STATE.IDLE) {
                    (0, one_message_1.oneMessage)({ message: "当前还有修改未保存，请保存后重试！", type: "warning" });
                    return;
                }
                project_1.ProjectService.current().closeAutoSave();
                let valid = yield ((_a = projectFormRef.value) === null || _a === void 0 ? void 0 : _a.validate());
                if (!valid)
                    return;
                let newProject = originProjectId ? yield project_1.ProjectService.getProjectById(originProjectId) : new index_1.Project();
                newProject.name = projectForm.value.name;
                try {
                    newProject = yield project_1.ProjectService.current().addProject(newProject);
                }
                catch (e) {
                    console.log(e);
                    one_message_1.oneMessage.error(e.message);
                    return;
                }
                newProjectDialog.value = false;
                projectFormRef.value.resetFields();
                yield project_1.ProjectService.current().loadProjectData(Number(newProject.id));
                originProjectId = null;
            }
            catch (e) {
                console.log(e);
            }
            finally {
                project_1.ProjectService.current().startAutoSave();
                nextTick(() => isAdding.value = false);
            }
        });
        __expose({
            handleNewProject
        });
        return (_ctx, _cache) => {
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            const _component_el_form_item = (0, vue_2.resolveComponent)("el-form-item");
            const _component_el_form = (0, vue_2.resolveComponent)("el-form");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                modelValue: newProjectDialog.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((newProjectDialog).value = $event)),
                "align-center": "",
                width: "400px",
                title: dialogName.value,
                onClose: _cache[4] || (_cache[4] = ($event) => (cancel()))
            }, {
                footer: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createVNode)(_component_el_button, {
                        onClick: _cache[2] || (_cache[2] = ($event) => (cancel()))
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createTextVNode)("取消")
                        ]),
                        _: 1
                    }),
                    (0, vue_2.createVNode)(_component_el_button, {
                        type: "primary",
                        onClick: newProject,
                        loading: isAdding.value
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createTextVNode)("确定")
                        ]),
                        _: 1
                    }, 8, ["loading"])
                ]),
                default: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createVNode)(_component_el_form, {
                        model: projectForm.value,
                        ref_key: "projectFormRef",
                        ref: projectFormRef,
                        rules: rules.value,
                        onSubmit: _cache[1] || (_cache[1] = (0, vue_2.withModifiers)(() => { }, ["prevent"]))
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createVNode)(_component_el_form_item, {
                                prop: "name",
                                label: (0, vue_2.unref)(workStore).curPage === 'laborer' ? '工位名称' : '工程名称'
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        modelValue: projectForm.value.name,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((projectForm.value.name) = $event))
                                    }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                            }, 8, ["label"])
                        ]),
                        _: 1
                    }, 8, ["model", "rules"])
                ]),
                _: 1
            }, 8, ["modelValue", "title"]));
        };
    }
});
