import * as Blockly from 'blockly';

export class FieldTag extends Blockly.FieldDropdown {

  constructor(value, validator, config) {
    super(value, validator, config);
  }

  /**
   * @override
   */
  initView() {
    super.initView();
    if (this.shouldAddBorderRect_()) {
      this.createBorderRect_();
    } else {
      this.clickTarget_ = this.sourceBlock_.getSvgRoot();
    }
    this.createTextElement_();

    this.imageElement_ = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.IMAGE, {}, this.fieldGroup_);

    if (this.borderRect_) {
      Blockly.utils.dom.addClass(this.borderRect_, 'blocklyDropdownRect');
      Blockly.utils.dom.addClass(this.textElement_, 'blocklyTag');
    }
  }


  /**
   * @override
   */
  showEditor(opt_e) {
  };

  static fromJson(options) {
    return new FieldTag( [[options.text, ""]], undefined, options);
  }
}
