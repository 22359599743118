"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
Blockly.defineBlocksWithJsonArray([{
        "type": "socket_open",
        "message0": "%1 %2 %3 %4 %5 %6",
        "args0": [{
                "type": "field_label", "text": "通讯", "class": "blocklyBold"
            }, {
                "type": "field_tag", "text": "创建", "class": "labelBlock"
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "通讯口名",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "服务器IP",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "端口",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "状态检测变量",
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "channel_blocks",
        "extensions": []
    }, {
        "type": "socket_close",
        "message0": "%1 %2 %3 %4",
        "args0": [{
                "type": "field_label", "text": "通讯", "class": "blocklyBold"
            }, {
                "type": "field_tag", "text": "关闭", "class": "labelBlock"
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "通讯口名",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "状态检测变量",
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "channel_blocks",
        "extensions": []
    }, {
        "type": "socket_send",
        "message0": "%1 %2 %3 %4 %5",
        "args0": [{
                "type": "field_label", "text": "通讯", "class": "blocklyBold"
            }, {
                "type": "field_tag", "text": "发送", "class": "labelBlock"
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "通讯口名",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "发送数据值",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "状态检测变量",
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "channel_blocks",
        "extensions": []
    }, {
        "type": "socket_read",
        "message0": "%1 %2 %3 %4",
        "args0": [{
                "type": "field_label", "text": "通讯", "class": "blocklyBold"
            }, {
                "type": "field_tag", "text": "读取", "class": "labelBlock"
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "通讯口名",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "读取数量",
            }],
        "message1": "%1 %2 %3 %4",
        "args1": [{
                "type": "field_label", "text": "      ", "class": "blocklyBold"
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "保存命名",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "读取时长",
            }, {
                "type": "field_input", "name": "TAG", "check": "TEXT", "text": "状态检测变量",
            }],
        "previousStatement": null,
        "nextStatement": null,
        "style": "channel_blocks",
        "extensions": []
    }]);
