/* unplugin-vue-components disabled */const { computed, ref } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const model_1 = require("@/model");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ProgramForm',
    props: {
        form: {
            type: Object,
            required: true
        },
        curRobot: {
            type: Object,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const curProgramName = computed(() => { var _a, _b, _c; return (_c = (_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram) === null || _b === void 0 ? void 0 : _b.program) === null || _c === void 0 ? void 0 : _c.name; });
        const programFormRef = ref();
        const validateProgramName = (rule, value, callback) => {
            let patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
            if (patrn.test(value)) {
                callback(new Error('该程序名称含有特殊字符'));
            }
            if (value.indexOf(' ') != -1) {
                callback(new Error('该程序名称含有空格'));
            }
            if (props.isEdit) {
                if (props.curRobot.code.programs.filter(e => e.name !== curProgramName.value).find(e => e.name === value)) {
                    callback(new Error('该程序名称已存在'));
                }
            }
            else {
                if (props.curRobot.code.programs.find(e => e.name === value)) {
                    callback(new Error('该程序名称已存在'));
                }
            }
            callback();
        };
        const programRules = ref({
            name: [{ required: true, message: '请输入程序名称', trigger: 'blur' }, { validator: validateProgramName, trigger: 'change' }]
        });
        __expose({
            programFormRef
        });
        return (_ctx, _cache) => {
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            const _component_el_form_item = (0, vue_2.resolveComponent)("el-form-item");
            const _component_el_form = (0, vue_2.resolveComponent)("el-form");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_form, {
                ref_key: "programFormRef",
                ref: programFormRef,
                model: __props.form,
                rules: (0, vue_2.unref)(programRules),
                "label-width": "90px",
                onSubmit: _cache[1] || (_cache[1] = (0, vue_2.withModifiers)(() => { }, ["prevent"]))
            }, {
                default: (0, vue_2.withCtx)(() => [
                    (0, vue_2.createVNode)(_component_el_form_item, {
                        label: "程序名称",
                        prop: "name"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createVNode)(_component_el_input, {
                                modelValue: __props.form.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((__props.form.name) = $event)),
                                placeholder: "请输入程序名称",
                                maxlength: 15
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["model", "rules"]));
        };
    }
});
