/* unplugin-vue-components disabled */const { ref, onMounted } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const work_1 = require("@/store/modules/work");
require("@babylonjs/loaders");
const stage_1 = require("./render/stage");
const model_1 = require("@/model");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    setup(__props, { expose: __expose }) {
        const renderCanvas = ref();
        const store = (0, work_1.useWork)();
        let stage;
        function cleanUp() {
            if (stage)
                stage.removeAll();
        }
        function getTcpPoint(robot_id) {
            if (stage)
                stage.getTcpPoint(robot_id);
        }
        __expose({
            cleanUp,
            getTcpPoint
        });
        onMounted(() => {
            stage = new stage_1.Stage(renderCanvas.value);
            stage.createScene();
            stage.start();
            stage.onEvent((event, { id, point }) => {
                var _a;
                let device = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.findMachineById(id);
                if (device) {
                    switch (event) {
                        case 'move':
                            device.pr.x = point.x;
                            device.pr.y = point.y;
                            device.pr.z = point.z;
                            break;
                        case 'move_x':
                            device.pr.x = point.x;
                            break;
                        case 'move_y':
                            device.pr.y = point.y;
                            break;
                        case 'move_z':
                            device.pr.z = point.z;
                            break;
                        case 'rotation':
                            device.pr.rx = point.x;
                            device.pr.ry = point.y;
                            device.pr.rz = point.z;
                            break;
                        case 'select':
                            store.selectNode(device);
                            break;
                    }
                }
                else {
                    console.log('device not found: ' + id);
                }
            });
            function limitPosition(entity) {
                entity.pr.x = Math.max(-5000, Math.min(5000, entity.pr.x));
                entity.pr.y = Math.max(-5000, Math.min(5000, entity.pr.y));
                entity.pr.z = Math.max(0, Math.min(5000, entity.pr.z));
            }
            const init3D = () => {
                model_1.Project.current().machines.forEach((device) => {
                    store.notifyAddMachine(device);
                    if (device.type === model_1.ENTITY_TYPE.ROBOT) {
                        let robot = device;
                        if (robot.tool) {
                            store.notifyAddMachine(robot.tool, robot.id);
                        }
                    }
                });
            };
            store.$subscribe((mutation, state) => {
                if (mutation.storeId === 'work') {
                    let entity = store.curDevice;
                    if (!entity)
                        return;
                    switch (entity.type) {
                        case model_1.ENTITY_TYPE.ROBOT:
                            limitPosition(entity);
                            stage.updateRobot(entity);
                            stage.updatePath(entity);
                            break;
                        case model_1.ENTITY_TYPE.UCS:
                            limitPosition(entity);
                            stage.updateEntity(entity);
                            break;
                        case model_1.ENTITY_TYPE.TOOL:
                            break;
                    }
                    if (entity.type == model_1.ENTITY_TYPE.ROBOT) {
                    }
                    else if (entity.type == model_1.ENTITY_TYPE.UCS) {
                    }
                    else if (entity.type == model_1.ENTITY_TYPE.TOOL) {
                    }
                }
            });
            store.$onAction(({ name, store, args, after, onError }) => {
                if (name === 'notifyAddMachine') {
                    after((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        let [machine, parentId] = args;
                        switch (machine.type) {
                            case model_1.ENTITY_TYPE.ROBOT:
                            case model_1.ENTITY_TYPE.UCS:
                            case model_1.ENTITY_TYPE.TOOL:
                                yield stage.loadMesh(machine, parentId);
                                break;
                        }
                    }));
                }
                else if (name === 'notifySelectMachine') {
                    after((resolve) => {
                        let [id] = args;
                        let node = store.curNode;
                        if (node) {
                            stage.selectEntity(node);
                        }
                    });
                }
                else if (name === 'notifyRemoveMachine') {
                    after((resolve) => {
                        let [id] = args;
                        stage.removeMachine(id);
                    });
                }
                else if (name === 'notifyHideMachine') {
                    after((resolve) => {
                        let [id] = args;
                        stage.visibleMachine(id, false);
                    });
                }
                else if (name === 'notifyShowMachine') {
                    after((resolve) => {
                        let [id] = args;
                        stage.visibleMachine(id, true);
                    });
                }
                else if (name === 'notifyRemovePath') {
                    after((resolve) => {
                        let [id] = args;
                        stage.removePath(id);
                    });
                }
                else if (name === 'resetProject') {
                    stage.removeAll();
                }
                else if (name === 'notifyChannel') {
                    let [method, params] = args;
                    let result = stage[method](...params);
                    store.notifyChannelCallback(method, result);
                }
                else if (name === 'notifyChangeProject') {
                    stage.removeAll();
                    init3D();
                }
            });
            if (model_1.Project.current()) {
                init3D();
            }
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("canvas", {
                ref_key: "renderCanvas",
                ref: renderCanvas
            }, null, 512));
        };
    }
});
