/* unplugin-vue-components disabled */const { nextTick } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-053947a4"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { style: { "display": "flex" } };
const _hoisted_2 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { style: { "display": "flex", "flex-direction": "column" } }, null, -1));
const vue_3 = require("vue");
const element_plus_1 = require("element-plus");
const blockly_1 = require("./patch/blockly");
const ast_1 = require("@/utils/ast");
const opts = tslib_1.__importStar(require("./config/options.json"));
const robot_script_1 = require("./generators/robot_script");
require("./blocks");
const model_1 = require("@/model");
const work_1 = require("@/store/modules/work");
const one_message_1 = require("@/utils/one_message");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: ["options"],
    setup(__props, { expose: __expose }) {
        let Zh = require('blockly/msg/zh-hans');
        const props = __props;
        const blocklyDiv = (0, vue_3.ref)();
        const workspace = (0, vue_3.shallowRef)();
        const miniWorkspace = (0, vue_3.shallowRef)();
        let isShowToolbox = true;
        const toggleToolbox = () => {
            if (!workspace.value)
                return;
            let wp = workspace.value;
            let toolbox = wp.getToolbox();
            if (toolbox) {
                if (isShowToolbox) {
                    isShowToolbox = false;
                    toolbox.clearSelection();
                    let categories = toolbox.getToolboxItems();
                    for (let i = 0; i < categories.length; i++) {
                        let category = categories[i];
                        category.setVisible_(false);
                    }
                }
                else {
                    isShowToolbox = true;
                    toolbox.clearSelection();
                    let categories = toolbox.getToolboxItems();
                    for (let i = 0; i < categories.length; i++) {
                        let category = categories[i];
                        category.setVisible_(true);
                    }
                }
            }
        };
        function createVar(id, name, type) {
            if (workspace.value.getVariableById(id)) {
                workspace.value.renameVariableById(id, name);
            }
            else {
                workspace.value.createVariable(name, type, id);
            }
        }
        function deleteVar(id) {
            workspace.value.deleteVariableById(id);
        }
        __expose({
            workspace,
            miniWorkspace,
            createVar,
            deleteVar
        });
        let oldProjectId;
        (0, vue_3.onMounted)(() => {
            const options = props.options || {};
            options.theme = opts.theme;
            if (!options.toolbox) {
                options.toolbox = opts.toolbox;
            }
            blockly_1.Blockly.setLocale(Object.assign(Zh, opts.msg["zh-hans"]));
            blockly_1.Blockly.Msg.LOGIC_BOOLEAN_TRUE = '真';
            blockly_1.Blockly.Msg.LOGIC_BOOLEAN_FALSE = '假';
            workspace.value = blockly_1.Blockly.inject(blocklyDiv.value, Object.assign(options, {
                renderer: 'thrasos',
                media: '/static/media/',
                sounds: false,
            }));
            const runCode = () => {
                var _a, _b;
                let project = model_1.Project.current();
                ast_1.tmpPool.clear();
                if ((_a = project === null || project === void 0 ? void 0 : project.curProgram.program) === null || _a === void 0 ? void 0 : _a.localVars) {
                    (_b = project === null || project === void 0 ? void 0 : project.curProgram.program) === null || _b === void 0 ? void 0 : _b.localVars.forEach(item => {
                        var _a;
                        if (item.name.indexOf("临时变量") != -1 || item.name.indexOf("$temp") != -1) {
                            (_a = project === null || project === void 0 ? void 0 : project.curProgram.program) === null || _a === void 0 ? void 0 : _a.delLocalVar(item.id);
                        }
                    });
                }
                try {
                    const code = robot_script_1.robotScript.workspaceToCode(workspace.value);
                    if (project) {
                        let array = code.split('\n').filter(c => c.startsWith('[')).map(c => c.replace(/,$/, '')).join(',\n');
                        let actions = JSON.parse(`[${array}]`).filter(a => a.length);
                        project.curProgram.program.actions = actions;
                    }
                }
                catch (e) {
                    (0, element_plus_1.ElNotification)({
                        title: '程序错误',
                        message: `请检查程序指令块！（${e.message}）`,
                        type: 'error',
                    });
                    console.error(e);
                }
            };
            function getBlockVars(block) {
                let Vars = [];
                let blocks = workspace.value.getBlocksByType('vars_def', false);
                blocks.forEach(block => {
                    let name = block.getFieldValue("VAR");
                    let type = block.getFieldValue("TYPE");
                    if (type == model_1.VAL_TYPE.POSE) {
                        let x = block.getFieldValue("INIT_X");
                        let y = block.getFieldValue("INIT_Y");
                        let z = block.getFieldValue("INIT_Z");
                        let rx = block.getFieldValue("INIT_RX");
                        let ry = block.getFieldValue("INIT_RY");
                        let rz = block.getFieldValue("INIT_RZ");
                        Vars.push({ id: block.id, name, type: type, initVal: [x, y, z, rx, ry, rz], isStatic: false });
                    }
                    else if (type == model_1.VAL_TYPE.JOINT) {
                        let initVal = model_1.Project.current().curProgram.robot.model.z_axes.map(a => 0);
                        initVal.length = Math.max(0, initVal.length - 1);
                        Vars.push({ id: block.id, name, type: type, initVal, isStatic: false });
                    }
                    else {
                        let initVal = block.getFieldValue("INIT_VALUE");
                        Vars.push({ id: block.id, name, type: type, initVal, isStatic: false });
                    }
                });
                blocks = workspace.value.getBlocksByType('timer_def', false);
                blocks.forEach(block => {
                    let name = block.getFieldValue("VAR");
                    let type = model_1.VAL_TYPE.DOUBLE;
                    let initVal = 0;
                    Vars.push({ id: block.id, name, type: type, initVal, isStatic: false });
                });
                blocks = workspace.value.getBlocksByType('tag_mark', false);
                blocks.forEach(block => {
                    let name = block.getFieldValue("TAG");
                    let type = model_1.VAL_TYPE.LABEL;
                    let initVal = 0;
                    Vars.push({ id: block.id, name, type: type, initVal, isStatic: false });
                });
                return Vars;
            }
            function onEndDrag(block, done) {
                let onDrag = (e) => {
                    if (e.type === blockly_1.Blockly.Events.MOVE && e.blockId === block.id) {
                        workspace.value.removeChangeListener(onDrag);
                        done();
                    }
                };
                workspace.value.addChangeListener(onDrag);
            }
            workspace.value.addChangeListener((evt) => {
                var _a, _b, _c, _d, _e;
                let e = evt;
                if (!e.blockId)
                    return;
                let block = workspace.value.getBlockById(e.blockId);
                if (e.type == blockly_1.Blockly.Events.CLICK) {
                    let blockType = (_a = workspace.value.getBlockById(e.blockId)) === null || _a === void 0 ? void 0 : _a.type;
                }
                else if (e.type == blockly_1.Blockly.Events.BLOCK_DELETE) {
                    let project = model_1.Project.current();
                    let robot = project.curProgram.robot;
                    let pg = project.curProgram.program;
                    let d = e;
                    let block = d.oldJson;
                    const delVar = (block) => {
                        if ((block.type == 'vars_def' || block.type == 'timer_def' || block.type == 'tag_mark') && block.id) {
                            console.log(block);
                            if (pg.isMain) {
                                robot.code.delGlobalVar(block.id);
                            }
                            else {
                                model_1.RobotProgram.prototype.delLocalVar.call(pg, block.id);
                            }
                        }
                        if (block.next)
                            delVar(block.next.block);
                    };
                    delVar(block);
                }
                else if (e.type == blockly_1.Blockly.Events.BLOCK_CREATE || e.type == blockly_1.Blockly.Events.BLOCK_CHANGE) {
                    let project = model_1.Project.current();
                    let robot = project.curProgram.robot;
                    let pg = project.curProgram.program;
                    let block = workspace.value.getBlockById(e.blockId);
                    if (block.isCollapsed()) {
                        block.setCollapsed(false);
                        nextTick(() => block.setCollapsed(true));
                    }
                    if (e.type == blockly_1.Blockly.Events.BLOCK_CREATE) {
                        if (block.type == 'vars_def') {
                            if (block.getFieldValue('VAR') == "变量名") {
                                onEndDrag(block, () => {
                                    let index = project.getNewId('var', '');
                                    block.setFieldValue(`变量${index}`, 'VAR');
                                });
                            }
                        }
                        else if (block.type == 'timer_def') {
                            if (block.getFieldValue('VAR') == "秒表名称") {
                                onEndDrag(block, () => {
                                    let index = project.getNewId('timer', '');
                                    block.setFieldValue(`秒表${index}`, 'VAR');
                                });
                            }
                        }
                        else if (block.type == 'tag_mark') {
                            if (block.getFieldValue('TAG') == "书签名称") {
                                onEndDrag(block, () => {
                                    let index = project.getNewId('tag', '');
                                    block.setFieldValue(`书签${index}`, 'TAG');
                                });
                            }
                        }
                        let blocks = workspace.value.getBlocksByType('move_material', false);
                        if ((blocks === null || blocks === void 0 ? void 0 : blocks.length) > 1) {
                            nextTick(() => {
                                workspace.value.removeBlockById(block.id);
                                block.dispose(true);
                                (0, one_message_1.oneMessage)({
                                    message: '只能引入一个自动取料指令',
                                    type: 'warning'
                                });
                            });
                        }
                    }
                    let Vars = getBlockVars(block);
                    if (Vars.length) {
                        if (pg.isMain) {
                            robot.code.globalVars.length = 0;
                            Vars.forEach(Var => {
                                robot.code.addGlobalVar(Var);
                            });
                        }
                        else {
                            pg.localVars.length = 0;
                            Vars.forEach(Var => {
                                model_1.RobotProgram.prototype.addLocalVar.call(pg, Var);
                            });
                        }
                    }
                }
                if (e.isUiEvent || e.type === blockly_1.Blockly.Events.FINISHED_LOADING || workspace.value['isDragging']()) {
                    if (e.type === 'drag' || e.type === 'click') {
                        if (e.type === 'drag' && e.isStart)
                            return;
                        let rootBlock = (_b = workspace.value) === null || _b === void 0 ? void 0 : _b.getBlockById(e.blockId);
                        if (!rootBlock)
                            return;
                        let allBlocks = (_c = workspace.value) === null || _c === void 0 ? void 0 : _c.getAllBlocks();
                        let startBlocks = allBlocks === null || allBlocks === void 0 ? void 0 : allBlocks.filter(block => !block["parentBlock_"] && block.id !== e.blockId);
                        let getRect = (block) => {
                            let startXY = block.getRelativeToSurfaceXY();
                            let endXY = { x: startXY.x + block.width, y: startXY.y + block.height };
                            let searchAllBlock = (block) => {
                                let position = block.getRelativeToSurfaceXY();
                                endXY.x = Math.max(position.x + block.width, endXY.x);
                                endXY.y = Math.max(position.y + block.height, endXY.y);
                                if (block['childBlocks_'].length > 0) {
                                    block['childBlocks_'].forEach(item => searchAllBlock(item));
                                }
                            };
                            searchAllBlock(block);
                            return { startXY, endXY };
                        };
                        let { startXY: dragStartXY, endXY: dragEndXY } = getRect(rootBlock);
                        startBlocks === null || startBlocks === void 0 ? void 0 : startBlocks.forEach((block) => {
                            let { startXY, endXY } = getRect(block);
                            if (dragStartXY.x < startXY.x
                                && dragStartXY.y < startXY.y
                                && dragEndXY.x > endXY.x
                                && dragEndXY.y > endXY.y) {
                                block.moveBy(dragEndXY.x - startXY.x, 0, ["blocks cover"]);
                            }
                        });
                    }
                    return true;
                }
                else {
                    if (oldProjectId === ((_d = model_1.Project.current()) === null || _d === void 0 ? void 0 : _d.id)) {
                        let code = blockly_1.Blockly.serialization.workspaces.save(workspace.value);
                        let program = model_1.Project.current().curProgram.program;
                        if (program)
                            program.code = code;
                    }
                    oldProjectId = (_e = model_1.Project.current()) === null || _e === void 0 ? void 0 : _e.id;
                }
                runCode();
            });
            const work = (0, work_1.useWork)();
            work.$onAction(({ name, store, args, after, onError }) => {
                if (name === 'notifyChangeProgram') {
                    after((resolve) => {
                        var _a;
                        let state = (_a = model_1.Project.current().curProgram.program) === null || _a === void 0 ? void 0 : _a.code;
                        if (!workspace.value)
                            throw new Error("workspace is undefined!");
                        if (state)
                            blockly_1.Blockly.serialization.workspaces.load(state, workspace.value);
                    });
                }
                else if (name === 'notifyChangeProject') {
                }
                else if (name.startsWith('notifyChange')) {
                    runCode();
                }
            });
        });
        (0, vue_3.onBeforeUnmount)(() => {
            var _a, _b;
            (_a = workspace.value) === null || _a === void 0 ? void 0 : _a.dispose();
            (_b = miniWorkspace.value) === null || _b === void 0 ? void 0 : _b.dispose();
        });
        return (_ctx, _cache) => {
            const _component_xml = (0, vue_2.resolveComponent)("xml");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", {
                    class: "blocklyDiv",
                    ref_key: "blocklyDiv",
                    ref: blocklyDiv
                }, null, 512),
                _hoisted_2,
                (0, vue_2.createVNode)(_component_xml, {
                    ref: "blocklyToolbox",
                    style: { "display": "none" }
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]),
                    _: 3
                }, 512)
            ]));
        };
    }
});
