"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    elite: {
        socket: {
            id: 'id',
            MoveByLine: {
                request: '{"method":"moveByLine","params":{"targetPos":${targetPos}, "speed":${speed}, "speed_type": ${speedType}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                },
            },
            MoveByJoint: {
                request: '{"method":"moveByJoint","params":{"targetPos":${targetPos}, "speed":${speed}, "acc": 50, "dec": 50},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message'
                }
            },
            SetServo: {
                request: '{"method":"set_servo_status","params":{"status": ${status}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            SetUserFrame: {
                request: '{"method":"setUserFrame","params":{"user_num": 0, "user_frame":${frame}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            StopRobot: {
                request: '{"method":"stop","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            SetUserNum: {
                request: '{"method":"setUserNumber","params":{"user_num": ${userNum}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            SetCoordMode: {
                request: '{"method":"setCurrentCoord","params":{"coord_mode": ${mode}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            MoveByJog: {
                request: '{"method":"jog","params":{"index": ${index}, "speed":${speed}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            GetTcpPose: {
                request: '{"method":"getRobotPose","params":{"unit_type":1, "coordinate_num":-1, "tool_num": 0},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    pose: 'result'
                }
            },
            GetJoints: {
                request: '{"jsonrpc":"2.0" ,"method":"getRobotPos","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    joints: 'result'
                }
            },
            InverseKinematic: {
                request: '{"method":"inverseKinematic","params":{"targetPose": ${targetPose}, "unit_type": 1},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    pos: 'result'
                }
            },
            GetIOOutputState: {
                request: '{"method":"getOutput","params":{"addr": ${addr}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    status: 'result'
                }
            },
            GetIOInputState: {
                request: '{"method":"getInput","params":{"addr": ${addr}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    status: 'result'
                }
            },
            SetIOState: {
                request: '{"method":"setOutput","params":{"addr": ${addr}, "status": ${status}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            GetRobotState: {
                request: '{"method":"getRobotState","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    state: 'result'
                }
            },
            GetMotorStatus: {
                request: '{"method":"getMotorStatus","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    state: 'result'
                }
            },
            RunFile: {
                request: '{"method":"runJbi","params":{"filename": ${filename}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            GetFileRunningState: {
                request: '{"method":"getJbiState","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    state: 'result.runState'
                }
            },
            ClearCache: {
                request: '{"method":"flush_trajectory","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                }
            },
            GetRobotSpeed: {
                request: '{"method":"getSpeed","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message',
                    speed: 'result'
                }
            },
            SetRobotSpeed: {
                request: '{"method":"setSpeed","params":{"value":${speed}},"jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message'
                }
            },
            SyncMotorStatus: {
                request: '{"method":"syncMotorStatus","jsonrpc":"2.0","id":${id}}',
                response: {
                    success: 'result',
                    msg: 'error.message'
                }
            },
        },
        http: {
            extends: {
                target: "socket",
                url: "/send",
                method: 'get',
                data: 'sendstr'
            },
        }
    },
};
