/* unplugin-vue-components disabled */const { computed } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-14df5b82"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = ["xlink:href"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: '1em'
        }
    },
    setup(__props) {
        const props = __props;
        const iconName = computed(() => `#icon-${props.iconClass}`);
        const svgClass = computed(() => {
            if (props.className) {
                return 'svg-icon ' + props.className;
            }
            else {
                return 'svg-icon';
            }
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("svg", (0, vue_2.mergeProps)({
                class: (0, vue_2.unref)(svgClass),
                "aria-hidden": "true"
            }, (0, vue_2.toHandlers)(_ctx.$listeners, true), {
                style: { 'width': __props.size, 'height': __props.size }
            }), [
                (0, vue_2.createElementVNode)("use", { "xlink:href": (0, vue_2.unref)(iconName) }, null, 8, _hoisted_1)
            ], 16));
        };
    }
});
