"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const core_1 = require("blockly/core");
Blockly.defineBlocksWithJsonArray([
    {
        'type': 'variables_get_robot',
        'message0': '%1',
        'args0': [{
                'type': 'field_variable',
                'name': 'VAR',
                'variable': '%{BKY_VARIABLES_DEFAULT_NAME}',
            }],
        'output': null,
        'style': 'variable_robot_blocks',
        'tooltip': '%{BKY_VARIABLES_GET_TOOLTIP}',
        'extensions': ['contextMenu_variableDynamicSetterGetter'],
    },
    {
        'type': 'variables_set_robot',
        'message0': '%{BKY_VARIABLES_SET}',
        'args0': [
            {
                'type': 'field_variable',
                'name': 'VAR',
                'variable': '%{BKY_VARIABLES_DEFAULT_NAME}',
            },
            {
                'type': 'input_value',
                'name': 'VALUE',
            },
        ],
        'previousStatement': null,
        'nextStatement': null,
        'style': 'variable_robot_blocks',
        'tooltip': '%{BKY_VARIABLES_SET_TOOLTIP}',
        'extensions': ['contextMenu_variableDynamicSetterGetter'],
    },
]);
const CUSTOM_CONTEXT_MENU_VARIABLE_GETTER_SETTER_MIXIN = {
    customContextMenu: function (options) {
        if (!this.isInFlyout) {
            let oppositeType;
            let contextMenuMsg;
            const id = this.getFieldValue('VAR');
            const variableModel = this.workspace.getVariableById(id);
            const varType = variableModel.type;
            if (this.type === 'variables_get_robot') {
                oppositeType = 'variables_set_robot';
                contextMenuMsg = core_1.Msg['VARIABLES_GET_CREATE_SET'];
            }
            else {
                oppositeType = 'variables_get_robot';
                contextMenuMsg = core_1.Msg['VARIABLES_SET_CREATE_GET'];
            }
            const name = this.getField('VAR').getText();
            const xmlField = core_1.utils.xml.createElement('field');
            xmlField.setAttribute('name', 'VAR');
            xmlField.setAttribute('variabletype', varType);
            xmlField.appendChild(core_1.utils.xml.createTextNode(name));
            const xmlBlock = core_1.utils.xml.createElement('block');
            xmlBlock.setAttribute('type', oppositeType);
            xmlBlock.appendChild(xmlField);
            options.push({
                enabled: this.workspace.remainingCapacity() > 0,
                text: contextMenuMsg.replace('%1', name),
                callback: core_1.ContextMenu.callbackFactory(this, xmlBlock)
            });
        }
        else {
            if (this.type === 'variables_get_robot' ||
                this.type === 'variables_get_reporter_robot') {
                const renameOption = {
                    text: core_1.Msg['RENAME_VARIABLE'] + 1,
                    enabled: true,
                    callback: renameOptionCallbackFactory(this),
                };
                const name = this.getField('VAR').getText();
                const deleteOption = {
                    text: core_1.Msg['DELETE_VARIABLE'].replace('%1', name),
                    enabled: true,
                    callback: deleteOptionCallbackFactory(this),
                };
                options.unshift(renameOption);
                options.unshift(deleteOption);
            }
        }
    },
    onchange: function (_e) {
        const id = this.getFieldValue('VAR');
        const variableModel = core_1.Variables.getVariable(this.workspace, id);
        if (this.type === 'prvariables_get_robot') {
            this.outputConnection.setCheck(variableModel.type);
        }
        else {
            this.getInput('VALUE').connection.setCheck(variableModel.type);
        }
    },
};
const renameOptionCallbackFactory = function (block) {
    return function () {
        const workspace = block.workspace;
        const variableField = block.getField('VAR');
        const variable = variableField.getVariable();
        core_1.Variables.renameVariable(workspace, variable);
    };
};
const deleteOptionCallbackFactory = function (block) {
    return function () {
        const workspace = block.workspace;
        const variableField = block.getField('VAR');
        const variable = variableField.getVariable();
        workspace.deleteVariableById(variable.getId());
        workspace.refreshToolboxSelection();
    };
};
core_1.Extensions.unregister('contextMenu_variableSetterGetter');
core_1.Extensions.registerMixin('contextMenu_variableSetterGetter', CUSTOM_CONTEXT_MENU_VARIABLE_GETTER_SETTER_MIXIN);
