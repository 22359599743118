/* unplugin-vue-components disabled */const { ref, watchEffect } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const vue_3 = require("vue");
const model_1 = require("@/model");
const one_message_1 = require("@/utils/one_message");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        item: {},
        beforeChange: { type: Function, default: (val) => true }
    },
    emits: ['update:model', 'onChange'],
    setup(__props, { emit: __emit }) {
        const attrs = (0, vue_3.useAttrs)();
        const emit = __emit;
        const myModel = ref(attrs.model);
        const inputType = ref('text');
        watchEffect(() => {
            if (attrs.model) {
                myModel.value = attrs.model;
            }
        });
        const props = __props;
        function onChange() {
            var _a;
            inputType.value = 'text';
            if (myModel.value == '') {
                myModel.value = props.item.name;
                (0, one_message_1.oneMessage)({ message: 'IO名称不可以为空，请重新输入', type: 'warning' });
                return;
            }
            if (props.beforeChange && !props.beforeChange("#" + myModel.value)) {
                myModel.value = props.item.name;
                return;
            }
            myModel.value = (_a = myModel.value) === null || _a === void 0 ? void 0 : _a.replace(/\D/g, "");
            myModel.value = "#" + myModel.value;
            if (props.item) {
                let isSameName = !!model_1.Project.current().curProgram.robot.code
                    .usingNames(props.item.type)
                    .filter(name => name !== props.item.name)
                    .find(name => name === myModel.value);
                if (isSameName) {
                    (0, one_message_1.oneMessage)({ message: '名字重复，请重新输入', type: 'warning' });
                    myModel.value = props.item.name;
                    return;
                }
            }
            emit('update:model', myModel.value);
            emit('onChange', myModel.value);
        }
        function onNameInputFocus() {
            var _a;
            if (myModel.value) {
                myModel.value = (_a = myModel.value) === null || _a === void 0 ? void 0 : _a.replace("#", "");
            }
            inputType.value = 'number';
        }
        function onNameInput() {
            myModel.value = myModel.value.replace(/\D/g, "");
            if (myModel.value.length > 4) {
                myModel.value = Math.min(9999, Number(myModel.value)).toString();
            }
        }
        return (_ctx, _cache) => {
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, (0, vue_2.mergeProps)({ id: "io-input" }, Object.assign(Object.assign({}, props), _ctx.$attrs), {
                modelValue: (0, vue_2.unref)(myModel),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((0, vue_2.isRef)(myModel) ? (myModel).value = $event : null)),
                type: (0, vue_2.unref)(inputType),
                onBlur: onChange,
                onKeydown: (0, vue_2.withKeys)(onChange, ["enter"]),
                onFocus: onNameInputFocus,
                onInput: onNameInput
            }), null, 16, ["modelValue", "type"]));
        };
    }
});
