"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const utils_1 = require("@/utils");
Blockly.defineBlocksWithJsonArray([
    {
        'type': 'math_number',
        'message0': '%1',
        'args0': [{
                'type': 'field_number',
                'name': 'NUM',
                'value': 10,
                'min': -9999999999999998,
                'max': 9999999999999998,
            }],
        'output': 'Number',
        'style': 'math_blocks',
        'tooltip': '%{BKY_MATH_NUMBER_TOOLTIP}',
        'extensions': ['parent_tooltip_when_inline'],
    },
    {
        'type': 'math_arithmetic',
        'message0': '%1 %2 %3',
        'args0': [
            {
                'type': 'input_value',
                'name': 'A',
                'check': 'Number',
            },
            {
                'type': 'field_dropdown',
                'name': 'OP',
                'options': [
                    ['%{BKY_MATH_ADDITION_SYMBOL}', 'ADD'],
                    ['%{BKY_MATH_SUBTRACTION_SYMBOL}', 'MINUS'],
                    ['%{BKY_MATH_MULTIPLICATION_SYMBOL}', 'MULTIPLY'],
                    ['%{BKY_MATH_DIVISION_SYMBOL}', 'DIVIDE'],
                    ['%{BKY_MATH_MOD_SYMBOL}', 'MOD'],
                ],
            },
            {
                'type': 'input_value',
                'name': 'B',
                'check': 'Number',
            },
        ],
        'inputsInline': true,
        'output': 'Number',
        'style': 'math_blocks',
        'extensions': ['math_op_tooltip'],
    },
    {
        "type": "math_gate",
        "message0": "%1 %2 %3",
        "args0": [{
                "type": "input_value", "name": "A", "check": "Number"
            }, {
                "type": "field_dropdown", "name": "OP", "options": [["按位与", "AND"], ["按位或", "OR"], ["按位异或", "XOR"]]
            }, {
                "type": "input_value", "name": "B", "check": "Number"
            }],
        "inputsInline": true,
        "output": "Number",
        "style": "math_blocks",
        "extensions": ["math_op_tooltip"]
    },
    {
        'type': 'math_single',
        'message0': '%1 %2',
        'args0': [
            {
                'type': 'field_dropdown',
                'name': 'OP',
                'options': [
                    ['取整', 'FLOOR'],
                ],
            },
            {
                'type': 'input_value',
                'name': 'NUM',
                'check': 'Number',
            },
        ],
        "inputsInline": true,
        'output': 'Number',
        'style': 'math_blocks',
        'extensions': ['math_op_tooltip'],
    },
]);
Blockly.Blocks['math_self'] = {
    init: function () {
        const input = this.appendValueInput("VALUE")
            .setCheck(["Number", "Boolean"])
            .appendField(new Blockly.FieldDropdown(this.varOptions, this.varChange), 'VAR')
            .appendField('=', 'LABEL_=')
            .appendField('变量', 'LABEL_VAR')
            .appendField(new Blockly.FieldDropdown([
            ['加', 'ADD'],
            ['减', 'MINUS'],
            ['乘', 'MULTIPLY'],
            ['除', 'DIVIDE'],
        ]), 'LABEL_OP');
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true, "Number");
        this.setStyle("math_blocks");
    },
    varOptions: function () {
        var _a, _b;
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        let program = project.curProgram.program;
        console.assert(robot);
        console.assert(program);
        let isSub = ((_b = (_a = model_1.Project.current()) === null || _a === void 0 ? void 0 : _a.curProgram.program) === null || _b === void 0 ? void 0 : _b.name) !== "主程序";
        let globalVars = robot.code.globalVars.filter(Var => Var.type).map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [`${isSub ? '[主程序]' : ''} ${Var.name}`, utils_1.Utils.pinyin(Var.name)];
        });
        let localVars = program.localVars.filter(Var => Var.type != model_1.VAL_TYPE.LABEL && !Var.isTemp).map((Var) => {
            let typeKey = Var.type.toString().toUpperCase();
            return [`${Var.name}`, utils_1.Utils.pinyin(Var.name)];
        });
        if (!globalVars.length && !localVars.length)
            return [['选择变量', 'NONE']];
        return [...globalVars, ...localVars];
    },
    varChange(varValue) {
        var _a;
        let ws = Blockly.getMainWorkspace();
        if (!ws)
            return [['选择变量', 'NONE']];
        const input = this.getSourceBlock().getInput('VALUE');
        let project = model_1.Project.current();
        let Var = ((_a = project.curProgram.program) === null || _a === void 0 ? void 0 : _a.getLocalVar(varValue)) || project.curProgram.robot.code.getGlobalVar(varValue);
        if (Var && input) {
            let type = Var.type.toString();
            input.setCheck(model_1.VAL_TYPE_CHECK[type]);
            input.setFieldValue(Var.name, 'LABEL_VAR');
        }
        return varValue;
    }
};
