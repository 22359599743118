"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stage = void 0;
const tslib_1 = require("tslib");
const utils_1 = require("./utils");
const bbl = tslib_1.__importStar(require("@babylonjs/core"));
const core_1 = require("@babylonjs/core");
const tween_js_1 = require("@tweenjs/tween.js");
const model_1 = require("@/model");
const work_1 = require("@/store/modules/work");
const BABYLON = bbl;
class Stage {
    constructor(renderCanvas) {
        this.meshMap = new Map();
        this.pathMap = new Map();
        this.action = () => {
        };
        this.canvas = renderCanvas;
        this.engine = new bbl.Engine(renderCanvas, true);
        let store = (0, work_1.useWork)();
        if (store.babylonScene) {
            this.loadScene(store.babylonScene);
        }
    }
    onEvent(action) {
        this.action = action;
    }
    start() {
        let start = Date.now();
        this.engine.runRenderLoop(() => {
            var _a;
            let time = Date.now() - start;
            if (time / 1000 > 1 / 60) {
                start = Date.now();
                (_a = this.scene) === null || _a === void 0 ? void 0 : _a.render();
                this.saveScene();
            }
            (0, tween_js_1.update)();
        });
        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }
    createScene() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let scene = this.scene = new core_1.Scene(this.engine);
            scene.useRightHandedSystem = true;
            scene.ambientColor = new BABYLON.Color3(1, 1, 1);
            let hl = this.hl = new bbl.HighlightLayer("hl1", scene);
            let deviceLayer = this.deviceLayer = new bbl.UtilityLayerRenderer(scene);
            let rootNode = this.rootNode = new bbl.TransformNode('devices', this.scene);
            rootNode.rotation.x = -Math.PI / 2;
            let gizmoManager = this.gizmoManager = new bbl.GizmoManager(scene, 1, deviceLayer);
            gizmoManager.positionGizmoEnabled = true;
            gizmoManager.rotationGizmoEnabled = gizmoManager.boundingBoxGizmoEnabled = gizmoManager.scaleGizmoEnabled = false;
            gizmoManager.attachableMeshes = [];
            let gizmoRManager = this.gizmoRManager = new bbl.GizmoManager(scene, 1, deviceLayer);
            gizmoRManager.rotationGizmoEnabled = true;
            gizmoRManager.positionGizmoEnabled = gizmoRManager.boundingBoxGizmoEnabled = gizmoRManager.scaleGizmoEnabled = false;
            gizmoRManager.attachableMeshes = [];
            let camera = this.camera = new bbl.ArcRotateCamera("Camera", Math.PI / 4, Math.PI / 2, 50, bbl.Vector3.Zero(), scene);
            camera.lowerBetaLimit = 0.01;
            camera.upperBetaLimit = (Math.PI / 2) * 0.9;
            camera.lowerRadiusLimit = 10;
            camera.upperRadiusLimit = 100;
            camera.wheelPrecision = 10;
            camera.pinchPrecision = 10;
            camera.attachControl(this.canvas, true);
            camera.panningSensibility = 100;
            let light = this.light = new bbl.HemisphericLight("light", new bbl.Vector3(0, 1, 0), scene);
            let skybox = utils_1.Utils.createSkybox("skybox", "texture/skybox/TropicalSunnyDay", scene);
            utils_1.Utils.createAxis(20, this.scene, this.camera);
            let grid = utils_1.Utils.createGridPlane(scene);
            scene.onBeforeRenderObservable.add(function () {
                light.direction = new core_1.Vector3(camera.position.x, camera.position.y, camera.position.z);
            });
            for (let event of ['click', 'auxclick']) {
                window.addEventListener(event, () => {
                    var _a;
                    const pickResult = scene.pick(scene.pointerX, scene.pointerY);
                    const name = (_a = pickResult === null || pickResult === void 0 ? void 0 : pickResult.pickedMesh) === null || _a === void 0 ? void 0 : _a.name;
                    if (name === "Mesh" || name === "RootMesh") {
                        let mesh = this.attachToMesh(gizmoManager, pickResult.pickedMesh, 'RootMesh');
                        if (mesh) {
                            this.selected = this.meshMap.get(mesh.id);
                            this.action('select', { id: mesh.id });
                        }
                    }
                });
            }
            let positionGizmo = gizmoManager.gizmos.positionGizmo;
            let limitPosition = (position) => {
                var _a;
                position.x = Math.max(-50, Math.min(50, position.x));
                position.y = Math.max(-50, Math.min(50, position.y));
                position.z = Math.max(0, position.z);
                let mesh = this.meshMap.get((_a = positionGizmo === null || positionGizmo === void 0 ? void 0 : positionGizmo.attachedMesh) === null || _a === void 0 ? void 0 : _a.id);
                mesh.position = position;
                let point = utils_1.Utils.stageToVue(position);
                return point;
            };
            if (positionGizmo) {
                positionGizmo.xGizmo.dragBehavior.onDragObservable.add(({ dragPlanePoint, dragDistance }) => {
                    let mesh = positionGizmo === null || positionGizmo === void 0 ? void 0 : positionGizmo.attachedMesh;
                    if (!mesh)
                        return;
                    let position = utils_1.Utils.localToLocal(mesh, this.rootNode, mesh === null || mesh === void 0 ? void 0 : mesh.position);
                    let point = limitPosition(position);
                    this.action("move", { id: mesh.id, point });
                });
                positionGizmo.yGizmo.dragBehavior.onDragObservable.add(({ dragPlanePoint }) => {
                    let mesh = positionGizmo === null || positionGizmo === void 0 ? void 0 : positionGizmo.attachedMesh;
                    if (!mesh)
                        return;
                    let position = utils_1.Utils.localToLocal(mesh, this.rootNode, mesh === null || mesh === void 0 ? void 0 : mesh.position);
                    let point = limitPosition(position);
                    this.action("move", { id: mesh.id, point });
                });
                positionGizmo.zGizmo.dragBehavior.onDragObservable.add(({ dragPlanePoint }) => {
                    let mesh = positionGizmo === null || positionGizmo === void 0 ? void 0 : positionGizmo.attachedMesh;
                    if (!mesh)
                        return;
                    let position = utils_1.Utils.localToLocal(mesh, this.rootNode, mesh === null || mesh === void 0 ? void 0 : mesh.position);
                    let point = limitPosition(position);
                    this.action("move", { id: mesh.id, point });
                });
                positionGizmo.onDragEndObservable.add((event) => {
                    console.log("onDragEndObservable");
                    console.log(event);
                });
            }
            let rotationGizmo = gizmoRManager.gizmos.rotationGizmo;
            if (rotationGizmo) {
                let onRotation = ({ dragPlanePoint, dragDistance }) => {
                    var _a, _b;
                    let r = ((_a = rotationGizmo === null || rotationGizmo === void 0 ? void 0 : rotationGizmo.attachedMesh) === null || _a === void 0 ? void 0 : _a.rotation) || core_1.Vector3.Zero();
                    this.action("rotation", {
                        id: (_b = rotationGizmo === null || rotationGizmo === void 0 ? void 0 : rotationGizmo.attachedMesh) === null || _b === void 0 ? void 0 : _b.id,
                        point: utils_1.Utils.radToAngle(r.x, r.y, r.z)
                    });
                };
                rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(onRotation);
                rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(onRotation);
                rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(onRotation);
            }
        });
    }
    saveScene() {
    }
    loadScene(sceneData) {
    }
    attachToMesh(manager, pickedMesh, names) {
        var _a;
        if (typeof names == 'string') {
            names = [names];
        }
        let mesh;
        for (let name of names) {
            mesh = pickedMesh;
            while (mesh && mesh.name != name) {
                if (!mesh.parent)
                    console.warn(mesh);
                mesh = mesh.parent;
            }
            if (mesh && mesh.name == name)
                break;
        }
        if (mesh && names.includes(mesh.name)) {
            manager.attachToMesh(mesh);
            (_a = this.hl) === null || _a === void 0 ? void 0 : _a.removeAllMeshes();
            mesh.getChildMeshes(false, (mesh) => mesh.name == 'Mesh').forEach((mesh) => { var _a; return (_a = this.hl) === null || _a === void 0 ? void 0 : _a.addMesh(mesh, bbl.Color3.White(), true); });
            return mesh;
        }
        return null;
    }
    attachNull() {
        var _a, _b, _c;
        (_a = this.gizmoManager) === null || _a === void 0 ? void 0 : _a.attachToMesh(null);
        (_b = this.gizmoRManager) === null || _b === void 0 ? void 0 : _b.attachToMesh(null);
        (_c = this.hl) === null || _c === void 0 ? void 0 : _c.removeAllMeshes();
    }
    loadMesh(machine, parentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (machine.type == model_1.ENTITY_TYPE.ROBOT) {
                yield this.createRobot(machine);
            }
            else if (machine.type == model_1.ENTITY_TYPE.TOOL) {
                yield this.createTool(machine, parentId);
            }
            else if (machine.type == model_1.ENTITY_TYPE.UCS) {
                yield this.createUcs(machine);
            }
        });
    }
    createRobot(robot) {
        var _a, _b;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let model = robot.model;
            let axle_count = model.path.length;
            let id = robot.id;
            let rootMesh = utils_1.Utils.createChild(this.rootNode, 'RootMesh', this.scene, id);
            rootMesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(robot.pr.x, robot.pr.y, robot.pr.z));
            this.meshMap.set(id, rootMesh);
            let rotateMesh = utils_1.Utils.createChild(rootMesh, 'RotateMesh', this.scene, id);
            let fixedMesh = utils_1.Utils.createChild(rotateMesh, 'fixedMesh', this.scene, id);
            let parentMesh = fixedMesh;
            let parentMeshs = [];
            for (let i = 0; i < axle_count; i++) {
                let points = model.zAxes[i];
                try {
                    let pivot = utils_1.Utils.vueToStage(new core_1.Vector3(points[0], points[1], points[2]));
                    let point = utils_1.Utils.vueToStage(new core_1.Vector3(points[3], points[4], points[5]));
                    parentMesh = utils_1.Utils.createChild(parentMesh, `pivotMesh_${i + 1}`, this.scene, id);
                    parentMesh.setPivotPoint(pivot);
                    parentMeshs.push(parentMesh);
                    utils_1.Utils.markAxis(parentMesh, pivot, point, this.scene);
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (parentMeshs.length) {
                let parentMesh = parentMeshs[parentMeshs.length - 1];
                model.TCP.forEach(tcp => {
                    let points = model.zAxes[model.zAxes.length - 1];
                    let point = utils_1.Utils.vueToStage(new core_1.Vector3(points[3], points[4], points[5]));
                    let local_point = utils_1.Utils.worldToLocal(parentMesh, point);
                    let ttcp = bbl.MeshBuilder.CreateSphere('TCP', { diameter: 2 / 10 }, this.scene);
                    let tcp_mat = new bbl.StandardMaterial("color", this.scene);
                    tcp_mat.diffuseColor = bbl.Color3.Black();
                    ttcp.material = tcp_mat;
                    ttcp.parent = parentMesh;
                    ttcp.position = local_point;
                    ttcp.rotation.y = Math.PI / 2;
                    ttcp.rotation.z = Math.PI;
                });
            }
            for (let i = 0; i < axle_count; i++) {
                let ossPath = model.path[i];
                utils_1.Utils.createMachine(this.scene, ossPath, i == 1).toPromise().then(mesh => {
                    mesh.parent = parentMeshs[i];
                    mesh.name = `axis_${i}`;
                    mesh.rotation.z = -Math.PI / 2;
                }).catch(err => {
                    console.error(err);
                });
            }
            if (this.gizmoManager)
                (_a = this.gizmoManager.attachableMeshes) === null || _a === void 0 ? void 0 : _a.push(rootMesh);
            if (this.gizmoRManager)
                (_b = this.gizmoRManager.attachableMeshes) === null || _b === void 0 ? void 0 : _b.push(rotateMesh);
            this.updateRobot(robot);
        });
    }
    createTool(tool, robot_id) {
        var _a, _b;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let model = tool.model;
            let id = tool.id;
            let robot = this.meshMap.get(robot_id);
            let tcp = this.getTcpMesh(robot_id);
            let rootMesh = utils_1.Utils.createChild(tcp, 'RootMesh', this.scene, id);
            this.meshMap.set(id, rootMesh);
            let rotateMesh = utils_1.Utils.createChild(rootMesh, 'RotateMesh', this.scene, id);
            let fixedMesh = utils_1.Utils.createChild(rotateMesh, 'fixedMesh', this.scene, id);
            let parentMesh = fixedMesh;
            let mesh = yield utils_1.Utils.createMachine(this.scene, model.path[0], true).toPromise();
            mesh.parent = tcp;
            mesh.name = `tool`;
            mesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(tool.pr.x, tool.pr.y, tool.pr.z));
            mesh.rotation = utils_1.Utils.angleToRad(tool.pr.rx, tool.pr.ry, tool.pr.rz);
            this.meshMap.set(tool.id, mesh);
            if (this.gizmoManager)
                (_a = this.gizmoManager.attachableMeshes) === null || _a === void 0 ? void 0 : _a.push(rootMesh);
            if (this.gizmoRManager)
                (_b = this.gizmoRManager.attachableMeshes) === null || _b === void 0 ? void 0 : _b.push(rotateMesh);
        });
    }
    createUcs(ucs) {
        var _a, _b;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let arrow = bbl.MeshBuilder.CreateBox("Mesh", { size: 1 }, this.scene);
            arrow.isVisible = false;
            let arrow_x = yield utils_1.Utils.loadStatic('/static/mesh/', 'arrow.obj', this.scene, bbl.Color3.Red(), new core_1.Vector3(5, 5, 5));
            let arrow_y = yield utils_1.Utils.loadStatic('/static/mesh/', 'arrow.obj', this.scene, bbl.Color3.Green(), new core_1.Vector3(5, 5, 5));
            let arrow_z = yield utils_1.Utils.loadStatic('/static/mesh/', 'arrow.obj', this.scene, bbl.Color3.Blue(), new core_1.Vector3(5, 5, 5));
            arrow_x.rotation.y = -Math.PI / 2;
            arrow_y.rotation.x = Math.PI / 2;
            arrow_z.rotation.x = Math.PI;
            arrow_x.parent = arrow_y.parent = arrow_z.parent = arrow;
            let rootMesh = utils_1.Utils.createChild(this.rootNode, 'RootMesh', this.scene, ucs.id, false, true, 0.2);
            rootMesh.parent = this.rootNode;
            rootMesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(ucs.pr.x, ucs.pr.y, ucs.pr.z));
            rootMesh.rotation = utils_1.Utils.angleToRad(ucs.pr.rx, ucs.pr.ry, ucs.pr.rz);
            this.meshMap.set(ucs.id, rootMesh);
            let point = utils_1.Utils.stageToVue(rootMesh.position);
            this.action("move", { id: ucs.id, point });
            if (this.gizmoManager)
                (_a = this.gizmoManager.attachableMeshes) === null || _a === void 0 ? void 0 : _a.push(rootMesh);
            if (this.gizmoRManager)
                (_b = this.gizmoRManager.attachableMeshes) === null || _b === void 0 ? void 0 : _b.push(rootMesh);
        });
    }
    updateRobot(robot) {
        let mesh = this.meshMap.get(robot.id);
        if (mesh) {
            mesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(robot.pr.x, robot.pr.y, robot.pr.z));
            let [rotateMesh] = mesh.getChildMeshes(true, (node) => node.name === 'RotateMesh');
            if (rotateMesh) {
                rotateMesh.rotation = utils_1.Utils.angleToRad(robot.pr.rx, robot.pr.ry, robot.pr.rz);
            }
            let pivotMeshes = mesh.getChildMeshes(false, (node) => {
                return node.name.startsWith('pivotMesh_');
            });
            this.updateTool(robot);
            pivotMeshes.forEach(pivotMesh => {
                if (pivotMesh.name.indexOf('_') > 0) {
                    let index = parseInt(pivotMesh.name.split('_')[1]) - 2;
                    if (index >= 0) {
                        let angle = robot.getAxisAngle(index);
                        let { pivot, point } = utils_1.Utils.getAxis(pivotMesh, this.scene);
                        let axis = new bbl.Vector3(point.x - pivot.x, point.y - pivot.y, point.z - pivot.z);
                        let rotationQuaternion = bbl.Quaternion.RotationAxis(axis, utils_1.Utils.angleToRad(angle).x);
                        pivotMesh.rotationQuaternion = rotationQuaternion;
                    }
                }
            });
        }
    }
    updateTool(robot) {
        let tool = robot.tool;
        let tcp = this.getTcpMesh(robot.id);
        if (!tool || !tcp)
            return;
        let [mesh] = tcp.getChildMeshes(true, child => child.name === 'tool');
        if (!mesh)
            return;
        mesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(tool.pr.x, tool.pr.y, tool.pr.z));
        mesh.rotation = utils_1.Utils.angleToRad(tool.pr.rx, tool.pr.ry, tool.pr.rz);
    }
    updateEntity(ucs) {
        let rootMesh = this.meshMap.get(ucs.id);
        if (rootMesh) {
            rootMesh.position = utils_1.Utils.vueToStage(new core_1.Vector3(ucs.pr.x, ucs.pr.y, ucs.pr.z));
            let [rotateMesh] = rootMesh.getChildMeshes(true, (node) => node.name === 'RotateMesh');
            if (rotateMesh) {
                rotateMesh.rotation = utils_1.Utils.angleToRad(ucs.pr.rx, ucs.pr.ry, ucs.pr.rz);
            }
            else {
                rootMesh.rotation = utils_1.Utils.angleToRad(ucs.pr.rx, ucs.pr.ry, ucs.pr.rz);
            }
        }
    }
    updatePath(robot) {
        let mesh = this.meshMap.get(robot.id);
        robot.paths.filter(path => path.type === 'path').forEach(path => {
            let points = path.points.map(point => {
                let pt = new core_1.Vector3(point[0], point[1], point[2]);
                return utils_1.Utils.vueToStage(pt);
            });
            let linesMesh = this.pathMap.get(path.id);
            let mat = new bbl.StandardMaterial("color", this.scene);
            mat.diffuseColor = bbl.Color3.Green();
            let line = BABYLON.MeshBuilder.CreateDashedLines(path.name, {
                points,
                updatable: true,
                instance: linesMesh
            }, this.scene);
            line.material = mat;
            line.color = bbl.Color3.White();
            line.parent = mesh;
            line.rotation = utils_1.Utils.angleToRad(robot.pr.rx, robot.pr.ry, robot.pr.rz);
            this.pathMap.set(path.id, line);
        });
    }
    selectEntity(node) {
        let tcpMesh = this.getTcpMesh(node.rootId);
        if ([work_1.NODE_TYPE.TOOL, work_1.NODE_TYPE.TCP].includes(node.type)) {
            if (this.selected !== tcpMesh) {
                this.selected = tcpMesh;
                this.lookAtMesh(tcpMesh).then();
            }
        }
        else if (node.type == work_1.NODE_TYPE.ROBOT) {
            this.camera.zoomOnFactor = 20;
            this.selectMachine(node.target.id).then(() => {
                this.camera.zoomOnFactor = 1;
            });
        }
        else if (node.type == work_1.NODE_TYPE.MENU) {
            switch (node.target.type) {
                case work_1.VIR_NODE_TYPE.N_PATH:
                    break;
                case work_1.VIR_NODE_TYPE.N_TAIL:
                case work_1.VIR_NODE_TYPE.N_TCP:
                    if (this.selected !== tcpMesh) {
                        this.selected = tcpMesh;
                        this.lookAtMesh(tcpMesh).then();
                    }
                    break;
            }
        }
        else {
            this.selectMachine(node.target.id);
        }
    }
    selectMachine(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let rootMesh = this.meshMap.get(id);
            if (rootMesh) {
                this.attachToMesh(this.gizmoManager, rootMesh, 'RootMesh');
                let [rotateMesh] = rootMesh.getChildMeshes(true, (node) => node.name === 'RotateMesh');
                this.attachToMesh(this.gizmoRManager, rotateMesh || rootMesh, ['RotateMesh', 'RootMesh']);
                if (this.selected !== rootMesh) {
                    this.selected = rootMesh;
                    let newTarget = utils_1.Utils.localToWorld(rootMesh.parent, rootMesh.position);
                    yield this.lookAtMesh(rootMesh);
                }
            }
            else {
                console.log(`找不到machine: ${id}`);
                this.attachNull();
            }
        });
    }
    lookAtMesh(target) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!target)
                return;
            const minMaxVector = core_1.Mesh.MinMax(target.getChildMeshes(true));
            const distance = core_1.Vector3.Distance(minMaxVector.min, minMaxVector.max);
            let radius = distance * this.camera.zoomOnFactor;
            let position = utils_1.Utils.localToWorld(target.parent, target.position);
            yield utils_1.Utils.lookAt(position, radius, this.camera);
        });
    }
    removeAll() {
        let pathKeys = this.pathMap.keys();
        for (let id of pathKeys) {
            this.removePath(id);
        }
        let machineKeys = this.meshMap.keys();
        for (let id of machineKeys) {
            this.removeMachine(id);
        }
    }
    removePath(path_id) {
        let line = this.pathMap.get(path_id);
        if (line) {
            this.pathMap.delete(path_id);
            line.dispose();
        }
    }
    removeMachine(id) {
        let rootMesh = this.meshMap.get(id);
        if (rootMesh) {
            rootMesh.parent = null;
            this.meshMap.delete(id);
            this.attachNull();
            rootMesh.dispose();
        }
    }
    visibleMachine(id, visible) {
        let rootMesh = this.meshMap.get(id);
        if (rootMesh) {
            rootMesh.setEnabled(visible);
        }
    }
    getTcpMesh(robot_id) {
        let robot = this.meshMap.get(robot_id);
        let tcp;
        if (robot) {
            let tcpMeshes = robot.getChildMeshes(false, (node) => node.name.startsWith("TCP"));
            if (tcpMeshes.length > 0) {
                tcpMeshes.sort((a, b) => a.name > b.name ? -1 : 1);
                tcp = tcpMeshes[0];
            }
        }
        return tcp;
    }
    getTcpPoint(robot_id) {
        let robot = this.meshMap.get(robot_id);
        if (robot) {
            let tcp = this.getTcpMesh(robot_id);
            if (tcp) {
                let [rotateMesh] = robot.getChildMeshes(true, (node) => node.name === 'RotateMesh');
                let point1 = utils_1.Utils.localToWorld(tcp, core_1.Vector3.Zero());
                let point = utils_1.Utils.worldToLocal(rotateMesh, point1);
                let pt = utils_1.Utils.stageToVue(point);
                return new model_1.PositionRotation({ x: pt.x, y: pt.y, z: pt.z });
            }
        }
        return null;
    }
    getUcsPoint(ucs_id, robot_id) {
        let ucs = this.meshMap.get(ucs_id);
        let robot = this.meshMap.get(robot_id);
        if (ucs && robot) {
            let [rotateMesh] = robot.getChildMeshes(true, (node) => node.name === 'RotateMesh');
            let point = utils_1.Utils.localToLocal(ucs, rotateMesh, ucs.position);
            let pt = utils_1.Utils.stageToVue(point);
            return new model_1.PositionRotation({ x: pt.x, y: pt.y, z: pt.z });
        }
        return null;
    }
    static call(method, ...params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const store = (0, work_1.useWork)();
            return new Promise((resolve, reject) => {
                let id = setTimeout(() => {
                    if (done)
                        return;
                    reject('channel call timeout');
                }, 1000);
                let done = false;
                store.$onAction(({ name, store, args, after, onError }) => {
                    if (name === 'notifyChannelCallback') {
                        let [cb_method, result] = args;
                        if (method == cb_method && !done) {
                            done = true;
                            clearTimeout(id);
                            resolve(result);
                        }
                    }
                });
                store.notifyChannel(method, params);
            });
        });
    }
}
exports.Stage = Stage;
