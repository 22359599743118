"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.closeLoading = exports.openLoading = void 0;
const element_plus_1 = require("element-plus");
let loadingIns = null;
function openLoading(options) {
    if (loadingIns && loadingIns.text && options) {
        loadingIns.text.value = options.text;
    }
    else {
        loadingIns = element_plus_1.ElLoading.service(options);
    }
}
exports.openLoading = openLoading;
function closeLoading() {
    if (loadingIns) {
        loadingIns.close();
        loadingIns = null;
    }
}
exports.closeLoading = closeLoading;
