"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.radToDeg = exports.degToRad = void 0;
function degToRad(degrees) {
    return degrees * (Math.PI / 180);
}
exports.degToRad = degToRad;
function radToDeg(radians) {
    return radians * (180 / Math.PI);
}
exports.radToDeg = radToDeg;
