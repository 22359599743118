"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const user_1 = require("@/service/user");
const vue_router_1 = require("vue-router");
const loading_1 = require("@/utils/loading");
const one_message_1 = require("@/utils/one_message");
const user_2 = require("@/service/user");
const routes = [
    {
        path: '/',
        name: 'login',
        component: () => Promise.resolve().then(() => tslib_1.__importStar(require('@/views/Login.vue'))),
    },
    {
        path: '/ide',
        name: 'IDE',
        redirect: { name: 'design' },
        component: () => Promise.resolve().then(() => tslib_1.__importStar(require('@/views/Main.vue'))),
        children: [
            {
                path: 'laborer',
                name: 'laborer',
                component: () => Promise.resolve().then(() => tslib_1.__importStar(require('@/views/laborer/index.vue'))),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: 'design',
                name: 'design',
                component: () => Promise.resolve().then(() => tslib_1.__importStar(require('@/views/design/index.vue'))),
                meta: {
                    keepAlive: true
                }
            }, {
                path: 'program',
                name: 'program',
                component: () => Promise.resolve().then(() => tslib_1.__importStar(require('@/views/program/index.vue'))),
                meta: {
                    keepAlive: true
                }
            }
        ]
    },
];
const router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes
});
router.beforeEach((to, from, next) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    console.log('before', to);
    (0, loading_1.openLoading)({ text: '加载中' });
    const toLogin = () => {
        (0, user_1.clearUserToken)();
        (0, loading_1.closeLoading)();
        one_message_1.oneMessage.error('用户无权限！');
        next({ name: 'login' });
    };
    if (to.name === 'login') {
        if (user_1.userToken.token.value && user_1.userToken.token.value != "") {
            next("/ide");
        }
    }
    else {
        switch (to.name) {
            case 'laborer':
                if (yield user_2.permService.hasLaborer()) {
                    next();
                }
                else {
                    if (yield user_2.permService.hasDesign()) {
                        next({ name: 'design' });
                    }
                    else if (yield user_2.permService.hasProgram()) {
                        next({ name: 'program' });
                    }
                    else {
                        toLogin();
                    }
                }
                break;
            case 'design':
                if (yield user_2.permService.hasDesign()) {
                    next();
                }
                else {
                    if (yield user_2.permService.hasProgram()) {
                        next({ name: 'program' });
                    }
                    else if (yield user_2.permService.hasLaborer()) {
                        next({ name: 'laborer' });
                    }
                    else {
                        toLogin();
                    }
                }
                break;
            case 'program':
                if (yield user_2.permService.hasProgram()) {
                    next();
                }
                else {
                    if (yield user_2.permService.hasDesign()) {
                        next({ name: 'design' });
                    }
                    else if (yield user_2.permService.hasLaborer()) {
                        next({ name: 'laborer' });
                    }
                    else {
                        toLogin();
                    }
                }
                break;
        }
    }
    next();
}));
router.afterEach(() => {
    (0, loading_1.closeLoading)();
});
exports.default = router;
