"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.protocol = void 0;
var protocol;
(function (protocol) {
    protocol.getRequestId = () => (Date.now() % 1000000).toString() + Math.round(Math.random() * 1000);
    class Request {
        constructor() {
            this.id = protocol.getRequestId();
        }
    }
    protocol.Request = Request;
    class Response {
        constructor(id) {
            this.msg = '';
            this.success = false;
            this.id = id;
        }
    }
    protocol.Response = Response;
    class DefaultResponse extends Response {
        constructor(id, success = true, msg) {
            super(id);
            this.success = success;
            this.msg = msg || '';
        }
    }
    protocol.DefaultResponse = DefaultResponse;
    class SetServoRequest extends Request {
        constructor(status) {
            super();
            this.status = status;
        }
    }
    protocol.SetServoRequest = SetServoRequest;
    class MoveByJogRequest extends Request {
        constructor(speed, index) {
            super();
            this.speed = speed;
            this.index = index;
        }
    }
    protocol.MoveByJogRequest = MoveByJogRequest;
    class MoveByJointRequest extends Request {
        constructor(targetPos, speed) {
            super();
            this.speed = speed;
            this.targetPos = targetPos;
        }
    }
    protocol.MoveByJointRequest = MoveByJointRequest;
    class SetCoordModeRequest extends Request {
        constructor(mode) {
            super();
            this.mode = mode;
        }
    }
    protocol.SetCoordModeRequest = SetCoordModeRequest;
    class SetUserFrameRequest extends Request {
        constructor(userFrame) {
            super();
            this.frame = userFrame;
        }
    }
    protocol.SetUserFrameRequest = SetUserFrameRequest;
    class SetUserNumRequest extends Request {
        constructor(userNum) {
            super();
            this.userNum = userNum;
        }
    }
    protocol.SetUserNumRequest = SetUserNumRequest;
    class StopRobotRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.StopRobotRequest = StopRobotRequest;
    class GetTcpPoseRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetTcpPoseRequest = GetTcpPoseRequest;
    class GetTcpPoseResponse extends DefaultResponse {
        constructor(id, success, pose = []) {
            super(id, success);
            this.pose = pose;
        }
    }
    protocol.GetTcpPoseResponse = GetTcpPoseResponse;
    class GetJointsRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetJointsRequest = GetJointsRequest;
    class GetJointsResponse extends DefaultResponse {
        constructor(id, success, joints = []) {
            super(id, success);
            this.joints = joints;
        }
    }
    protocol.GetJointsResponse = GetJointsResponse;
    class GetRobotSpeedRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetRobotSpeedRequest = GetRobotSpeedRequest;
    class GetRobotSpeedResponse extends DefaultResponse {
        constructor(id, success, speed) {
            super(id, success);
            this.speed = speed;
        }
    }
    protocol.GetRobotSpeedResponse = GetRobotSpeedResponse;
    class SetRobotSpeedRequest extends Request {
        constructor(speed) {
            super();
            this.speed = speed;
        }
    }
    protocol.SetRobotSpeedRequest = SetRobotSpeedRequest;
    class GetRobotStateRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetRobotStateRequest = GetRobotStateRequest;
    class GetRobotStateResponse extends DefaultResponse {
        constructor(id, success, state) {
            super(id, success);
            this.state = state;
        }
    }
    protocol.GetRobotStateResponse = GetRobotStateResponse;
    class GetMotorStatusRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetMotorStatusRequest = GetMotorStatusRequest;
    class GetMotorStatusResponse extends DefaultResponse {
        constructor(id, success, state) {
            super(id, success);
            this.state = state;
        }
    }
    protocol.GetMotorStatusResponse = GetMotorStatusResponse;
    class RunFileRequest extends Request {
        constructor(filename) {
            super();
            this.filename = filename;
        }
    }
    protocol.RunFileRequest = RunFileRequest;
    class GetFileRunningStateRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.GetFileRunningStateRequest = GetFileRunningStateRequest;
    class GetFileRunningStateResponse extends DefaultResponse {
        constructor(id, success, state) {
            super(id, success);
            this.runState = state;
        }
    }
    protocol.GetFileRunningStateResponse = GetFileRunningStateResponse;
    class InverseKinematicRequest extends Request {
        constructor(targetPose) {
            super();
            this.targetPose = targetPose;
        }
    }
    protocol.InverseKinematicRequest = InverseKinematicRequest;
    class InverseKinematicResponse extends DefaultResponse {
        constructor(id, success = true, pos = []) {
            super(id, success);
            this.pos = pos;
        }
    }
    protocol.InverseKinematicResponse = InverseKinematicResponse;
    class MoveByLineRequest extends Request {
        constructor(speed, targetPos, speedType = 3) {
            super();
            this.speed = speed;
            this.targetPos = targetPos;
            this.speedType = speedType;
        }
    }
    protocol.MoveByLineRequest = MoveByLineRequest;
    class SyncMotorStatusRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.SyncMotorStatusRequest = SyncMotorStatusRequest;
    class SetIOStateRequest extends Request {
        constructor(addr, state) {
            super();
            this.addr = addr;
            this.status = state;
        }
    }
    protocol.SetIOStateRequest = SetIOStateRequest;
    class GetIOStateRequest extends Request {
        constructor(addr) {
            super();
            this.addr = addr;
        }
    }
    class GetIOInputStateRequest extends GetIOStateRequest {
        constructor(addr) {
            super(addr);
        }
    }
    protocol.GetIOInputStateRequest = GetIOInputStateRequest;
    class GetIOOutputStateRequest extends GetIOStateRequest {
        constructor(addr) {
            super(addr);
        }
    }
    protocol.GetIOOutputStateRequest = GetIOOutputStateRequest;
    class GetIOStateResponse extends DefaultResponse {
        constructor(id, success, state) {
            super(id);
            this.success = success;
            this.state = state;
        }
    }
    protocol.GetIOStateResponse = GetIOStateResponse;
    class ClearCacheRequest extends Request {
        constructor() {
            super();
        }
    }
    protocol.ClearCacheRequest = ClearCacheRequest;
    class FileRequest extends Request {
        constructor(path) {
            super();
            this.path = path;
        }
    }
    protocol.FileRequest = FileRequest;
    class FileResponse extends Response {
        constructor(id, path, content) {
            super(id);
            this.path = path;
            this.content = content;
        }
    }
    protocol.FileResponse = FileResponse;
    protocol.requestMap = new Map();
    [
        [MoveByLineRequest.name, 'MoveByLine'],
        [MoveByJointRequest.name, 'MoveByJoint'],
        [SetServoRequest.name, 'SetServo'],
        [SetUserFrameRequest.name, 'SetUserFrame'],
        [StopRobotRequest.name, 'StopRobot'],
        [SetUserNumRequest.name, 'SetUserNum'],
        [SetCoordModeRequest.name, 'SetCoordMode'],
        [MoveByJogRequest.name, 'MoveByJog'],
        [GetTcpPoseRequest.name, 'GetTcpPose'],
        [GetJointsRequest.name, 'GetJoints'],
        [InverseKinematicRequest.name, 'InverseKinematic'],
        [GetIOOutputStateRequest.name, 'GetIOOutputState'],
        [GetIOInputStateRequest.name, 'GetIOInputState'],
        [SetIOStateRequest.name, 'SetIOState'],
        [GetRobotStateRequest.name, 'GetRobotState'],
        [GetMotorStatusRequest.name, 'GetMotorStatus'],
        [RunFileRequest.name, 'RunFile'],
        [GetFileRunningStateRequest.name, 'GetFileRunningState'],
        [ClearCacheRequest.name, 'ClearCache'],
        [GetRobotSpeedRequest.name, 'GetRobotSpeed'],
        [SetRobotSpeedRequest.name, 'SetRobotSpeed'],
        [SyncMotorStatusRequest.name, 'SyncMotorStatus']
    ].forEach(item => {
        protocol.requestMap.set(item[0], item[1]);
    });
    class Encoding {
        static register(name, codec) {
            Encoding[name] = codec;
        }
    }
    protocol.Encoding = Encoding;
})(protocol || (exports.protocol = protocol = {}));
