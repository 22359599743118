/* unplugin-vue-components disabled */const { ref } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ListItem',
    props: {
        isSelected: { type: Boolean }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const activeNames = ref(['listItem']);
        function openCollapse() {
            activeNames.value = ['listItem'];
        }
        __expose({
            openCollapse
        });
        return (_ctx, _cache) => {
            const _component_el_collapse_item = (0, vue_2.resolveComponent)("el-collapse-item");
            const _component_el_collapse = (0, vue_2.resolveComponent)("el-collapse");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(_component_el_collapse, {
                    modelValue: (0, vue_2.unref)(activeNames),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((0, vue_2.isRef)(activeNames) ? (activeNames).value = $event : null))
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.createVNode)(_component_el_collapse_item, { name: "listItem" }, {
                            title: (0, vue_2.withCtx)(() => [
                                (0, vue_2.renderSlot)(_ctx.$slots, "header")
                            ]),
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.renderSlot)(_ctx.$slots, "content")
                            ]),
                            _: 3
                        })
                    ]),
                    _: 3
                }, 8, ["modelValue"])
            ]));
        };
    }
});
