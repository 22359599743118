"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Blockly = tslib_1.__importStar(require("blockly/core"));
const model_1 = require("@/model");
const common_1 = require("@/views/program/components/Blockly/blocks/common");
const one_message_1 = require("@/utils/one_message");
Blockly.Blocks['state_get'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('状态', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(function () {
            return [["TCP位姿信息", model_1.VAL_TYPE.POSE], ["关节角度信息", model_1.VAL_TYPE.JOINT]];
        }, this.typeChange), 'STATE_TYPE');
        const save = this.appendDummyInput()
            .appendField("保存到", "LABEL_SAVE")
            .appendField(new Blockly.FieldDropdown(this.varOptions, this.varChange), 'VAR');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    },
    typeChange: function (typeValue) {
        let block = this.getSourceBlock();
        let oldValue = block.getFieldValue('STATE_TYPE');
        if (oldValue != typeValue) {
            block.setFieldValue('NONE', 'VAR');
        }
        return typeValue;
    },
    varOptions: (0, common_1.getVarOptions)(Var => {
        return [model_1.VAL_TYPE.POSE, model_1.VAL_TYPE.JOINT].includes(Var.type);
    }),
    varChange: function (varValue) {
        if (varValue == 'NONE')
            return varValue;
        let project = model_1.Project.current();
        if (!project)
            return varValue;
        if (varValue == "CREATE") {
            alert("TODO 弹出新增变量窗口");
        }
        let Var = project.curProgram.getVar(varValue);
        let block = this.getSourceBlock();
        let stateType = block.getFieldValue('STATE_TYPE');
        let oldValue = block.getFieldValue('VAR');
        if (stateType == model_1.VAL_TYPE.POSE) {
            if (!Var || Var.type !== model_1.VAL_TYPE.POSE) {
                (0, one_message_1.oneMessage)({
                    message: '请选择[位姿]类型',
                    grouping: true,
                    type: 'error'
                });
                if (oldValue == 'NONE')
                    return oldValue;
                let Var = project.curProgram.getVar(oldValue);
                if (!Var || Var.type !== model_1.VAL_TYPE.POSE) {
                    block.setFieldValue('NONE', 'VAR');
                    return 'NONE';
                }
                return oldValue;
            }
        }
        else if (stateType == model_1.VAL_TYPE.JOINT) {
            if (!Var || Var.type !== model_1.VAL_TYPE.JOINT) {
                (0, one_message_1.oneMessage)({
                    message: '请选择[关节]类型',
                    grouping: true,
                    type: 'error'
                });
                if (oldValue == 'NONE')
                    return oldValue;
                let Var = project.curProgram.getVar(oldValue);
                if (!Var || Var.type !== model_1.VAL_TYPE.JOINT) {
                    block.setFieldValue('NONE', 'VAR');
                    return 'NONE';
                }
                return oldValue;
            }
        }
        return varValue;
    }
};
Blockly.Blocks['io_save_sign'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('获取', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(this.ioOptions), 'IO_ID');
        const save = this.appendDummyInput()
            .appendField("保存到", "LABEL_SAVE")
            .appendField(new Blockly.FieldDropdown(this.varOptions, this.varChange), 'VAR');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyChangeIO', that.id, ({ id, name, type, remark }, update) => {
            let text = `${name}：${remark || model_1.IO_TYPE_NAME[type]}`;
            let field = that.getField('IO_ID');
            if (!field)
                return;
            update(field, id, text);
        });
        common_1.DataListener.instance.onAction('notifyDeleteIO', that.id, ({ id }, update) => {
            let field = that.getField('IO_ID');
            if (!field)
                return;
            let fieldVal = that.getFieldValue('IO_ID');
            if (fieldVal === id)
                field.setValue("NONE");
        });
    },
    ioOptions: function () {
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        console.assert(robot);
        let ioList;
        if (robot.type === model_1.ENTITY_TYPE.ROBOT && (ioList = model_1.Robot.current.filterIO(io => io.type.indexOf('I') >= 0 && io.usingType !== 'feedBin')).length) {
            return [['选择IO', 'NONE'], ...ioList.map(io => {
                    return [`${io.name}：${io.remark || model_1.IO_TYPE_NAME[io.type]}`, io.id];
                })];
        }
        return [['选择IO', 'NONE']];
    },
    varOptions: (0, common_1.getVarOptions)(Var => {
        return [model_1.VAL_TYPE.DOUBLE, model_1.VAL_TYPE.INT].includes(Var.type);
    }),
    varChange: function (newValue) {
        if (newValue == "CREATE") {
            alert("TODO 弹出新增变量窗口");
        }
        return newValue;
    }
};
Blockly.Blocks['io_get_sign'] = {
    init: function () {
        const that = this;
        const ioField = new Blockly.FieldDropdown(this.ioOptions);
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('信号', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(ioField, 'IO_ID');
        this.setPreviousStatement(false);
        this.setNextStatement(false);
        this.setStyle("math_blocks");
        this.setOutput("Null");
        this.optionChange();
        ioField.setValidator(function (ioValue) {
            let project = model_1.Project.current();
            if (!project)
                return ioValue;
            let io = project.curProgram.robot.code.ioList.find(io => io.id === ioValue);
            if (io) {
                switch (io.type) {
                    case model_1.IO_TYPE.DI:
                    case model_1.IO_TYPE.GI:
                    case model_1.IO_TYPE.AI:
                        that.setOutput(true, 'Number');
                        return ioValue;
                }
            }
            that.setOutput(true, 'Null');
            return ioValue;
        });
    },
    optionChange: Blockly.Blocks['io_save_sign'].optionChange,
    ioOptions: Blockly.Blocks['io_save_sign'].ioOptions
};
Blockly.Blocks['io_wait_sign'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('等待', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown(this.ioOptions), 'IO_ID')
            .appendField("信号为", "LABEL_AS")
            .appendField(new Blockly.FieldDropdown(function () {
            return [["开", "1"], ["关", "0"]];
        }), 'VALUE');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
        this.optionChange();
    },
    optionChange: Blockly.Blocks['io_save_sign'].optionChange,
    ioOptions: Blockly.Blocks['io_save_sign'].ioOptions
};
Blockly.Blocks['io_send_sign'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('发送', 'blocklyText blocklyBold'))
            .appendField(new Blockly.FieldDropdown(this.ioOptions, this.validate), 'IO_ID');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
        this.optionChange();
    },
    optionChange: Blockly.Blocks['io_save_sign'].optionChange,
    ioOptions: function () {
        let project = model_1.Project.current();
        let robot = project.curProgram.robot;
        console.assert(robot);
        let ioList;
        if (robot.type === model_1.ENTITY_TYPE.ROBOT && (ioList = robot.code.ioList.filter(io => io.type.indexOf('O') >= 0)).length) {
            return [['选择IO', 'NONE'], ...ioList.map(io => {
                    return [`${io.name}：${io.remark || model_1.IO_TYPE_NAME[io.type]}`, io.id];
                })];
        }
        return [['选择IO', 'NONE']];
    },
    validate: function (newValue) {
        let that = this.getSourceBlock();
        let robot = model_1.Project.current().curProgram.robot;
        console.assert(robot);
        let ioList;
        if (robot.type === model_1.ENTITY_TYPE.ROBOT && (ioList = robot.code.ioList.filter(io => io.type.indexOf("O") >= 0)).length) {
            let io = ioList.find(io => io.id == newValue);
            that.removeInput('VALUE', true);
            that.removeInput('DUMMY', true);
            if (!io)
                return newValue;
            switch (io.type) {
                case model_1.IO_TYPE.DO:
                    that.appendDummyInput("DUMMY")
                        .appendField(new Blockly.FieldLabel("发送信息"), "LABEL")
                        .appendField(new Blockly.FieldDropdown(function () {
                        return [["开", "1"], ["关", "0"]];
                    }), 'VALUE')
                        .appendField(new Blockly.FieldLabel("脉冲信号"), "LABEL_PULSE")
                        .appendField(new Blockly.FieldCheckbox("FALSE", (is_pulse) => {
                        let dummy = that.getInput('DUMMY');
                        dummy.removeField("LABEL_TIME", true);
                        dummy.removeField("TIME", true);
                        if (is_pulse == "TRUE") {
                            dummy.appendField(new Blockly.FieldLabel("脉冲时长(ms)"), "LABEL_TIME")
                                .appendField(new Blockly.FieldNumber(100), "TIME");
                        }
                    }), "IS_PULSE");
                    break;
                case model_1.IO_TYPE.AO:
                    that.appendDummyInput("DUMMY")
                        .appendField(new Blockly.FieldLabel("发送信息"), "LABEL")
                        .appendField(new Blockly.FieldNumber(0, -10, 10), 'VALUE')
                        .appendField(new Blockly.FieldLabel("输出时间(ms)"))
                        .appendField(new Blockly.FieldNumber(100), "TIME")
                        .appendField(new Blockly.FieldLabel("中断使能"))
                        .appendField(new Blockly.FieldCheckbox("FALSE"), "IS_IE");
                    break;
                case model_1.IO_TYPE.GO:
                    that.appendDummyInput("DUMMY")
                        .appendField(new Blockly.FieldLabel("发送信息"), "LABEL")
                        .appendField(new Blockly.FieldNumber(0), 'VALUE');
                    break;
            }
        }
        return newValue;
    }
};
Blockly.Blocks['sys_io_wait_sign'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('等待 ', 'blocklyText blocklyBold'))
            .appendField(new Blockly.FieldLabel('系统I/O', 'blocklyText'))
            .appendField(new Blockly.FieldNumber(0, 0, null, 1), 'IO_ID')
            .appendField(new Blockly.FieldLabel("信号为"), "LABEL")
            .appendField(new Blockly.FieldDropdown(function () {
            return [["开", "1"], ["关", "0"]];
        }), 'VALUE');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    }
};
Blockly.Blocks['sys_io_send_sign'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('发送 ', 'blocklyText blocklyBold'))
            .appendField(new Blockly.FieldLabel('系统I/O', 'blocklyText'))
            .appendField(new Blockly.FieldNumber(0, 0, null, 1), 'IO_ID')
            .appendField(new Blockly.FieldLabel("信号为"), "LABEL")
            .appendField(new Blockly.FieldDropdown(function () {
            return [["开", "1"], ["关", "0"]];
        }), 'VALUE');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    },
    validate: function (newValue) {
        return newValue;
    }
};
Blockly.Blocks['set_coordinate'] = {
    init: function () {
        const coordDropdown = new Blockly.FieldDropdown(this.options);
        coordDropdown.setValue('$base');
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('设置', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldLabel('基准坐标='), 'LABEL_NAME')
            .appendField(coordDropdown, 'COORDINATE');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
        this.optionChange();
    },
    optionChange: function () {
        let that = this.getSourceBlock ? this.getSourceBlock() : this;
        common_1.DataListener.instance.onAction('notifyDeleteUcs', that.id, ({ id, name }, update) => {
            let field = that.getField('COORDINATE');
            if (!field)
                return;
            let fieldVal = that.getFieldValue('COORDINATE');
            if (fieldVal === id)
                field.setValue('NONE');
        });
    },
    options() {
        let rpList = model_1.Project.current().curProgram.robot.tcps.filter(e => e.reference.frame === 'rp').map(e => [e.name, e.id]);
        return [['请选择', 'NONE'], ['基坐标系', '$base'], ...rpList];
    }
};
Blockly.Blocks['set_dynamic'] = {
    init: function () {
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('设置', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldDropdown([['全局速度', 'SPEED'], ['加速度', 'ACC'], ['减速度', 'DEC'], ['力矩', 'MOMENTAL']]), 'TYPE')
            .appendField(new Blockly.FieldLabel('='), 'LABEL_NAME')
            .appendField(new Blockly.FieldNumber(100, 1, 100, 1), "VALUE")
            .appendField(new Blockly.FieldLabel('%'));
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    }
};
Blockly.Blocks['set_error'] = {
    init: function () {
        const textInput = new Blockly.FieldTextInput('错误信息');
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('打印', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(textInput, "VALUE");
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    }
};
Blockly.Blocks['set_stop'] = {
    init: function () {
        const textInput = new Blockly.FieldTextInput('停止信息');
        const input = this.appendDummyInput()
            .appendField(new Blockly.FieldLabel('停止运行', 'blocklyText blocklyBold'), 'LABEL')
            .appendField(new Blockly.FieldLabel('并打印'), 'LABEL_NAME')
            .appendField(textInput, "VALUE");
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle("state_blocks");
    }
};
