export default {
  "MovePath":     1,
  "MoveRelLin":	  2,
  "MoveLin":	    3,
  "MoveRelJog":	  4,
  "MoveJoints":   5,
  "MoveMaterial": 6,
  "Pallet":       7,

  "GetPose":	  101,
  "GetPoseE":	  102,
  "GetJoint": 	103,
  "GetJointE": 	104,
  "SetCoord":	  105,
  "SetDyn":	    106,
  "SetSpeed":	  107,
  "SetErr":	    108,
  "SetStop":	  109,
  "Set":        110,
  "SetPose":  	111,
  "SetJoint": 	112,
  "OffAxis":	  113,
  "OffAxisJ": 	114,
  "EuclideanDist":	115,
  "MakeUFrame":	116,
  "SetAxis": 117,
  "SetAxisJ": 118,
  "ConvertPose": 119,

  "Label":    201,
  "Goto":	    202,
  "If":	      203,
  "ElseIf":	  204,
  "Else":	    205,
  "EndIf":	  206,
  "While":	  207,
  "EndWhile":	208,
  "Call":	    209,
  "Run":	    210,
  "Kill":	    211,
  "For":	    212,
  "EndFor":	  213,
  "WaitTime": 214,

  "WaitDI":	    301,
  "WaitAI":	    302,
  "WaitGI":	    303,
  "ReadDI":	    304,
  "ReadAI":	    305,
  "ReadGI":	    306,
  "SendDO":	    307,
  "SendAO":	    308,
  "SendPulse":	309,
  "SendGO":	    310,
  "SaveIO":	    311,
  "SendSO":     312,
  "WaitSI":     313,

  "ClockStart":	401,
  "ClockStop":	402,
  "ClockRead":	403,
  "ClockReset":	404,

  "Add":	            501,
  "Sub":	            502,
  "Mul":	            503,
  "Div":	            504,
  "Fmod":	            505,
  "Floor":	          506,
  "Random":          	507,
  "And":	            508,
  "Or":               509,
  "Neg":	            510,
  "Xor":	            511,
  "AND":	            512,
  "OR":               513,
  "NEG":	            514,
  "XOR":	            515,
  "Equal":	          516,
  "GreaterThan":	    517,
  "LessThan":	        518,
  "LessThanEqual":	  519,
  "GreaterThanEqual":	520,
  "Neq":	            521,

  "SocketCreate":	601,
  "SocketClose":	602,
  "SocketSend":	  603,
  "SocketRead":	  604
}
