let id = 0;

function createId(){
  return `node-${id++}`;
}

function RootNode(){
  return {
    id:createId(),
    type:"ROOT",
    name:"root",
    children:[],
    maxChildren:0,
  }
}

function BiggerNode(){
  return {
    id:createId(),
    type:">",
    children:[...arguments],
    maxChildren:2,
    name:"bigger",
  }
}

function SmallerNode(){
  return {
    id:createId(),
    type:"<",
    children:[...arguments],
    maxChildren:2,
    name:"smaller",
  }
}

function AddNode(){
  return {
    id:createId(),
    type:"+",
    children:[...arguments],
    maxChildren:2,
    name:"add",
  }
}

function SubNode(){
  return {
    id:createId(),
    type:"-",
    children:[...arguments],
    maxChildren:2,
    name:"sub",
  }
}

function MulNode(){
  return {
    id:createId(),
    type:"*",
    children:[...arguments],
    maxChildren:2,
    name:"mul",
  }
}

function ModNode(){
  return {
    id:createId(),
    type:"%",
    children:[...arguments],
    maxChildren:2,
    name:"mod",
  }
}

function DivNode(){
  return {
    id:createId(),
    type:"/",
    children:[...arguments],
    maxChildren:2,
    name:"div",
  }
}


function NegNode(){
  return {
    id:createId(),
    type:"NEGATE",
    children:[...arguments],
    maxChildren:1,
    name:"neg",
  }
}

function ParNode(){
  return {
    id:createId(),
    type:"(",
    name:"par",
    children:[],
    maxChildren:0,
  }
}

function NumberNode(){
  return {
    id:createId(),
    type:"NUMBER",
    children:[...arguments],
    maxChildren:1,
    name:"number",
  }
}

function IONode(){
  return {
    id:createId(),
    type:"IO",
    children:[...arguments],
    maxChildren:1,
    name:"io",
  }
}
function PointAxisNode() {
  return {
    id:createId(),
    type:"PointAxis",
    children:[...arguments],
    maxChildren:1,
    name:"PointAxis",
  }
}
function DegNode(){
  return {
    id:createId(),
    type:"@deg",
    children:[...arguments],
    maxChildren:1,
    name:"deg",
  }
}

function VecNode(){
  return {
    id:createId(),
    type:"[",
    name:"vec",
    children:[],
    maxChildren:0,
  }
}

function WallNode(){
  return {
    id:createId(),
    type:",",
    name:"wall",
    children:[],
    maxChildren:0,
  }
}
function AndNode(){
  return {
    id:createId(),
    type:"&",
    name:"and",
    children:[...arguments],
    maxChildren:2,
  }
}
function OrNode(){
  return {
    id:createId(),
    type:"|",
    name:"or",
    children:[...arguments],
    maxChildren:2,
  }
}
function XORNode(){
  return {
    id:createId(),
    type:"^",
    name:"xor",
    children:[...arguments],
    maxChildren:1,
  }
}

function VARNode() {
  return {
    id:createId(),
    type:"VAR",
    name:"var",
    children:[...arguments],
    maxChildren:1,
  }
}
function BOENode() {
  return {
    id:createId(),
    type:"@BOE",
    name:"biggerOrEqual",
    children:[...arguments],
    maxChildren:2,
  }
}
function SOENode() {
  return {
    id:createId(),
    type:"@SOE",
    name:"smallOrEqual",
    children:[...arguments],
    maxChildren:2,
  }
}
function EqualNode() {
  return {
    id:createId(),
    type:"@Equal",
    name:"equal",
    children:[...arguments],
    maxChildren:2,
  }
}
function NotEQLNode() {
  return {
    id:createId(),
    type:"@NotEQL",
    name:"notEqual",
    children:[...arguments],
    maxChildren:2,
  }
}
function LgAndNode(){
  return {
    id:createId(),
    type:"@and",
    name:"LgAnd",
    children:[...arguments],
    maxChildren:2,
  }
}
function LgOrNode(){
  return {
    id:createId(),
    type:"@or",
    name:"LgOr",
    children:[...arguments],
    maxChildren:2,
  }
}
function FloorNode(){
  return {
    id:createId(),
    type:"Floor",
    name:"floor",
    children:[],
    maxChildren:1,
  }
}
export function CreateTypeNode(type){
  if (type === "+") return AddNode;
  if (type === "-") return SubNode;
  if (type === "*") return MulNode;
  if (type === "/") return DivNode;
  if (type === ">") return BiggerNode;
  if (type === "<") return SmallerNode;
  if (type === "NEGATE") return NegNode;
  if (type === "NUMBER") return NumberNode;
  if (type === "@deg") return DegNode;
  if (type === ",") return WallNode;
  if (type === "[") return VecNode;
  if (type === "(") return ParNode;
  if (type === "ROOT") return RootNode;
  if (type === "&") return AndNode;
  if (type === "|") return OrNode;
  if (type === "^") return XORNode;
  if (type === "VAR") return VARNode;
  if (type === "@BOE") return BOENode;
  if (type === "@SOE") return SOENode;
  if (type === "@Equal") return EqualNode;
  if (type === "@NotEQL") return NotEQLNode;
  if (type === "@or") return LgOrNode;
  if (type === "@and") return LgAndNode;
  if (type === 'Floor') return FloorNode;
  if (type === '%') return ModNode;
  if (type === 'IO') return IONode;
  if (type === 'PointAxis') return PointAxisNode;
}

export const operatorValue = {
  "ROOT" : 0,
  "(" : 0.1,
  "@or": 0.4,
  "@and": 0.4,
  "@Equal": 0.45,
  "@NotEQL": 0.45,
  "[" : 1,
  "|": 1.5,
  "^": 1.6,
  "&": 1.7,
  "@dot" : 2,
  "<" : 3,
  ">" : 3,
  "@BOE": 3,
  "@SOE": 3,
  "+" : 4,
  "-" : 4,
  "*" : 5,
  "/" : 5,
  "%" : 5,
  "@rot" : 5,
  "@len" : 6, // 取模
  "@lens" : 6, // 取模平方
  "NEGATE" : 6, // 取负
  "@deg" : 7,
  "NUMBER" : 8, // 取正
  "IO": 8,
  "VAR": 8,
  "PointAxis": 8,
  "Floor": 8,
  ")" : 9,
  "]" : 9,
  "ROOT_END" : 10,
}

export const opposite = {
  "(" : ")" ,
  "[" : "]" ,
  "ROOT" : "ROOT_END" ,
}

export function isFullNode(node){
  if (isNoChildrenNode(node)) return false;
  return node && node.children && node.children.length >= node.maxChildren;
}

export function isNotFullNode(node){
  if (isNoChildrenNode(node)) return false;
  return node && node.children && node.children.length < node.maxChildren;
}

export function isNoChildrenNode(node){
  return node.maxChildren === 0;
}

export function typeValue(node){
  if (node === undefined) throw new Error("node is undefined");
  return operatorValue[node.type];
}