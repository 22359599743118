import {
  CreateTypeNode,
  operatorValue,
  opposite,
  isFullNode,
  isNotFullNode,
  isNoChildrenNode,
  typeValue
} from "./node.js";
import {IO_TYPE, OPERATORS, Project} from "@/model";

const EOF = Symbol('EOF');

class Lexer {
  constructor() {
    this.token = [];
    this.tokens = [];
    this.state = this.start;
    this.id = 0;
  }

  start(char) {
    // 数字
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(char)) {
      this.token.push(char);
      return this.inInt;
    }
    // .
    if (char === ".") {
      this.token.push(char);
      return this.inFloat;
    }
    // 符号
    if (["+", "-", "*", "/", "(", ")", "[", "]", ",", '>', '<', "|", "&", "^", "%"].includes(char)) {
      this.emitToken("SIGN", char);
      return this.start
    }
    // 空白字符
    if ([" ", "\r", "\n"].includes(char)) {
      return this.start;
    }
    // 介绍
    if (char === EOF) {
      this.emitToken("EOF", EOF);
      return this.start
    }
    if (char === "@" || char === '#') {
      this.token.push(char);
      return this.sign;
    }
    // 都没有匹配，按照变量处理
    this.token.push(char)
    return this.inVar
  }

  sign(char) {
    if ("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890_".split("").includes(char)) {
      this.token.push(char);
      return this.sign;
    } else {
      this.emitToken(this.token[0]=== "@" ? "SIGN" : 'IO', this.token.join(""));
      this.token = [];
      return this.start(char); // put back char
    }
  }

  inInt(char) {
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(char)) {
      this.token.push(char);
      return this.inInt;
    } else if (char === '.') {
      this.token.push(char);
      return this.inFloat;
    } else {
      this.emitToken("NUMBER", this.token.join(""));
      this.token = [];
      return this.start(char); // put back char
    }
  }

  inFloat(char) {
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(char)) {
      this.token.push(char);
      return this.inFloat;
    } else if (char === ".") {
      throw new Error("不能出现`..`");
    } else {
      if (this.token.length === 1 && this.token[0] === ".") throw new Error("不能单独出现`.`");
      this.emitToken("NUMBER", this.token.join(""));
      this.token = [];
      return this.start(char); // put back char
    }
  }

  inVar(char) {
    if (["+", "-", "*", "/", "(", ")", "[", "]", ",", ">", "<", "|", "&", "^", " ", "%"].includes(char) || char === EOF || char === '@') {
      let token = this.token.join("")
      if (token.indexOf("robot") !== -1 && token.indexOf("point") !== -1) {
        this.emitToken("PointAxis", token)
      } else {
        this.emitToken("VAR", token);
      }
      this.token = [];
      return this.start(char); // put back char
    } else {
      this.token.push(char)
      if (this.token.join('') === 'floor') {
        return this.inFloor
      }
      return this.inVar;
    }
  }

  inFloor(char) {
    this.emitToken("Floor", this.token.join(""));
    this.token = [];
    return this.start(char); // put back char
  }

  emitToken(type, value) {
    // console.log(value);
    this.tokens.push({
      id: this.id++,
      type,
      value,
    })
  }

  push(char) {
    this.state = this.state(char);
    return this.check();
  }

  end() {
    this.state(EOF);
    return this.check();
  }

  check() {
    // console.log(this.tokens);
    const _token = [...this.tokens];
    this.tokens = [];
    return _token;
  }

  clear() {
    this.token = [];
    this.tokens = [];
    this.state = this.start;
  }
}

// 完整的 Parser
class Parser {
  constructor() {
    this.stack = [CreateTypeNode("ROOT")()];
    this.tempStack = [];
    this.ParNodeSize = 0;
    this.VecNodeSize = 0;
  }

  push(token) {
    const top = this.stack[this.stack.length - 1];

    const rob = (type, children) => {
      const child = children.pop();
      this.stack.push(CreateTypeNode(type)(child));
    }

    const retire = (type) => {
      this.stack.push(CreateTypeNode(type)(this.stack.pop()));
    }

    const link = (type) => {
      const value = operatorValue[type];
      // console.log(stack[stack.length -2].type,stack[stack.length -1].type,type);
      // console.log(typeValue(stack[stack.length -2]),typeValue(stack[stack.length -1]),value);
      while (isFullNode(this.stack[this.stack.length - 1]) && isNotFullNode(this.stack[this.stack.length - 2]) && (value <= typeValue(this.stack[this.stack.length - 1])) && (value <= typeValue(this.stack[this.stack.length - 2]))) {
        // console.log(value);
        this.stack[this.stack.length - 2].children.push(this.stack.pop());
      }
    }

    const remove = (type) => {
      link(type);
      //  找到最近的( 其余push到tempStack
      while (this.stack.length > 0 && !(this.stack[this.stack.length - 1].type === type && this.stack[this.stack.length - 1].maxChildren === 0)) {
        this.tempStack.push(this.stack.pop());
      }
      // 修改最近的(
      const top = this.stack[this.stack.length - 1];
      if (top.type === type) {
        top.type = opposite[type];
        top.children = [];
        // tempStack的Node压给(
        while (this.tempStack.length > 0) {
          top.children.push(this.tempStack.pop());
        }
        top.maxChildren = top.children.length;
      }
    }

    const stackPush = (node) => {
      this.stack.push(node);
    }

    const topChildPush = (node) => {
      top.children.push(node);
    }

    if (token.type === "EOF") {
      // ( 1 + 2 EOF
      if (this.ParNodeSize > 0) throw new Error("还有没有闭合的(");
      if (this.VecNodeSize > 0) throw new Error("还有没有闭合的[");
      // EOF
      return remove("ROOT");
    }

    if (token.value === "[") {
      // 1[
      // 1 + 1 [
      if (isFullNode(top)) throw new Error("非顶端[前面不能有满项");
      this.VecNodeSize++;
      return stackPush(CreateTypeNode("[")());
    }

    if (token.value === ",") {
      // ,
      // ,,
      // (,
      // [,
      if (isNoChildrenNode(top)) throw new Error(",不能接在空符后面");
      // [ 1 + ,
      if (isNotFullNode(top)) throw new Error(",不能接在非满项后面");
      link("[")
      return stackPush(CreateTypeNode(",")());
    }

    if (token.value === "]") {
      // []]
      if (this.VecNodeSize <= 0) throw new Error("缺少匹配的[");
      // [1+]
      if (isNotFullNode(top)) throw new Error("]前不能有非满项");
      this.VecNodeSize--;
      return remove("[");
    }

    if (token.value === "(") {
      // 1(
      // 1 + 1 (
      console.log('top', top)
      if (isFullNode(top)) throw new Error("not a function");
      this.ParNodeSize++;
      // (
      return stackPush(CreateTypeNode("(")());
    }

    if (token.value === ")") {
      //  ())
      if (this.ParNodeSize <= 0) throw new Error("Unexpected token )");
      this.ParNodeSize--;
      // ()
      if (isNoChildrenNode(top)) throw new Error("Unexpected token )");
      // (1+)
      if (isNotFullNode(top)) throw new Error("Unexpected token )");
      return remove("(");
    }

    if (token.type === 'Floor') {
      if (
        (isNoChildrenNode(top)) || // (-
        (isNotFullNode(top)) // 1 + -
      ) {
        const number = CreateTypeNode(token.type)(token.value);
        return stackPush(number);
      }
      throw new Error("Floor 已满")
    }

    if (token.type === "SIGN") {
      // 后置符号
      if (isFullNode(top)) {
        if (operatorValue[token.value] > operatorValue[top.type]) {
          // 1 + 2 *
          // console.log("rob");
          return rob(token.value, top.children);
        } else {
          //  1 +
          //  1 + 2 +
          link(token.value);
          return retire(token.value);
        }
      }

      // 前置符号
      if (
        (isNoChildrenNode(top)) || // (-
        (isNotFullNode(top)) // 1 + -
      ) {
        if (token.value === "-") return stackPush(CreateTypeNode("NEGATE")()); // 取负公用符号 -
        if (token.value === '^') return stackPush(CreateTypeNode("^")())
        if (token.value === "+") return; // + 号静默
        throw new Error(token.value + "符号不能前置");
      }

    }

    if (token.type === "NUMBER" || token.type === "VAR" || token.type === "IO" || token.type === 'PointAxis') {
      //  1 1
      //  1 + 1 1d
      if (isFullNode(top)) throw new Error("数字前一项不能是满项")
      const number = CreateTypeNode(token.type)(token.value);
      if (isNotFullNode(top)) {
        return topChildPush(number);
      } else {
        return stackPush(number);
      }
    }


  }

  clear() {
    this.stack = [CreateTypeNode("ROOT")()];
    this.tempStack = [];
    this.ParNodeSize = 0;
    this.VecNodeSize = 0;
  }

  end() {
    return this.stack[0];
  }
}

const cmdName = {
  Add: "Add",
  Sub: "Sub",
  Mul: "Mul",
  Div: "Div",
  And: "And",
  Or: "Or",
  Xor: "Xor",
  Set: 'Set',
  If: "If",
  Else: "Else",
  EndIf: "EndIf",
  Floor: "Floor",
  Fmod: "Fmod",
  ReadDI: "ReadDI",
  ReadAI: "ReadAI",
  ReadGI: "ReadGI",
  GetPoseE: "GetPoseE"
}

function evaluate(node, tmpPool) {
  if (node == null) return null;
  const {type, children} = node;
  let cmds = [];
  if (type === "NUMBER") return {tmpVar: Number(children[0]), cmds};

  if (type === "VAR") return {tmpVar: children[0], cmds};

  if (type === "IO") {
    let tmpVar = tmpPool.getTmpVar()
    let [,type] = children[0].split("_")
    if (!type) {
      throw new Error(`io id is illegal ${children[0]}`)
    }
    let io = Project.current().curProgram.robot.code.getIOById(children[0])
    if (!io) {
      throw new Error(`can't find io ${children[0]}`)
    }
    switch (type) {
      case IO_TYPE.DI:
        cmds.push([cmdName.ReadDI, [tmpVar, io.name]])
        break;
      case IO_TYPE.AI:
        cmds.push([cmdName.ReadAI, [tmpVar, io.name]])
        break;
      case IO_TYPE.GI:
        cmds.push([cmdName.ReadGI, [tmpVar, io.name]])
        break;
    }
    return {tmpVar, cmds}
  }

  if (type === 'PointAxis') {
    let tmpVar = tmpPool.getTmpVar()
    let lastIndex = children[0].lastIndexOf("_")
    let axis = children[0].slice(lastIndex + 1, children[0].length)
    let pointId = children[0].slice(0, lastIndex)
    cmds.push([cmdName.GetPoseE, [tmpVar, pointId, axis]])
    return {tmpVar, cmds}
  }

  if (type === "ROOT_END") {
    let data = evaluate(children[0], tmpPool)
    return {tmpVar: data.tmpVar, cmds: cmds.concat(data.cmds)}
  }

  if (type === ")") {
    let data = evaluate(children[0], tmpPool)
    return {tmpVar: data.tmpVar, cmds: cmds.concat(data.cmds)}
  }

  const reduceChildren = (child0, child1) => {
    let a = child0 ? evaluate(child0, tmpPool) : null
    let b = child1 ? evaluate(child1, tmpPool) : null
    if (a && a.tmpVar != null && a.cmds) {
      cmds = cmds.concat(a.cmds)
      a = a.tmpVar
    }
    if (b && b.tmpVar != null && b.cmds) {
      cmds = cmds.concat(b.cmds)
      b = b.tmpVar
    }
    return [a, b]
  }
  const releaseVar = (...varName) => {
    for (let i = 0; i < varName.length; i++) {
      let flag = tmpPool.releaseVar(varName[i])
      if (flag) {
        cmds.push([cmdName.Set, [varName[i], 0]])
      }
    }
  }
  if (type === "+") {
    let [a, b] = reduceChildren(children[0], children[1])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.Add, [tmpVar, b]])
    releaseVar(a,b)
    return {tmpVar, cmds};
  }
  if (type === "-") {
    let [a, b] = reduceChildren(children[0], children[1])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.Sub, [tmpVar, b]])
    releaseVar(a,b)
    return {tmpVar, cmds};
  }
  if (type === '&') {
    let [a, b] = reduceChildren(children[0], children[1])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.And, [tmpVar, b]])
    releaseVar(a,b)
    return {tmpVar, cmds};
  }
  if (type === '|') {
    let [a, b] = reduceChildren(children[0], children[1])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.Or, [tmpVar, b]])
    releaseVar(a,b)
    return {tmpVar, cmds};
  }
  if (type === '^') {
    let [a] = reduceChildren(children[0])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Xor, [tmpVar, a]])
    releaseVar(a)
    return {tmpVar, cmds};
  }
  if (type === '%') {
    let [a, b] = reduceChildren(children[0], children[1])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.Fmod, [tmpVar, b]])
    releaseVar(a, b)
    return {tmpVar, cmds};
  }
  if ([">", "<", "@BOE", "@SOE", "@Equal", "@NotEQL", "@or", "@and"].includes(type)) {
    let [a, b] = reduceChildren(children[0], children[1])
    let judgeSentences
    if (type === ">") {
      judgeSentences = [a, OPERATORS.GT, b]
    }
    if (type === "<") {
      judgeSentences = [a, OPERATORS.LT, b]
    }
    if (type === "@BOE") {
      judgeSentences = [a, OPERATORS.GT, b, OPERATORS.OR, a, OPERATORS.EQ, b]
    }
    if (type === "@SOE") {
      judgeSentences = [a, OPERATORS.LT, b, OPERATORS.OR, a, OPERATORS.EQ, b]
    }
    if (type === "@Equal") {
      judgeSentences = [a, OPERATORS.EQ, b]
    }
    if (type === "@NotEQL") {
      judgeSentences = [a, OPERATORS.GT, b, OPERATORS.OR, a, OPERATORS.LT, b]
    }
    if (type === "@or") {
      judgeSentences = [a, OPERATORS.OR, b]
    }
    if (type === "@and") {
      judgeSentences = [a, OPERATORS.AND, b]
    }
    let judegeAry = []
    for (const item of judgeSentences) {
      if (item instanceof Array) {
        judegeAry.push(...item)
      } else {
        judegeAry.push(item)
      }
    }
    let tmpVar = tmpPool.getTmpVar();
    cmds.push([cmdName.Set, [tmpVar, 0]]);
    cmds.push([cmdName.If, judegeAry]);
    cmds.push([cmdName.Set, [tmpVar, 1]]);
    cmds.push([cmdName.EndIf])
    return {tmpVar: [tmpVar, OPERATORS.EQ, 1], cmds: cmds};
  }

  if (type === "Floor") {
    let [a] = reduceChildren(children[0])
    let tmpVar = tmpPool.getTmpVar()
    cmds.push([cmdName.Set, [tmpVar, a]])
    cmds.push([cmdName.Floor, [tmpVar]])
    releaseVar(a)
    return {tmpVar, cmds}
  }

  if (type === "*" || type === "/") {
    let [a, b] = reduceChildren(children[0], children[1])
    const isNumA = typeof a === "number" || typeof a === "string"; // 是数字和变量时
    const isNumB = typeof b === "number" || typeof b === "string";
    if (isNumA && isNumB) {
      let tmpVar = tmpPool.getTmpVar()
      cmds.push([cmdName.Set, [tmpVar, a]])
      if (type === "*") {
        cmds.push([cmdName.Mul, [tmpVar, b]])
        // return a * b;
      }
      if (type === "/") {
        cmds.push([cmdName.Div, [tmpVar, b]])
        // return a / b;
      }
      releaseVar(a,b)
      return {tmpVar, cmds};
    }
  }

  if (type === "NEGATE") {
    const a = evaluate(children[0]);
    let tmpVar = a.tmpVar * -1;
    return { tmpVar, cmds };
  }

  if (type === "@deg") {
    const a = evaluate(children[0]);
    let tmpVar = a.tmpVar;
    const isNumA = typeof tmpVar === "number";
    if (isNumA) {
      tmpVar = tmpVar / 180 * Math.PI;
      return { tmpVar, cmds };
    } else {
      throw new Error("非数字不能转换deg");
    }
  }

}

let index = 0

/**
 * value == true 为变量使用中
 */
class TmpVarPool {
  constructor() {
    this.tmpPool = new Map();
  }

  getTmpVar() {
    for (let item of this.tmpPool) {
      if (!item[1]) {
        this.tmpPool.set(item[0], true)
        return item[0];
      }
    }
    let tmp = `$temp${++index}`
    this.tmpPool.set(tmp, true)
    return tmp
  }

  releaseVar(varName) {
    let value = this.tmpPool.get(varName)
    if (!value) {
      return false
    }
    this.tmpPool.set(varName, false)
    return true
  }

  clear() {
    index = 0
    this.tmpPool.clear()
  }

  getTmpPoolVarNames() {
    return this.tmpPool.keys()
  }
}

export const tmpPool = new TmpVarPool()

/**
 * 对传入的字符进行ast转译， tmpPool:所有的临时变量， res:转译后的结果， cmds：生成转译结果需要的前置命令
 * @param str
 * @returns {{tempVars: *, params: [*], cmds: []}|{tempVars: *, params: (*[]|number|*), cmds: ([]|*[])}}
 */
export function astReduce(str) {
  let lexer = new Lexer()
  let tokens = [];
  if (typeof str !== "string" || str === '') {
    return {params: [str], cmds: [], tempVars: tmpPool.getTmpPoolVarNames()}
  }
  str = str
    .replace(getNoSpCharRegExp(OPERATORS.EQ, "g"), "@Equal ")
    .replace(getNoSpCharRegExp(OPERATORS.NEQ, "g"), "@NotEQL ")
    .replace(getNoSpCharRegExp(OPERATORS.GTE, "g"), "@BOE ")
    .replace(getNoSpCharRegExp(OPERATORS.LTE, "g"), "@SOE ")
    .replace(getNoSpCharRegExp(OPERATORS.OR, "g"), "@or ")
    .replace(getNoSpCharRegExp(OPERATORS.AND, "g"), "@and ")
    .replace(getNoSpCharRegExp(OPERATORS.LT, 'g'), '<')
    .replace(getNoSpCharRegExp(OPERATORS.GT, 'g'), '>')
    .replace(getNoSpCharRegExp(OPERATORS.XOR, 'g'), '^')
    .replace(getNoSpCharRegExp(OPERATORS.NOT, 'g'), '!')

  for (let c of str.split('')) {
    tokens = [...tokens, ...lexer.push(c)];
  }
  tokens = [...tokens, ...lexer.end()];
  let parer = new Parser()
  for (const token of tokens) {
    parer.push(token);
  }
  let stack = parer.stack
  let res = evaluate(stack[0], tmpPool)
  let params = res.tmpVar
  if (!(res.tmpVar instanceof Array)) {
    params = [res.tmpVar]
  }
  return {cmds: res.cmds, params, tempVars: tmpPool.getTmpPoolVarNames()}
}

function getNoSpCharRegExp(pattern, flags) {
  let escapedVar = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(escapedVar, flags)
}