/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        title: {},
        unvisibility: { type: Boolean, default: false }
    },
    emits: ['delConfirm'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const onDelBtnClk = () => {
            emit('delConfirm');
        };
        return (_ctx, _cache) => {
            const _component_Close = (0, vue_2.resolveComponent)("Close");
            const _component_el_icon = (0, vue_2.resolveComponent)("el-icon");
            const _component_el_popconfirm = (0, vue_2.resolveComponent)("el-popconfirm");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)([{ 'un-visibility': _ctx.unvisibility }, "del-wrap"])
            }, [
                (0, vue_2.createVNode)(_component_el_popconfirm, {
                    title: _ctx.title,
                    onConfirm: onDelBtnClk,
                    width: "200"
                }, {
                    reference: (0, vue_2.withCtx)(() => [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default", {}, () => [
                            (0, vue_2.createVNode)(_component_el_icon, { class: "del-icon" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_Close, { style: { "height": "15px", "width": "15px" } })
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _: 3
                }, 8, ["title"])
            ], 2));
        };
    }
});
