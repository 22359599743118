"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
const tslib_1 = require("tslib");
const bbl = tslib_1.__importStar(require("@babylonjs/core"));
const core_1 = require("@babylonjs/core");
const MATERIAL = tslib_1.__importStar(require("@babylonjs/materials"));
const GUI = tslib_1.__importStar(require("@babylonjs/gui"));
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const async_1 = require("@/utils/async");
const tween_js_1 = require("@tweenjs/tween.js");
class Utils {
    static createGround(scene) {
        let groundMaterial = new bbl.StandardMaterial("groundMaterial", scene);
        groundMaterial.diffuseTexture = new bbl.Texture("/static/texture/ground.jpg", scene);
        let ground = bbl.Mesh.CreateGround("ground", 512, 512, 32, scene, false);
        ground.position.y = -1;
        ground.material = groundMaterial;
        return ground;
    }
    static createWater(scene) {
        let waterMesh = bbl.Mesh.CreateGround("waterMesh", 512, 512, 32, scene, false);
        let waterMaterial = Utils.createWaterMaterial("water", "/static/texture/waterbump.png", scene);
        waterMesh.material = waterMaterial;
        waterMesh.position.y = 4;
        return waterMaterial;
    }
    static createGUI() {
        return GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
    }
    static createButtonSwim(guiTexture, btnText, btnClicked) {
        let btnTest = GUI.Button.CreateSimpleButton("but1", btnText);
        btnTest.width = "150px";
        btnTest.height = "40px";
        btnTest.color = "white";
        btnTest.background = "grey";
        btnTest.onPointerUpObservable.add(() => {
            if (btnClicked) {
                btnClicked(btnTest);
            }
        });
        btnTest.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        btnTest.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        btnTest.left = 12;
        btnTest.top = 12;
        guiTexture.addControl(btnTest);
    }
    static createVerticalLine(scene, position) {
        var myPoints = [
            new bbl.Vector3(position.x, 0, position.y),
            new bbl.Vector3(position.x, 100, position.y),
        ];
        var lines = bbl.MeshBuilder.CreateLines("lines", { points: myPoints }, scene);
    }
    static createCoordinatesText(guiTexture) {
        let txtX = new GUI.TextBlock();
        txtX.height = "20px";
        txtX.width = "500px";
        txtX.fontSize = 20;
        txtX.text = "X: ";
        txtX.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtX.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtX.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtX.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtX.left = 20;
        txtX.top = 60;
        let txtY = new GUI.TextBlock();
        txtY.height = "20px";
        txtY.width = "500px";
        txtY.fontSize = 20;
        txtY.text = "Y: ";
        txtY.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtY.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtY.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtY.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtY.left = 20;
        txtY.top = 90;
        let txtZ = new GUI.TextBlock();
        txtZ.height = "20px";
        txtZ.width = "500px";
        txtZ.fontSize = 20;
        txtZ.text = "Z: ";
        txtZ.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtZ.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtZ.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        txtZ.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        txtZ.left = 20;
        txtZ.top = 120;
        guiTexture.addControl(txtX);
        guiTexture.addControl(txtY);
        guiTexture.addControl(txtZ);
        return {
            txtX: txtX,
            txtY: txtY,
            txtZ: txtZ
        };
    }
    static createMeshFromObjFile(filePath, scene, scaling, position, rotationQuaternion) {
        if (!scene) {
            return new rxjs_1.Observable((subscriber) => subscriber.error("GameUtils.createMeshFromObjFile: parameter scene is empty"));
        }
        if (!filePath)
            filePath = "";
        if (!scaling)
            scaling = bbl.Vector3.One();
        if (!position)
            position = bbl.Vector3.Zero();
        if (!rotationQuaternion)
            rotationQuaternion = bbl.Quaternion.RotationYawPitchRoll(0, 0, 0);
        return new rxjs_1.Observable((observer) => {
            bbl.SceneLoader.ImportMesh(null, filePath, '', scene, (meshes, particleSystems, skeletons) => {
                meshes.forEach((mesh) => {
                    mesh.position = position;
                    mesh.rotationQuaternion = rotationQuaternion;
                    mesh.scaling = scaling;
                });
                observer.next(meshes);
                observer.complete();
            }, () => {
            }, (scene, errorMessage, exception) => {
                console.log(errorMessage);
                console.error(exception);
            });
        });
    }
    static createSkybox(name, fileName, scene) {
        if (!name) {
            console.error("GameUtils.createSkyBox: name is not defined");
            return;
        }
        if (!fileName) {
            console.error("GameUtils.createSkyBox: fileName is not defined");
            return;
        }
        if (!scene) {
            console.error("GameUtils.createSkyBox: scene is not defined");
            return;
        }
        let skybox = bbl.Mesh.CreateBox(name, 1000.0, scene);
        let skyboxMaterial = new bbl.StandardMaterial(name, scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.reflectionTexture = new bbl.CubeTexture("/static/texture/skybox/TropicalSunnyDay", scene);
        skyboxMaterial.reflectionTexture.coordinatesMode = bbl.Texture.SKYBOX_MODE;
        skyboxMaterial.diffuseColor = new bbl.Color3(0, 0, 0);
        skyboxMaterial.specularColor = new bbl.Color3(0, 0, 0);
        skyboxMaterial.disableLighting = true;
        skybox.material = skyboxMaterial;
        return skybox;
    }
    static createWaterMaterial(name, noiseFile, scene) {
        if (!name) {
            console.error("GameUtils.createWaterMaterial: name is not defined");
            return;
        }
        if (!noiseFile) {
            console.error("GameUtils.createWaterMaterial: noiseFile is not defined");
            return;
        }
        if (!scene) {
            console.error("GameUtils.createWaterMaterial: scene is not defined");
            return;
        }
        let water = new MATERIAL.WaterMaterial(name, scene);
        water.bumpTexture = new bbl.Texture(noiseFile, scene);
        water.windForce = -15;
        water.waveHeight = 0;
        water.windDirection = new bbl.Vector2(1, 1);
        water.waterColor = new bbl.Color3(0.25, 0.88, 0.82);
        water.colorBlendFactor = 0.3;
        water.bumpHeight = 0.1;
        water.waveLength = 0.1;
        return water;
    }
    static createGridPlane(scene) {
        var scale = 0.01;
        const padSide = 10000;
        const groundSide = scale * 1000 * padSide;
        var ground = bbl.Mesh.CreateGround("ground", padSide * scale, padSide * scale, 1, scene, true);
        var groundMaterial = new MATERIAL.GridMaterial("grid", scene);
        groundMaterial.mainColor = bbl.Color3.Gray();
        groundMaterial.alpha = 1;
        const gridLineGray = 0.7;
        groundMaterial.lineColor = new bbl.Color3(gridLineGray, gridLineGray, gridLineGray);
        groundMaterial.backFaceCulling = true;
        groundMaterial.majorUnitFrequency = 1;
        groundMaterial.minorUnitVisibility = 0;
        groundMaterial.freeze();
        ground.material = groundMaterial;
        return ground;
    }
    static loadStatic(rootUrl, filename, scene, color, scaling, position, useCache = true) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let key = rootUrl + filename;
            if (!scaling)
                scaling = bbl.Vector3.One();
            if (!position)
                position = bbl.Vector3.Zero();
            let rootMesh = bbl.MeshBuilder.CreateBox("meshes", { size: 1 }, scene);
            rootMesh.isVisible = false;
            rootMesh.scaling = scaling;
            rootMesh.position = position;
            let cache = this.mesh_cache.get(key);
            if (!this.mesh_cache.has(key)) {
                this.mesh_cache.set(key, { loading: true });
            }
            yield async_1.Async.waitTill(() => !cache || cache.mesh != null);
            let meshes;
            if ((cache = this.mesh_cache.get(key)) && cache.mesh) {
                let mesh = cache.mesh.clone();
                cache.time = Date.now();
                meshes = mesh.getChildren();
            }
            else {
                meshes = yield new Promise((resolve, reject) => {
                    bbl.SceneLoader.ImportMesh(null, rootUrl, filename, scene, (meshes, particleSystems, skeletons) => {
                        resolve(meshes);
                    }, () => {
                    }, (scene, errorMessage, exception) => {
                        resolve(null);
                        console.log(errorMessage);
                        console.error(exception);
                        cache.loading = false;
                        reject(errorMessage);
                    });
                });
            }
            meshes.forEach((mesh) => {
                let red = new bbl.StandardMaterial("color", scene);
                red.diffuseColor = color;
                mesh.material = red;
                mesh.parent = rootMesh;
            });
            cache.loading = false;
            cache.mesh = rootMesh;
            cache.time = Date.now();
            this.mesh_cache.set(key, cache);
            return Promise.resolve(rootMesh.clone());
        });
    }
    static createMachine(scene, filePath, root) {
        let rootMesh = bbl.MeshBuilder.CreateBox("meshes", { size: 1 }, scene);
        rootMesh.isVisible = false;
        return Utils.createMeshFromObjFile(filePath, scene, new bbl.Vector3(0.01, 0.01, 0.01))
            .pipe((0, operators_1.map)(meshes => {
            meshes.forEach((mesh) => {
                mesh.parent = rootMesh;
            });
            return rootMesh;
        }));
    }
    static createAxis(size, scene, camera) {
        var makeTextPlane = function (text, color, size) {
            const dynamicTexture = new bbl.DynamicTexture("DynamicTexture", 50, scene, true);
            dynamicTexture.hasAlpha = true;
            dynamicTexture.drawText(text, 5, 40, "20px Arial", color, "transparent", true);
            const plane = bbl.Mesh.CreatePlane("TextPlane", size, scene, true);
            let material = plane.material = new bbl.StandardMaterial("TextPlaneMaterial", scene);
            plane.material.backFaceCulling = false;
            material.specularColor = new bbl.Color3(0, 0, 0);
            material.diffuseTexture = dynamicTexture;
            return plane;
        };
        const axisX = bbl.MeshBuilder.CreateLines("axisX", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(size, 0, 0), new bbl.Vector3(size * 0.97, 0.02 * size, 0),
                new bbl.Vector3(size, 0, 0), new bbl.Vector3(size * 0.97, -0.02 * size, 0)
            ]
        }, scene);
        axisX.color = new bbl.Color3(1, 0, 0);
        const xChar = makeTextPlane("X", "red", size / 10);
        xChar.position = new bbl.Vector3(0.9 * size, 0.05 * size, 0);
        xChar.rotation = new bbl.Vector3(-Math.PI / 2, 0, 0);
        const axisY = bbl.MeshBuilder.CreateLines("axisY", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(0, 0, -size), new bbl.Vector3(0, -0.02 * size, -size * 0.97),
                new bbl.Vector3(0, 0, -size), new bbl.Vector3(0, 0.02 * size, -size * 0.97)
            ]
        }, scene);
        axisY.color = new bbl.Color3(0, 1, 0);
        const yChar = makeTextPlane("Y", "green", size / 10);
        yChar.position = new bbl.Vector3(0, 0.05 * size, -0.9 * size);
        yChar.rotation = new bbl.Vector3(-Math.PI / 2, 0, Math.PI / 2);
        const axisZ = bbl.MeshBuilder.CreateLines("axisZ", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(0, size, 0), new bbl.Vector3(-0.02 * size, size * 0.97, 0),
                new bbl.Vector3(0, size, 0), new bbl.Vector3(0.02 * size, size * 0.97, 0)
            ]
        }, scene);
        axisZ.color = new bbl.Color3(0, 0, 1);
        const zChar = makeTextPlane("Z", "blue", size / 10);
        zChar.position = new bbl.Vector3(0, 0.9 * size, 0.05 * size);
    }
    static localAxes(size, scene) {
        var pilot_local_axisX = bbl.MeshBuilder.CreateLines("pilot_local_axisX", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(size, 0, 0), new bbl.Vector3(size * 0.95, 0.05 * size, 0),
                new bbl.Vector3(size, 0, 0), new bbl.Vector3(size * 0.95, -0.05 * size, 0)
            ]
        }, scene);
        pilot_local_axisX.color = new bbl.Color3(1, 0, 0);
        var pilot_local_axisY = bbl.MeshBuilder.CreateLines("pilot_local_axisY", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(0, size, 0), new bbl.Vector3(-0.05 * size, size * 0.95, 0),
                new bbl.Vector3(0, size, 0), new bbl.Vector3(0.05 * size, size * 0.95, 0)
            ]
        }, scene);
        pilot_local_axisY.color = new bbl.Color3(0, 1, 0);
        var pilot_local_axisZ = bbl.MeshBuilder.CreateLines("pilot_local_axisZ", {
            points: [
                bbl.Vector3.Zero(), new bbl.Vector3(0, 0, size), new bbl.Vector3(0, -0.05 * size, size * 0.95),
                new bbl.Vector3(0, 0, size), new bbl.Vector3(0, 0.05 * size, size * 0.95)
            ]
        }, scene);
        pilot_local_axisZ.color = new bbl.Color3(0, 0, 1);
        var local_origin = bbl.MeshBuilder.CreateBox("local_origin", { size: 1 }, scene);
        local_origin.isVisible = false;
        pilot_local_axisX.parent = local_origin;
        pilot_local_axisY.parent = local_origin;
        pilot_local_axisZ.parent = local_origin;
        return local_origin;
    }
    static createOrGetChild(parentMesh, name, scene, id, box = true, isVisible = false) {
        let children = parentMesh.getChildMeshes(true, (mesh) => mesh.name == name || mesh.id == id);
        if (children.length > 0) {
            let mesh = children[0];
            mesh.isVisible = isVisible;
        }
        return Utils.createChild(parentMesh, name, scene, id, box, isVisible);
    }
    static createChild(parentMesh, name, scene, id, box = true, isVisible = false, scale = 1) {
        let childMesh = box
            ? bbl.MeshBuilder.CreateBox(name, { size: scale }, scene)
            : bbl.MeshBuilder.CreateSphere(name, { diameter: 5 / 10 * scale }, scene);
        childMesh.parent = parentMesh;
        childMesh.isVisible = isVisible;
        if (id)
            childMesh.id = id;
        return childMesh;
    }
    static createParent(childMesh, name, scene, id, useBox = true, isVisible = false, scale = 1) {
        let parentMesh = useBox
            ? bbl.MeshBuilder.CreateBox(name, { size: scale }, scene)
            : bbl.MeshBuilder.CreateSphere(name, { diameter: 5 / 10 * scale }, scene);
        childMesh.parent = parentMesh;
        childMesh.isVisible = isVisible;
        if (id)
            parentMesh.id = id;
        return parentMesh;
    }
    static printMesh(target) {
        function mapChild(mesh) {
            let children = mesh.getChildMeshes(true, (mesh) => !!mesh.name && mesh.name != 'Mesh');
            if (children.length) {
                return children.map((child) => mapChild(child));
            }
            else {
                return {
                    id: mesh.id,
                    name: mesh.name,
                    position: mesh.position,
                    rotation: mesh.rotation,
                    pivot: mesh.getPivotPoint()
                };
            }
        }
        console.log(JSON.parse(JSON.stringify({ mesh: mapChild(target) })));
    }
    static worldToLocal(local, point) {
        const matrix = local.getWorldMatrix().clone().invert();
        return core_1.Vector3.TransformCoordinates(point, matrix);
    }
    static localToWorld(local, point) {
        const matrix = local.getWorldMatrix();
        return core_1.Vector3.TransformCoordinates(point, matrix);
    }
    static localToLocal(source, target, position) {
        return Utils.worldToLocal(target, Utils.localToWorld(source.parent, position || source.position));
    }
    static lookAt(newTarget, radius, camera) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let target = camera['_getTargetPosition']();
            new Promise((resolve, reject) => {
                new tween_js_1.Tween(target)
                    .to({ x: newTarget.x, y: newTarget.y, z: newTarget.z }, 600)
                    .easing(tween_js_1.Easing.Exponential.InOut)
                    .onUpdate((target) => {
                    camera.setTarget(target, true);
                })
                    .onComplete(() => {
                    resolve();
                })
                    .start();
                new tween_js_1.Tween({ radius: camera.radius })
                    .to({ radius }, 600)
                    .easing(tween_js_1.Easing.Exponential.InOut)
                    .onUpdate((target) => {
                    camera.radius = target.radius;
                })
                    .start();
            });
        });
    }
    static drawLine(parentNode, points, scene, color = bbl.Color3.Red()) {
        let ball = bbl.MeshBuilder.CreateSphere('', { diameter: 2 / 10 }, scene);
        let mat = new bbl.StandardMaterial("color", scene);
        mat.diffuseColor = color;
        ball.material = mat;
        ball.parent = parentNode;
        ball.position = points[0];
        let line = bbl.MeshBuilder.CreateLines('', {
            points,
            colors: [color.toColor4(), color.toColor4()]
        }, scene);
        line.parent = parentNode;
    }
    static markAxis(parentMesh, pivot, point, scene) {
        let local_pivot = Utils.worldToLocal(parentMesh, pivot);
        let z_axis = bbl.MeshBuilder.CreateBox('z_axis', { size: 2 / 10 }, scene);
        let z_axis_mat = new bbl.StandardMaterial("z_axis_mat", scene);
        z_axis_mat.diffuseColor = bbl.Color3.Red();
        z_axis.material = z_axis_mat;
        z_axis.parent = parentMesh;
        z_axis.position = local_pivot;
        let local_point = Utils.worldToLocal(parentMesh, point);
        let z_point = bbl.MeshBuilder.CreateSphere('z_point', { diameter: 2 / 10 }, scene);
        let z_point_mat = new bbl.StandardMaterial("z_point_mat", scene);
        z_point_mat.diffuseColor = bbl.Color3.Red();
        z_point.material = z_point_mat;
        z_point.parent = parentMesh;
        z_point.position = local_point;
        let z_line = bbl.MeshBuilder.CreateLines("z_line", { points: [pivot, point] }, scene);
        z_line.color = bbl.Color3.Red();
        z_line.parent = parentMesh;
    }
    static getAxis(parentMesh, scene) {
        let [z_axis] = parentMesh.getChildMeshes(false, mesh => mesh.name == 'z_axis');
        let pivot = z_axis.position;
        let [z_point] = parentMesh.getChildMeshes(false, mesh => mesh.name == 'z_point');
        let point = z_point.position;
        return { pivot, point };
    }
    static vueToStage(point = core_1.Vector3.Zero()) {
        return new core_1.Vector3(point.x / 100, point.y / 100, point.z / 100);
    }
    static stageToVue(point = core_1.Vector3.Zero()) {
        return new core_1.Vector3(Math.round(point.x * 100 * 100) / 100, Math.round(point.y * 100 * 100) / 100, Math.round(point.z * 100 * 100) / 100);
    }
    static angleToRad(x, y = 0, z = 0) {
        return new core_1.Vector3(x / 180 * Math.PI, y / 180 * Math.PI, z / 180 * Math.PI);
    }
    static radToAngle(x, y = 0, z = 0) {
        return new core_1.Vector3(Math.round(x / Math.PI * 180 * 10000) / 10000, Math.round(y / Math.PI * 180 * 10000) / 10000, Math.round(z / Math.PI * 180 * 10000) / 10000);
    }
}
exports.Utils = Utils;
Utils.mesh_cache = new Map();
