/* unplugin-vue-components disabled */const { ref } = require('vue');
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-98db4524"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { style: { "display": "flex", "gap": "5px", "justify-content": "center", "align-items": "center" } };
const _hoisted_2 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "生成方式", -1));
const _hoisted_3 = {
    key: 0,
    class: "create-path-wrap"
};
const _hoisted_4 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 线或点 ", -1));
const _hoisted_5 = {
    key: 1,
    class: "create-path-wrap"
};
const _hoisted_6 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 面 ", -1));
const _hoisted_7 = {
    key: 2,
    class: "create-path-wrap"
};
const _hoisted_8 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 面 ", -1));
const _hoisted_9 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 环上的线 ", -1));
const _hoisted_10 = {
    key: 3,
    class: "create-path-wrap"
};
const _hoisted_11 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 投射曲面 ", -1));
const _hoisted_12 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 线图 ", -1));
const _hoisted_13 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 投射中点 ", -1));
const _hoisted_14 = { key: 0 };
const _hoisted_15 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 轨迹方向与坐标系重合 ", -1));
const _hoisted_16 = [
    _hoisted_15
];
const _hoisted_17 = { key: 1 };
const _hoisted_18 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 轨迹方向与外环一致 ", -1));
const _hoisted_19 = [
    _hoisted_18
];
const _hoisted_20 = { style: { "display": "flex", "gap": "5px" } };
const _hoisted_21 = { style: { "display": "flex", "align-items": "center", "gap": "5px" } };
const _hoisted_22 = _withScopeId(() => (0, vue_2.createElementVNode)("span", null, "轨迹上的点", -1));
const _hoisted_23 = { key: 0 };
const _hoisted_24 = { key: 1 };
const _hoisted_25 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyle" }, " 有问题的轨迹 ", -1));
const _hoisted_26 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyleMini" }, " 状态 ", -1));
const _hoisted_27 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyleMini" }, " 点 ", -1));
const _hoisted_28 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyleMini" }, " 运动方式 ", -1));
const _hoisted_29 = _withScopeId(() => (0, vue_2.createElementVNode)("div", { class: "textStyleMini" }, " 速度 ", -1));
const _hoisted_30 = { class: "unchangedTxt" };
const _hoisted_31 = { class: "unchangedTxt" };
const _hoisted_32 = { class: "unchangedTxt" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'ManualPathPanel',
    setup(__props) {
        const genTypeOptions = [
            {
                value: 'lineOrPoint',
                label: '线或点'
            }, {
                value: 'curveFeature',
                label: '面的外环'
            }, {
                value: 'faceOutRing',
                label: '一个面的一个环'
            }, {
                value: 'aRingOfFace',
                label: '曲线特征'
            }
        ];
        const genType = ref(genTypeOptions[0].value);
        const radioAxis = ref();
        const isReverseX = ref();
        const startCoordName = ref();
        return (_ctx, _cache) => {
            const _component_el_option = (0, vue_2.resolveComponent)("el-option");
            const _component_el_select = (0, vue_2.resolveComponent)("el-select");
            const _component_el_row = (0, vue_2.resolveComponent)("el-row");
            const _component_el_input = (0, vue_2.resolveComponent)("el-input");
            const _component_el_radio = (0, vue_2.resolveComponent)("el-radio");
            const _component_el_radio_group = (0, vue_2.resolveComponent)("el-radio-group");
            const _component_el_checkbox = (0, vue_2.resolveComponent)("el-checkbox");
            const _component_el_col = (0, vue_2.resolveComponent)("el-col");
            const _component_CloseBold = (0, vue_2.resolveComponent)("CloseBold");
            const _component_el_icon = (0, vue_2.resolveComponent)("el-icon");
            const _component_el_button = (0, vue_2.resolveComponent)("el-button");
            const _component_el_scrollbar = (0, vue_2.resolveComponent)("el-scrollbar");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("div", null, [
                    (0, vue_2.createElementVNode)("div", _hoisted_1, [
                        _hoisted_2,
                        (0, vue_2.createVNode)(_component_el_select, {
                            modelValue: (0, vue_2.unref)(genType),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((0, vue_2.isRef)(genType) ? (genType).value = $event : null)),
                            placeholder: "选择生成方式"
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(genTypeOptions, (item) => {
                                    return (0, vue_2.createVNode)(_component_el_option, {
                                        key: item.value,
                                        label: item.label,
                                        value: item.value
                                    }, null, 8, ["label", "value"]);
                                }), 64))
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    ((0, vue_2.unref)(genType) == 'lineOrPoint')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_4
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中线或点名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        autosize: { minRows: 6, maxRows: 8 },
                                        type: "textarea",
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(genType) == 'faceOutRing')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, [
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_6
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中面名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(genType) == 'aRingOfFace')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7, [
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_8
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, {
                                align: "middle",
                                style: { "padding-bottom": "25px" }
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中面名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_9
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中线名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(genType) == 'curveFeature')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_10, [
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_11
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, {
                                align: "middle",
                                style: { "padding-bottom": "25px" }
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中面名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_12
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, {
                                align: "middle",
                                style: { "padding-bottom": "25px" }
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中线名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    _hoisted_13
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)(_component_el_input, {
                                        placeholder: "显示选中面名称",
                                        modelValue: (0, vue_2.unref)(startCoordName),
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                        class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                    }, null, 8, ["modelValue", "class"])
                                ]),
                                _: 1
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.createVNode)(_component_el_row, {
                        align: "middle",
                        class: "create-path-wrap"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            ((0, vue_2.unref)(genType) == 'lineOrPoint' || (0, vue_2.unref)(genType) == 'faceOutRing' || (0, vue_2.unref)(genType) == 'aRingOfFace')
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_14, _hoisted_16))
                                : (0, vue_2.createCommentVNode)("", true),
                            ((0, vue_2.unref)(genType) == 'curveFeature')
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_17, _hoisted_19))
                                : (0, vue_2.createCommentVNode)("", true)
                        ]),
                        _: 1
                    }),
                    (0, vue_2.createElementVNode)("div", _hoisted_20, [
                        (0, vue_2.createVNode)(_component_el_radio_group, {
                            modelValue: (0, vue_2.unref)(radioAxis),
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((0, vue_2.isRef)(radioAxis) ? (radioAxis).value = $event : null)),
                            class: "myRadio",
                            "text-color": "#ffffff"
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_radio, {
                                    onChange: _ctx.handleChangeRadioX,
                                    label: "1",
                                    size: "large"
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("X轴")
                                    ]),
                                    _: 1
                                }, 8, ["onChange"]),
                                (0, vue_2.createVNode)(_component_el_radio, {
                                    onChange: _ctx.handleChangeRadioY,
                                    label: "2",
                                    size: "large"
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("Y轴")
                                    ]),
                                    _: 1
                                }, 8, ["onChange"]),
                                (0, vue_2.createVNode)(_component_el_radio, {
                                    onChange: _ctx.handleChangeRadioZ,
                                    label: "3",
                                    size: "large"
                                }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("Z轴")
                                    ]),
                                    _: 1
                                }, 8, ["onChange"])
                            ]),
                            _: 1
                        }, 8, ["modelValue"]),
                        (0, vue_2.createVNode)(_component_el_checkbox, {
                            modelValue: (0, vue_2.unref)(isReverseX),
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((0, vue_2.isRef)(isReverseX) ? (isReverseX).value = $event : null)),
                            label: "反转",
                            size: "large",
                            class: "myRedCheckBox",
                            disabled: _ctx.isDisableAsixX
                        }, null, 8, ["modelValue", "disabled"])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_21, [
                        _hoisted_22,
                        (0, vue_2.createVNode)(_component_el_input, {
                            placeholder: "填写点的数量",
                            modelValue: (0, vue_2.unref)(startCoordName),
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                            style: { "width": "150px" }
                        }, null, 8, ["modelValue"])
                    ])
                ]),
                (_ctx.curTabIdx == 1)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_23, [
                        (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 2 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("No.")
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 4 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("名称")
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 10 }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 6 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        (0, vue_2.createTextVNode)("删除")
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_scrollbar, { style: { "height": "170px" } }, {
                            default: (0, vue_2.withCtx)(() => [
                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.programList, (item, index) => {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: index }, [
                                        (0, vue_2.createVNode)(_component_el_row, {
                                            align: "middle",
                                            style: { "margin-top": "5px", "margin-bottom": "10px" }
                                        }, {
                                            default: (0, vue_2.withCtx)(() => [
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(index + 1), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 3 }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 13 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createVNode)(_component_el_input, {
                                                            placeholder: "未命名轨迹",
                                                            modelValue: (0, vue_2.unref)(startCoordName),
                                                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                                            class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                                        }, null, 8, ["modelValue", "class"])
                                                    ]),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 3 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                            color: "#9798DD",
                                                            type: "primary",
                                                            id: "deleteBtn",
                                                            onClick: _ctx.onClickParaSettingNo
                                                        }, {
                                                            default: (0, vue_2.withCtx)(() => [
                                                                (0, vue_2.createVNode)(_component_el_icon, null, {
                                                                    default: (0, vue_2.withCtx)(() => [
                                                                        (0, vue_2.createVNode)(_component_CloseBold)
                                                                    ]),
                                                                    _: 1
                                                                })
                                                            ]),
                                                            _: 1
                                                        }, 8, ["onClick"])
                                                    ]),
                                                    _: 1
                                                })
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]));
                                }), 128))
                            ]),
                            _: 1
                        })
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (_ctx.curTabIdx == 2)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_24, [
                        (0, vue_2.createVNode)(_component_el_row, { align: "middle" }, {
                            default: (0, vue_2.withCtx)(() => [
                                _hoisted_25
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_row, {
                            align: "middle",
                            style: { "padding-bottom": "25px" }
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_input, {
                                    placeholder: "显示问题轨迹名称",
                                    modelValue: (0, vue_2.unref)(startCoordName),
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((0, vue_2.isRef)(startCoordName) ? (startCoordName).value = $event : null)),
                                    autosize: { minRows: 6, maxRows: 8 },
                                    type: "textarea",
                                    class: (0, vue_2.normalizeClass)((0, vue_2.unref)(startCoordName) == '' ? 'myInputLock' : 'myInputOn')
                                }, null, 8, ["modelValue", "class"])
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_row, {
                            align: "middle",
                            style: { "padding-bottom": "20px" }
                        }, {
                            default: (0, vue_2.withCtx)(() => [
                                (0, vue_2.createVNode)(_component_el_col, { span: 3 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        _hoisted_26
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 4 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        _hoisted_27
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 3 }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 6 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        _hoisted_28
                                    ]),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 2 }),
                                (0, vue_2.createVNode)(_component_el_col, { span: 6 }, {
                                    default: (0, vue_2.withCtx)(() => [
                                        _hoisted_29
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_scrollbar, { style: { "height": "170px" } }, {
                            default: (0, vue_2.withCtx)(() => [
                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.programList, (item, index) => {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: index }, [
                                        (0, vue_2.createVNode)(_component_el_row, {
                                            align: "middle",
                                            style: { "margin-top": "0px", "margin-bottom": "0px" }
                                        }, {
                                            default: (0, vue_2.withCtx)(() => [
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createVNode)(_component_el_checkbox, {
                                                            disabled: _ctx.isDisableReverse,
                                                            modelValue: _ctx.isReverseAxis1,
                                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event) => ((_ctx.isReverseAxis1) = $event)),
                                                            label: "",
                                                            size: "large",
                                                            class: "myRedCheckBox"
                                                        }, null, 8, ["disabled", "modelValue"])
                                                    ]),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 7 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_30, " 程序_00" + (0, vue_2.toDisplayString)(item), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 7 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_31, " 程序_00" + (0, vue_2.toDisplayString)(item), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 1 }),
                                                (0, vue_2.createVNode)(_component_el_col, { span: 6 }, {
                                                    default: (0, vue_2.withCtx)(() => [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_32, " 程序_00" + (0, vue_2.toDisplayString)(item), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]));
                                }), 128))
                            ]),
                            _: 1
                        })
                    ]))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
