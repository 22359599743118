/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const update_check_1 = require("@/service/update_check");
const element_plus_1 = require("element-plus");
exports.default = (0, vue_1.defineComponent)({
    __name: 'App',
    setup(__props) {
        let isShowingNotification = false;
        const updateCheckCallback = (hasUpdate) => {
            console.log("hasUpdate", hasUpdate);
            if (hasUpdate && !isShowingNotification) {
                element_plus_1.ElNotification.success({
                    message: 'IDE 有更新，请重启应用！',
                    offset: 0,
                    customClass: 'update-notification animate__animated animate__bounceIn animate__faster',
                    duration: 0,
                    onClose: () => isShowingNotification = false
                });
                isShowingNotification = true;
            }
        };
        update_check_1.AutoCheckUpdate.current().startAutoCheckUpdate(updateCheckCallback, 1000 * 60 * 10);
        return (_ctx, _cache) => {
            const _component_router_view = (0, vue_2.resolveComponent)("router-view");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_router_view));
        };
    }
});
