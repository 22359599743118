"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
class Utils {
    static pinyin(name, sp = '_') {
        return name;
    }
    static isIPad() {
        return !!window['flutter_inappwebview'];
    }
    static isProd() {
        return location.host.indexOf('youtoorobot') >= 0;
    }
    static isPad() {
        return location.host.indexOf('pad') >= 0;
    }
    static isIde() {
        return location.host.indexOf('ide') >= 0;
    }
}
exports.Utils = Utils;
