"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [{
        sysErr: {
            elite: "Failed to set servo state value, robot is not sync, please sync first.",
        },
        errCode: 'SET_SERVO_STATE_SYNC_ERR',
        tip: "设置伺服开关失败，伺服电机未同步！",
    }, {
        sysErr: {
            elite: "Can't operate, robot must be in remote mode.",
        },
        errCode: 'OPERATE_ROBOT_MODE_ERR',
        tip: "控制机器人失败，请检查机器人当前是否处于远程模式！",
    }, {
        sysErr: {
            elite: "Can't operate, robot in error state.",
        },
        errCode: 'OPERATE_ROBOT_ROBOT_STATE_ERR',
        tip: "控制机器人失败，请检查机器人当前是否处于正确的状态！",
    }, {
        sysErr: {
            elite: "Failed to set servo state value, robot is not in stop state.",
        },
        errCode: 'SET_SERVO_STATE_ROBOT_STATE_ERR',
        tip: "设置伺服开关失败，请检查机器人当前是否处于停止状态！",
    }, {
        sysErr: {
            elite: "Can't operate, robot not in servo on state.",
        },
        errCode: 'OPERATE_ROBOT_SERVO_STATE_ERR',
        tip: "控制机器人失败，请检查机器人伺服当前是否处于正确的状态！",
    }, {
        sysErr: {
            elite: "Can't operate, robot is in collision.",
        },
        errCode: 'OPERATE_ROBOT_COLLISION_ERR',
        tip: "控制机器人失败，机器人发生了碰撞，请检查机器人当前状态！",
    }, {
        sysErr: {
            elite: 'jbi only can be run when robot stop',
        },
        errCode: 'RUN_FILE_ROBOT_STATE_ERR',
        tip: "程序只能在机器人是停止状态下运行！"
    }, {
        sysErr: {
            elite: 'Failed to inverse kinematic, Singularity area or unreachable area.'
        },
        errCode: 'IK_AREA_ERR',
        tip: '逆解失败，存在不可达区域！'
    }, {
        sysErr: {
            elite: 'Failed to jog, robot is not in stop state.'
        },
        errCode: 'JOG_ROBOT_STATE_ERR',
        tip: '控制机器人运动失败，请检查机器人状态！'
    }, {
        sysErr: {
            elite: 'Failed to set io output status, addr must be in range of [0,19] or [48,51]'
        },
        errCode: 'SET_IO_ADDR_OUT_OF_RANGE',
        tip: '设置IO状态失败，IO地址错误！'
    }, {
        sysErr: {
            elite: "Can't operate, current move type is jog",
        },
        errCode: 'JOG_CMD_ERR',
        tip: '控制机器人运动失败，机器人运动指令冲突！'
    }
];
