"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    base_url: process.env.VUE_APP_BASE_URL || 'https://api.youtoorobot.com/v2',
    apis: {
        'login': {
            method: 'POST',
            url: '/open/login',
            auth: false
        },
        'logout': {
            url: '/user/logout',
            method: 'POST',
            auth: true,
        },
        'captcha': {
            url: '/open/captcha',
            method: 'GET',
            auth: false
        },
        'signup': {
            url: '/open/signup',
            method: "POST",
            auth: false
        },
        'sms': {
            url: '/open/smsCode',
            method: "POST",
            auth: false
        },
        'resetPwd': {
            url: '/open/resetPassword',
            method: "POST",
            auth: false
        },
        'refreshToken': {
            url: '/open/refreshToken',
            method: 'POST',
        },
        'user.info': {
            url: '/user/info',
            method: 'GET',
            auth: true,
        },
        'project': {
            url: '/project/list',
            method: 'GET',
            auth: true
        },
        'project.list': {
            url: '/project/list',
            method: 'GET',
            auth: true
        },
        'project.add': {
            url: '/project/create',
            method: 'POST',
            auth: true
        },
        'project.save': {
            url: '/project/update',
            method: 'PUT',
            auth: true
        },
        'project.delete': {
            url: '/project/delete',
            method: "PUT",
            auth: true
        },
        'devices': {
            url: '/devices/info',
            method: 'GET',
            auth: true
        },
        'project.info': {
            url: '/project/info',
            method: 'GET',
            auth: true
        },
        'program.file': {
            url: `/translate/zip`,
            method: 'POST',
            auth: true,
            responseType: 'blob',
        },
        'program.support': {
            url: `/translate/support`,
            method: 'GET',
            auth: true,
        },
        'program.text': {
            url: `/translate/text`,
            method: 'POST',
            auth: true,
        },
        'words.check': {
            url: '/green/',
            method: 'GET',
            base_url: 'https://api.youtoorobot.com/v2'
        },
        'home': {
            url: `/`,
            method: 'GET',
            base_url: `http://${window.location.host}`
        }
    }
};
